import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ClientApiService {
    private apiUrl = environment.dsbApiUrl;
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.dsbApiSecretKey,
    });

    constructor(private http: HttpClient) {}

    getClient(daySmartAccountId: string, onlineID: string): Observable<any> {
        const url = `${this.apiUrl}/api/client/${daySmartAccountId}/${onlineID}`;
        return this.http.get<any>(url, { headers: this.headers });
    }
}
