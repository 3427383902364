import { Injectable } from '@angular/core';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { StripeApiService } from './api/stripe-api.service';
import { LocalStorageService, SITE_BOOKING_SETTINGS } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    constructor(
        private stripeApiService: StripeApiService,
        private localStorageService: LocalStorageService
    ) {}

    public getStripePublishableKey() {
        return this.stripeApiService.getStripePublishableKey();
    }

    public getStripeAccountID() {
        const settings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        return settings.stripeAccountID;
    }

    public createSetupIntent() {
        return this.stripeApiService.createSetupIntent();
    }
}
