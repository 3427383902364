<!-- eslint-disable max-len -->
<form [formGroup]="formGroup">
    <div class="step-container">
        <div class="step-fixed-part">
            <h1 qa="select-service" class="step-title">Select Service</h1>
            <div class="horizontal-scroll-container">
                <div class="icon" (click)="horScrollDirective.scrollOnClick('left')">
                    <mat-icon class="material-icons-round margin-left">keyboard_arrow_left</mat-icon>
                </div>
                <mat-button-toggle-group
                    #toggleGroup
                    class="horizontal-scroll-box"
                    [value]="selectedCategory"
                    [horizontalScroll]="'mat-button-toggle-group'"
                    #horScrollDirective="horizontalScroll"
                >
                    <mat-button-toggle
                        class="toggle-button"
                        (change)="onFilterValueChange($event)"
                        *ngFor="let category of categoryList"
                        value="{{ category }}"
                        qa="select-service"
                    >
                        {{ limitMaxLength(category) }}
                        <div *ngIf="showSelectedCategoryServicesCount(category)" class="selected-services-count">
                            {{ countSelectedServices(category) }}
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <div class="icon" (click)="horScrollDirective.scrollOnClick('right')">
                    <mat-icon class="material-icons-round margin-right">keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <h2 *ngIf="!isBookMultiServiceEnabled; else multiServiceSubtitle" class="step-subtitle">
                Services: <span>{{ filteredServiceList.length }}</span>
            </h2>
            <ng-template #multiServiceSubtitle>
                <h2 class="step-subtitle">
                    Services: <span>{{ filteredServiceList.length }}</span> | Selected: <span>{{ selectedServices.length }}</span>
                </h2>
            </ng-template>
        </div>
        <div class="tp-radio-container">
            <mat-radio-group class="tp-radio-group" [formControl]="serviceFormControl">
                <div class="service-info-container" *ngFor="let service of filteredServiceList">
                    <div>
                        <mat-radio-button
                            *ngIf="!isBookMultiServiceEnabled; else multiService"
                            class="tp-radio-button"
                            id="service-{{ service.id }}"
                            value="{{ service.id }}"
                            qa="select-type-service"
                        >
                            {{ service.name }}

                            <div class="price-container" *ngIf="shouldShowServicePrice">
                                <span class="from-word" *ngIf="service.hasPriceException">from</span>
                                <span qa="service-price" class="service-price">{{
                                    service.lowestPrice | currency: currencyCode : 'symbol' : (service.lowestPrice % 1 != 0 ? '1.2' : '1.0')
                                }}</span>
                            </div>
                        </mat-radio-button>
                        <ng-template #multiService>
                            <mat-checkbox
                                (change)="toggleService($event)"
                                class="custom-frame"
                                [checked]="checkSelectedServices(service.id)"
                                id="service-{{ service.id }}"
                                value="{{ service.id }}"
                                qa="multi-select-type-service"
                            >
                                {{ service.name }}

                                <div class="price-container" *ngIf="shouldShowServicePrice">
                                    <span class="from-word" *ngIf="service.hasPriceException">from </span>
                                    <span qa="service-price" class="service-price">{{
                                        service.lowestPrice
                                            | currency: currencyCode : 'symbol' : (service.lowestPrice % 1 != 0 ? '1.2' : '1.0')
                                    }}</span>
                                </div>
                            </mat-checkbox>
                        </ng-template>
                    </div>
                    <div
                        qa="step-appt-details-text-deposit"
                        class="step-service-deposit"
                        *ngIf="isRadioButtonCheckedAndDepositRequired(service.id)"
                    >
                        <span
                            ><svg
                                class="info-icon"
                                width="16"
                                height="16"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.49219 0.777344C4.05469 0.777344 0.492188 4.37109 0.492188 8.77734C0.492188 13.2148 4.05469 16.7773
                                8.49219 16.7773C12.8984 16.7773 16.4922 13.2148 16.4922 8.77734C16.4922 4.37109 12.8984 0.777344 8.49219
                                0.777344ZM8.49219 4.77734C9.02344 4.77734 9.49219 5.24609 9.49219 5.77734C9.49219 6.33984 9.02344 6.77734
                                8.49219 6.77734C7.92969 6.77734 7.49219 6.33984 7.49219 5.77734C7.49219 5.24609 7.92969 4.77734 8.49219
                                4.77734ZM9.74219 12.7773H7.24219C6.80469 12.7773 6.49219 12.4648 6.49219 12.0273C6.49219 11.6211 6.80469
                                11.2773 7.24219 11.2773H7.74219V9.27734H7.49219C7.05469 9.27734 6.74219 8.96484 6.74219 8.52734C6.74219
                                8.12109 7.05469 7.77734 7.49219 7.77734H8.49219C8.89844 7.77734 9.24219 8.12109 9.24219
                                8.52734V11.2773H9.74219C10.1484 11.2773 10.4922 11.6211 10.4922 12.0273C10.4922 12.4648
                                10.1484 12.7773 9.74219 12.7773Z"
                                    fill="#222222"
                                    fill-opacity="0.8"
                                />
                            </svg>
                        </span>
                        <span
                            >This business requires a
                            <span class="bold">{{
                                service.depositPercentage
                                    ? service.depositPercentage + '%'
                                    : (service.depositFixedAmount
                                      | currency
                                          : currencyCode
                                          : 'symbol'
                                          : (service.depositFixedAmount && service.depositFixedAmount % 1 != 0 ? '1.2' : '1.0'))
                            }}</span>
                            deposit for this service.</span
                        >
                    </div>
                </div>
            </mat-radio-group>
        </div>
    </div>
</form>
