export class UserAccount {
    public firstName: string;
    public lastName: string;
    public email: string;
    public onlineId: string;

    constructor(firstName: string, lastName: string, email: string, onlineId: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.onlineId = onlineId;
    }
}
