import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CUSTOMER_ID, LocalStorageService } from '@app/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class BaseRouteGuard implements CanActivate {
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router
    ) {}

    canActivate() {
        const customerID = this.localStorageService.getString(CUSTOMER_ID);
        if (!customerID) {
            void this.router.navigate(['booking/invalid']);
            return false;
        } else {
            return true;
        }
    }
}
