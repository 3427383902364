import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewChecked, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { NavigationEnd, Router } from '@angular/router';
import { CreditCard } from '@app/models/creditCard.model';
import { CreditCardProcessingMethods } from '@app/models/creditCardProcessingMethodEnum';
import { Pet } from '@app/models/pet.model';
import { Site } from '@app/models/site.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { AppointmentRequestService } from '@app/services/appointment-request.service';
import { ContentService } from '@app/services/content.service';
import { CreditCardService } from '@app/services/credit-card.service';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { CUSTOMER_ID, LocalStorageService, PET_LIST, SITE, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { LocaleService } from '@app/services/locale.service';
import { StripeElementsService } from '@app/services/stripe-elements.service';
import { StripeService } from '@app/services/stripe.service';
import { UserAccountService } from '@app/services/user-account.service';
import { NavigationService, ScreenToShow } from '@services/navigation.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, Subscription, lastValueFrom, switchMap, takeUntil, timer } from 'rxjs';

export enum ButtonName {
    SelectEmployee = 'Select Employee',
    SelectDateTime = 'Select Date/Time',
    YourInformation = 'Your Information',
    PetInformation = 'Pet Information',
    ReviewDetails = 'Review Details',
    SubmitRequest = 'Submit Request',
}

export interface DsbStep {
    stepRoute: string;
    buttonText: string;
    index: number;
}

@Component({
    selector: 'content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, AfterViewChecked, OnDestroy {
    @HostBinding('class') classes = 'content-component-container';
    public endSubscriptionInSeconds = 180;
    public areStepsCompleted = false;
    public isForwardButtonDisabled = true;
    public isBackButtonDisabled = false;
    private navigationSubscription!: Subscription;
    public isStepCompleted = 0;
    public supportsPets: boolean = false;
    public showSpinnerOnButton = false;
    public clientInfoScreen: ScreenToShow = ScreenToShow.ScreenOne;
    ScreenToShow = ScreenToShow;
    public selectedStepIndex: number = 0;
    private customerID;
    private confirmationRoute = 'booking/submitted';
    private routerSubscription!: Subscription;
    private stopTimer$ = new Subject<void>();
    private siteBookingSettings: SiteBookingSettings | undefined;
    public showLoginSection = true;
    private isUserLoggedIn = false;
    private contentServiceSubscription: Subscription;
    private isFormValid = false;
    public userAccountFeatureEnabled: boolean = true;

    @ViewChild('stepper', { static: true }) stepper!: MatStepper;
    public steps: DsbStep[] = [
        {
            stepRoute: 'booking/service',
            buttonText: ButtonName.SelectEmployee,
            index: 0,
        },
        {
            stepRoute: 'booking/employee',
            buttonText: ButtonName.SelectDateTime,
            index: 1,
        },
        {
            stepRoute: 'booking/datetime',
            buttonText: ButtonName.YourInformation,
            index: 2,
        },
        {
            stepRoute: 'booking/client',
            buttonText: ButtonName.PetInformation,
            index: 3,
        },
        {
            stepRoute: 'booking/review',
            buttonText: ButtonName.SubmitRequest,
            index: 4,
        },
    ];
    constructor(
        private navigationService: NavigationService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private cd: ChangeDetectorRef,
        private appointmentRequestService: AppointmentRequestService,
        private featureFlagService: FeatureFlagService,
        private creditCardService: CreditCardService,
        private stripeService: StripeService,
        private stripeElementsService: StripeElementsService,
        private localeService: LocaleService,
        private snackBar: MatSnackBar,
        public contentService: ContentService,
        private userAccountService: UserAccountService,
        private recaptchaV3Service: ReCaptchaV3Service
    ) {
        this.siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
        const site = this.localStorageService.get(SITE) as Site;
        this.supportsPets = site?.supportsPets;
        if (!this.supportsPets) {
            this.steps[3].buttonText = ButtonName.ReviewDetails;
        }
        this.isUserLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        /* istanbul ignore next */
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && !event.url.includes(this.confirmationRoute)) {
                const currentStep = this.steps.find((s) => event.url.includes(s.stepRoute));
                this.stepper.selectedIndex = currentStep ? currentStep.index : 0;
                this.selectedStepIndex = this.stepper.selectedIndex;
                this.contentService.isStepCompleted = this.selectedStepIndex;
                if (currentStep) {
                    this.contentService.buttonName =
                        currentStep.stepRoute === 'booking/datetime' && this.isUserLoggedIn && this.supportsPets
                            ? ButtonName.PetInformation
                            : (currentStep.stepRoute === 'booking/datetime' || currentStep.stepRoute === 'booking/client') &&
                                this.isUserLoggedIn
                              ? ButtonName.ReviewDetails
                              : currentStep.buttonText;

                    if (this.steps[this.selectedStepIndex].stepRoute === 'booking/client' && this.isUserLoggedIn && this.supportsPets) {
                        this.contentService.isAccountCreatedFromClientScreen = true;
                        this.setClientPetInfoScreenNavigateForward();
                    }
                } else {
                    this.contentService.buttonName = this.steps[0].buttonText;
                }
                this.contentService.selectedStepIndex = this.selectedStepIndex;
            }
        });

        this.contentServiceSubscription = this.contentService.dataUpdated$.subscribe((data) => {
            if (data === this.contentService.logout) {
                this.isUserLoggedIn = false;
                this.userAccountService.logout();
                this.stepper.previous();
                this.clientInfoScreen = ScreenToShow.ScreenOne;
                this.contentService.buttonName = this.supportsPets ? ButtonName.PetInformation : ButtonName.ReviewDetails;
                this.contentService.selectedStepIndex = 3;
                this.isForwardButtonDisabled = true;
            } else {
                if (data === 'ScreenTwo') {
                    this.clientInfoScreen = ScreenToShow.ScreenTwo;
                }
                if (data === 'ScreenThree') {
                    this.clientInfoScreen = ScreenToShow.ScreenThree;
                }
                this.setClientPetNextStep();
            }
        });
    }

    ngOnInit() {
        this.userAccountFeatureEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts);
        this.navigationSubscription = this.navigationService.getIsStepFormValid().subscribe((isValid) => {
            this.isFormValid = isValid;
            this.isForwardButtonDisabled = !this.isFormValid;
        });
        this.navigationSubscription = this.navigationService.getIsLoading().subscribe((isLoading) => {
            this.isBackButtonDisabled = isLoading;
            this.isForwardButtonDisabled = isLoading ? true : this.isFormValid ? false : true;
        });
        this.navigationSubscription = this.navigationService.getShowSpinnerOnButton().subscribe((showSpinner) => {
            this.showSpinnerOnButton = showSpinner;
        });
        this.contentService.steps = this.steps;
        this.contentService.stepper = this.stepper;
        this.showLoginSection = this.siteBookingSettings?.allowClientOnlineAccountAccess === true;
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    public get isStripeEnabled() {
        this.siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        return (
            this.siteBookingSettings?.creditCardProcessingEnabled &&
            this.siteBookingSettings?.creditCardProcessingMethod === CreditCardProcessingMethods.Stripe
        );
    }

    public get isCardConnectEnabled() {
        this.siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        return (
            this.siteBookingSettings?.creditCardProcessingEnabled &&
            this.siteBookingSettings?.creditCardProcessingMethod === CreditCardProcessingMethods.CardConnect
        );
    }

    selectionChanged(event: StepperSelectionEvent) {
        this.contentService.isStepCompleted =
            event.previouslySelectedIndex > event.selectedIndex
                ? this.contentService.isStepCompleted--
                : this.contentService.isStepCompleted++;
        const selectedStep = this.steps[event.selectedIndex];
        this.selectedStepIndex = event.selectedIndex;
        this.contentService.buttonName = selectedStep.buttonText;
        this.navigateToAnotherStep(selectedStep);
    }

    /**
     * Navigates back to the previous step based on the provided `buttonName`.
     * @param buttonName - The name of the button clicked to trigger the navigation.
     */
    public navigateBack(buttonName: string) {
        this.isUserLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        if (buttonName === ButtonName.YourInformation) {
            this.navigationService.setIsStepFormValid(true);
            this.contentService.buttonName = ButtonName.SelectDateTime;
            this.stepper.previous();
        } else if (buttonName === ButtonName.ReviewDetails && this.supportsPets && !this.isUserLoggedIn) {
            this.clientInfoScreen = ScreenToShow.ScreenOne;
            this.contentService.buttonName = ButtonName.PetInformation;
            this.isForwardButtonDisabled = false;
        } else if (buttonName === ButtonName.ReviewDetails && this.supportsPets && this.isUserLoggedIn) {
            this.stepper.previous();
            this.contentService.buttonName = ButtonName.PetInformation;
            this.isForwardButtonDisabled = false;
        } else if (buttonName === ButtonName.SubmitRequest) {
            this.stepper.previous();
            if (this.supportsPets) {
                this.setClientPetInfoScreenNavigateBack();
                this.selectedStepIndex = 3;
                this.contentService.buttonName = ButtonName.ReviewDetails;
                this.navigationService.setNavigationButtonName(ButtonName.ReviewDetails);
            } else {
                this.clientInfoScreen = ScreenToShow.ScreenOne;
                this.contentService.buttonName = this.contentService.isAccountCreatedFromClientScreen
                    ? ButtonName.PetInformation
                    : ButtonName.ReviewDetails;
                if (this.isUserLoggedIn) {
                    this.stepper.previous();
                    this.navigationService.setNavigationButtonName(ButtonName.ReviewDetails);
                }
            }
        } else {
            this.stepper.previous();
            this.selectedStepIndex = this.stepper.selectedIndex;
            const selectedStep = this.steps[this.selectedStepIndex];
            this.contentService.buttonName = selectedStep.buttonText;
            this.navigateToAnotherStep(selectedStep);
        }
        this.contentService.selectedStepIndex = this.selectedStepIndex;
    }

    /**
     * Navigates forward to the next step based on the provided `buttonName`.
     * @param buttonName - The name of the button clicked to trigger the navigation.
     */
    public navigateForward(buttonName: string) {
        this.isUserLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        if (buttonName === ButtonName.YourInformation) {
            if (!this.isUserLoggedIn) {
                this.clientInfoScreen = ScreenToShow.ScreenOne;
                if (this.userAccountFeatureEnabled && this.supportsPets) {
                    this.navigationService.setNavigationButtonName(ButtonName.PetInformation);
                } else {
                    this.navigationService.setNavigationButtonName(ButtonName.ReviewDetails);
                }
                this.stepper.next();
            }
            if (this.isUserLoggedIn) {
                if (this.userAccountFeatureEnabled && this.supportsPets) {
                    this.setClientPetInfoScreenNavigateForward();
                }
                this.navigationService.setNavigationButtonName(ButtonName.ReviewDetails);
                this.stepper.next();
            }
        } else if (buttonName === ButtonName.PetInformation) {
            this.contentService.buttonName = ButtonName.ReviewDetails;
            this.setClientPetInfoScreenNavigateForward();
            if (this.isUserLoggedIn) {
                this.stepper.next();
            }
            this.navigationService.setNavigationButtonName(buttonName);
        } else if (buttonName === ButtonName.SubmitRequest) {
            this.submitAppointmentRequest();
        } else {
            this.navigationService.setNavigationButtonName(buttonName);
            this.stepper.next();
            this.selectedStepIndex = this.stepper.selectedIndex;
            const selectedStep = this.steps[this.selectedStepIndex];
            this.navigateToAnotherStep(selectedStep);
        }
        this.contentService.selectedStepIndex = this.selectedStepIndex;
    }

    /**
     * Navigates to another step in the booking workflow or a submitted page, depending on the provided parameters.
     * @param selectedStep - (Optional) The selected step to navigate to (if provided).
     * @param ticketID - (Optional) The ticket ID associated with the appointment request (if provided).
     */
    private navigateToAnotherStep(selectedStep?: DsbStep, ticketID?: number) {
        if (selectedStep) {
            void this.router.navigate([selectedStep.stepRoute], {
                queryParams: {
                    DSID: this.customerID,
                },
            });
            this.contentService.isStepCompleted = selectedStep.index;
            return;
        }
        void this.router.navigate(['/booking/submitted'], {
            queryParams: {
                DSID: this.customerID,
            },
            state: {
                ticketID: ticketID,
                shouldNavigate: true,
            },
        });
    }

    /**
     * Submits the appointment request after validating the credit card information if required.
     * If the credit card is on file and active, it validates the credit card information before submitting the request.
     * If the credit card is not on file or not active, it directly submits the request.
     */
    private submitAppointmentRequest(): void {
        if (this.isCardConnectEnabled) {
            this.handleCardConnect();
        } else if (this.isStripeEnabled) {
            this.handleStripe();
        } else {
            this.submitRequest();
        }
    }

    private async handleCardConnect(): Promise<void> {
        const creditCard = this.creditCardService.getCreditCardInformation();
        if (!creditCard) {
            this.submitRequest();
            return;
        }

        this.navigationService.shouldShowSpinnerOnButton(true);
        const isValid = await lastValueFrom(this.creditCardService.validateCreditCard(creditCard));
        this.navigationService.shouldShowSpinnerOnButton(false);

        if (isValid) {
            this.submitRequest();
        } else {
            this.showSnackBar('Credit card is not valid. Please check your data.');
        }
    }

    private async handleStripe(): Promise<void> {
        try {
            this.navigationService.shouldShowSpinnerOnButton(true);
            const stripePaymentElements = this.creditCardService.getStripePaymentElements();
            if (!stripePaymentElements) {
                this.submitRequest();
                return;
            }

            const { error: submitError } = await stripePaymentElements.submit();

            if (submitError) {
                this.showError(submitError.message);
                return;
            }

            const setupIntent = await this.stripeService.createSetupIntent();

            const confirmResult = await this.stripeElementsService.confirmSetupIntent(
                setupIntent.clientSecret,
                stripePaymentElements,
                window.location.href
            );

            if (!confirmResult || confirmResult.error) {
                this.showError(confirmResult?.error.message);
                return;
            }

            const paymentMethodId =
                typeof confirmResult?.setupIntent?.payment_method === 'string'
                    ? confirmResult?.setupIntent?.payment_method
                    : confirmResult?.setupIntent?.payment_method?.id;

            if (!paymentMethodId) {
                this.showError();
                return;
            }

            let currency = this.localeService.getCurrencyCode();
            currency = currency.toLowerCase();
            this.creditCardService.setCreditCardInformation(
                new CreditCard({
                    paymentMethod: paymentMethodId,
                    daySmartAccountID: this.customerID,
                    clientSecret: setupIntent.clientSecret,
                    currency,
                })
            );

            await this.submitRequest();
        } finally {
            this.navigationService.shouldShowSpinnerOnButton(false);
        }
    }

    private showError(message?: string) {
        this.showSnackBar(message ?? 'Credit card is not valid. Please check your data.');
    }

    private showSnackBar(message: string): void {
        this.snackBar.open(message, undefined, {
            duration: 2500,
        });
    }

    private async submitRequest() {
        this.navigationService.setIsLoading(true);
        this.recaptchaV3Service.execute('submit_request').subscribe(async (token: string) => {
            try {
                const response = await this.appointmentRequestService.createAppointmentRequest(this.appointmentRequestService, token);
                const ticketID = response?.ticketID;
                if (ticketID) {
                    this.navigateToAnotherStep(undefined, ticketID);
                } else {
                    this.router.navigate(['/booking/error'], {
                        queryParams: {
                            DSID: this.customerID,
                        },
                    });
                }
            } catch (error) {
                this.router.navigate(['/booking/error'], {
                    queryParams: {
                        DSID: this.customerID,
                    },
                });
            } finally {
                this.navigationService.setIsLoading(false);
            }
        });
    }

    /**
     * Waits for an appointment request to be fulfilled, with a maximum timeout of two minutes.
     * @param requestID - The ID of the appointment request to wait for.
     */
    private async waitForAppointmentRequest(requestID: string) {
        const delayFirstCall = 1000;
        const delayInSeconds = 1;
        let elapsedTime = 0;

        // Create an observable timer that emits every `delayInSeconds` seconds and fetches the appointment request status.
        const timer$ = timer(delayFirstCall, delayInSeconds * 1000).pipe(
            switchMap(() => this.appointmentRequestService.getAppointmentRequest(requestID)),
            takeUntil(this.stopTimer$)
        );

        // Subscribe to the timer$ observable to perform actions when the timer emits a value.
        const subscription = timer$.subscribe((ticketID: number | undefined) => {
            if (ticketID) {
                // If the appointment request is fulfilled and we get a ticketID, proceed to the submitted page.
                this.navigationService.setIsLoading(false);
                this.navigateToAnotherStep(undefined, ticketID);
                subscription.unsubscribe(); // Stop the timer once we get the result
            } else {
                // If the appointment request is not yet fulfilled, update the elapsed time and check the timeout.
                elapsedTime += delayInSeconds;
                if (elapsedTime >= this.endSubscriptionInSeconds) {
                    this.stopTimer$.next();
                }
            }
        });

        // Subscribe to the stopTimer$ notifier to handle the timeout.
        this.stopTimer$.subscribe(() => {
            // If the notifier emits a value, it means the two-minute timeout is reached and proceed to the error page.
            this.navigationService.setIsLoading(false);
            this.router.navigate(['/booking/error'], {
                queryParams: {
                    DSID: this.customerID,
                },
            });
            subscription.unsubscribe();
        });
    }

    private setClientPetInfoScreenNavigateForward() {
        const storedPetListData = this.localStorageService.get(PET_LIST) as Pet[];
        const hasPetListInfo = storedPetListData?.length > 0;
        if (this.userAccountFeatureEnabled && hasPetListInfo && this.isUserLoggedIn) {
            this.clientInfoScreen = ScreenToShow.ScreenThree;
        } else {
            this.clientInfoScreen = ScreenToShow.ScreenTwo;
        }
    }

    private setClientPetInfoScreenNavigateBack() {
        const storedPetListData = this.localStorageService.get(PET_LIST) as Pet[];
        const hasPetListInfo = storedPetListData?.length > 0;
        if (this.userAccountFeatureEnabled && hasPetListInfo && this.isUserLoggedIn) {
            this.clientInfoScreen = ScreenToShow.ScreenThree;
        } else {
            this.clientInfoScreen = this.supportsPets ? ScreenToShow.ScreenTwo : ScreenToShow.ScreenOne;
        }
    }

    private setClientPetNextStep() {
        this.isUserLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        const selectedStep = this.steps[this.contentService.selectedStepIndex];
        this.contentService.selectedStepIndex = 3;
        this.selectedStepIndex = this.contentService.selectedStepIndex;
        this.contentService.buttonName =
            selectedStep.stepRoute === 'booking/datetime' && this.isUserLoggedIn && this.supportsPets
                ? ButtonName.PetInformation
                : (selectedStep.stepRoute === 'booking/datetime' || selectedStep.stepRoute === 'booking/client') && this.isUserLoggedIn
                  ? ButtonName.ReviewDetails
                  : selectedStep.buttonText;
        this.navigateToAnotherStep(selectedStep);
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
        this.navigationSubscription.unsubscribe();
        this.contentServiceSubscription.unsubscribe();
    }
}
