import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pet } from '@app/models/pet.model';
import { PetBreed, PetType } from '@app/models/petType.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { environment } from '@environments/environment';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { catchError, map, Observable, of } from 'rxjs';
import { AppSyncService } from '../app-sync.service';
import { LOCAL_DB_TABLE_NAME, LocalDbService } from '../local-db.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class PetApiService {
    private apiUrl = environment.dsbApiUrl;
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.dsbApiSecretKey,
    });

    constructor(
        private appSyncService: AppSyncService,
        private localDbCache: LocalDbService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private http: HttpClient
    ) {}

    public async getPetTypeList(): Promise<PetType[]> {
        const customerID = this.localStorageService.getString(CUSTOMER_ID);
        const petTypeList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.petType, 'petTypeList')) as PetType[];
        if (petTypeList) {
            return petTypeList;
        }

        this.navigationService.setIsLoading(true);
        const modelList = await this.gqlCall(customerID);
        this.navigationService.setIsLoading(false);
        return modelList;
    }

    public async setPetTypeListToTheLS(daySmartAccountID: string): Promise<void> {
        const petTypeList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.petType, 'petTypeList')) as PetType[];
        if (petTypeList) {
            return;
        }
        void this.gqlCall(daySmartAccountID);
    }

    private async gqlCall(daySmartAccountID: string): Promise<PetType[]> {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getPetTypeListQuery = gql`
            query getPetTypeList($daySmartAccountID: ID!) {
                getPetTypeList(input: { daySmartAccountID: $daySmartAccountID }) {
                    petTypeList {
                        id
                        name
                        petBreedList {
                            id
                            typeID
                            name
                        }
                    }
                }
            }
        `;
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getPetTypeListQuery,
            variables: { daySmartAccountID: daySmartAccountID },
            fetchPolicy: 'no-cache',
        });
        const modelList = PetType.deserializeAppSyncResponse(queryResult);
        void this.localDbCache.set(LOCAL_DB_TABLE_NAME.petType, 'petTypeList', modelList);
        return modelList;
    }

    getPetList(daySmartAccountId: string, onlineID: string): Observable<Pet[]> {
        const url = `${this.apiUrl}/api/pet/${daySmartAccountId}/${onlineID}`;
        return this.http.get<any>(url, { headers: this.headers }).pipe(
            map((response: any) => {
                if (!response?.petList?.length) {
                    return [];
                }

                return response.petList
                    .map((item: any) => {
                        const petType =
                            item.type && item.type.id
                                ? new PetType({
                                      id: item.type.id,
                                      name: item.type.name,
                                      petBreedList:
                                          item.breed && item.breed.id
                                              ? [
                                                    new PetBreed({
                                                        id: item.breed.id,
                                                        name: item.breed.name,
                                                        typeID: item.breed.typeID,
                                                    }),
                                                ]
                                              : [],
                                  })
                                : undefined;

                        const pet = new Pet({
                            name: item.name,
                            petType: petType,
                            weight: item.weight || undefined,
                        });

                        return pet.name || pet.petType?.id || pet.weight ? pet : null;
                    })
                    .filter((pet: Pet) => pet !== null);
            }),
            catchError((error) => {
                console.error('Error fetching pet list:', error);
                return of([] as Pet[]);
            })
        );
    }
}
