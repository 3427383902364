import { AppSyncResponse } from '@helpers/appSyncResponse.helper';

export class SetupIntent {
    public setupIntentID: string;
    public clientSecret: string;

    constructor(setupIntent: SetupIntent) {
        this.setupIntentID = setupIntent.setupIntentID;
        this.clientSecret = setupIntent.clientSecret;
    }

    public static deserializeAppSyncResponse(appSyncResponse: any): SetupIntent {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: SetupIntent) => obj, ['createSetupIntent']);
    }
}
