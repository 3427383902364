/// <reference path='../../../types/redux-offline/index.d.ts' />

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { CognitoIdentityCredentials, fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { environment } from '@environments/environment';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ErrorResponse, onError } from 'apollo-link-error';
import { AUTH_TYPE, AWSAppSyncClient, createAppSyncLink } from 'aws-appsync';
import { LocalStorageService } from './local-storage.service';
import { NewRelicLoggingService } from './new-relic-logging.service';

@Injectable({ providedIn: 'root' })
export class AppSyncService {
    private clients: { [serviceUrl: string]: AWSAppSyncClient<NormalizedCacheObject> } = {};
    private region: string;
    private identityPoolID: string;
    private datasetName = 'CustomerData';
    private customerIdKey = 'customerID';
    private customerID!: string;
    private fileNameForNewRelic = 'app-sync.service.ts';

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
        private newRelicLoggingService: NewRelicLoggingService
    ) {
        this.region = environment.region;
        this.identityPoolID = environment.identityPoolID;
    }
    public getClient(serviceUrl: string): AWSAppSyncClient<NormalizedCacheObject> {
        let client: AWSAppSyncClient<NormalizedCacheObject> = this.clients[serviceUrl];

        if (!client) {
            client = this.createClient(serviceUrl);
            this.clients[serviceUrl] = client;
        }

        return client;
    }

    private createClient(serviceUrl: string): AWSAppSyncClient<NormalizedCacheObject> {
        const errorLink: ApolloLink = onError((args) => this.handleError(args));
        const appSyncLink: ApolloLink = createAppSyncLink({
            url: serviceUrl,
            region: this.region,
            auth: {
                type: AUTH_TYPE.AWS_IAM,
                credentials: async () => {
                    return this.getCredentials();
                },
            },
            complexObjectsCredentials: () => null,
        });

        return new AWSAppSyncClient({ disableOffline: true } as any, { link: ApolloLink.from([errorLink, appSyncLink]) });
    }

    private handleError(args: ErrorResponse) {
        let isScheduleForEmployeeError = false;
        let isSignatureTimestampError = false;
        let errorMessageToDisplay;
        if (args.graphQLErrors?.length) {
            args.graphQLErrors.map((error) => {
                if (error.message.includes('No schedules found in the database for the following employeeID')) {
                    isScheduleForEmployeeError = true;
                }
                if (error.message.toLowerCase().includes('recaptcha')) {
                    errorMessageToDisplay = error.message;
                }
                this.newRelicLoggingService.logError(error, this.fileNameForNewRelic);
            });
        }
        if (args.networkError?.name === 'ServerError' && (args.networkError as any).result?.errors?.length) {
            (args.networkError as any).result.errors.map((error: { message: string }) => {
                if (error.message?.startsWith('Signature not yet current') || error.message?.startsWith('Signature expired')) {
                    isSignatureTimestampError = true;
                }
            });
        }

        if (!isScheduleForEmployeeError && !isSignatureTimestampError) {
            //If this point is reached, the error will just bubble up to the caller as it would normally
            this.router.navigate(['/booking/error'], {
                queryParams: {
                    DSID: this.customerID,
                    ErrorMessage: errorMessageToDisplay,
                },
            });
        }
    }
    private async getCredentials(): Promise<CognitoIdentityCredentials> {
        const cognitoCredentials = fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: this.region }),
            identityPoolId: this.identityPoolID,
        });
        const credentials = await cognitoCredentials();
        return credentials;
    }
}
