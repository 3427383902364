import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Client } from '@app/models/client.model';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { UserAccountService } from '@app/services/user-account.service';
import { SiteBookingSettings } from './../../models/siteBookingSettings.model';

export enum WarningScreenType {
    InvalidUrlScreen,
    ErrorScreen,
    IncorrectTimeScreen,
}

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    private invalidURLMainTitle = `Oops, that address doesn't look right.`;
    private invalidURLSubtext = 'Please verify that the url is correct and try again.';
    private errorScreenMainTitle = 'Oops, something went wrong...';
    private errorScreenURLSubtext = 'Please try again or call the number below to schedule an appointment.';
    private incorrectTimeMainTitle = 'Oops, the system time seems to be incorrect.';
    private incorrectTimeSubtext = "Please synchronize your system's time and try again.";

    screenMainTitle = '';
    screenSubtext = '';
    screenType: WarningScreenType | undefined;
    siteSettings: SiteBookingSettings | undefined;
    businessPhoneNumber = '';
    phoneNumberExists = false;
    isErrorScreen = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private userAccountService: UserAccountService
    ) {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    @HostBinding('class.error-container')
    ngOnInit() {
        this.navigationService.setIsLoading(false);
        this.resolveScreenType(this.router.url);
    }

    private resolveScreenType(url: string) {
        const errorMessageToDisplay = this.route.snapshot.queryParams?.['ErrorMessage'];
        this.siteSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.screenMainTitle = this.errorScreenMainTitle;
        this.screenSubtext = this.errorScreenURLSubtext;
        this.isErrorScreen = true;
        this.screenType = WarningScreenType.ErrorScreen;
        if (this.siteSettings.businessPhoneNumber) {
            this.phoneNumberExists = true;
            this.businessPhoneNumber = this.siteSettings.businessPhoneNumber;
        } else {
            this.screenSubtext = this.screenSubtext.replace('us at: ', 'the business.');
        }

        if (errorMessageToDisplay) {
            this.screenMainTitle = `${errorMessageToDisplay}`;
        } else if (url.toLowerCase().includes('incorrecttime')) {
            this.screenMainTitle = this.incorrectTimeMainTitle;
            this.screenSubtext = this.incorrectTimeSubtext;
            this.isErrorScreen = false;
            this.screenType = WarningScreenType.IncorrectTimeScreen;
        } else if (url.toLowerCase().includes('invalid')) {
            this.screenMainTitle = this.invalidURLMainTitle;
            this.screenSubtext = this.invalidURLSubtext;
            this.isErrorScreen = false;
            this.screenType = WarningScreenType.InvalidUrlScreen;
        }
    }

    public get WarningScreenType() {
        return WarningScreenType;
    }

    public requestAppointmentAgain(): void {
        let client: Client | undefined = undefined;
        if (this.userAccountService.getCurrentUser() !== undefined) {
            client = (this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest)?.client;
        }
        this.localStorageService.remove(APPOINTMENT_REQUEST);
        this.localStorageService.remove(SITE_BOOKING_SETTINGS);
        if (client) {
            this.localStorageService.setNested(APPOINTMENT_REQUEST, 'client', client);
        }

        const customerID = this.localStorageService.getString(CUSTOMER_ID);

        void this.router.navigate(['/booking/service'], {
            queryParams: {
                DSID: customerID,
            },
        });
    }
}
