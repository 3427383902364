import { Injectable } from '@angular/core';
import { areArraysEqual } from '@app/shared/helpers/extensions';
import { environment } from '@environments/environment';
import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { Employee } from '@models/employee.model';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { LOCAL_DB_TABLE_NAME, LocalDbService } from '../local-db.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeApiService {
    private customerID;
    constructor(
        private appSyncService: AppSyncService,
        private localDbCache: LocalDbService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }

    async getEmployeeList(employeeIDList?: string[], serviceIDList?: string[]): Promise<Employee[]> {
        const employeeList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.employee, 'employeeList')) as Employee[];
        const cachedServiceIDList = (await this.localDbCache.get(
            LOCAL_DB_TABLE_NAME.employee,
            'cachedServiceIDList'
        )) as unknown as string[];

        if (
            employeeList &&
            employeeIDList?.every((eID) => employeeList.map((e) => e.id).includes(eID)) &&
            areArraysEqual(serviceIDList as string[], cachedServiceIDList)
        ) {
            return employeeList.filter((e) => employeeIDList.includes(e.id));
        }
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getEmployeeListQuery = gql`
            query getEmployeeList($daySmartAccountID: ID!, $employeeIDList: [ID], $serviceIDList: [ID]) {
                getEmployeeList(
                    input: { daySmartAccountID: $daySmartAccountID, employeeIDList: $employeeIDList, serviceIDList: $serviceIDList }
                ) {
                    employeeList {
                        id
                        name
                        webOrder
                        durationExceptionList {
                            serviceID
                            duration
                        }
                        employeeServicePriceList {
                            serviceID
                            price
                        }
                    }
                }
            }
        `;
        this.navigationService.setIsLoading(true);
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getEmployeeListQuery,
            variables: { daySmartAccountID: this.customerID, employeeIDList: employeeIDList, serviceIDList: serviceIDList },
            fetchPolicy: 'no-cache',
        });

        const modelList = Employee.deserializeAppSyncResponse(queryResult);
        void this.localDbCache.set(LOCAL_DB_TABLE_NAME.employee, 'employeeList', modelList, environment.employeeListCacheLifeTimeInSeconds);
        void this.localDbCache.set(
            LOCAL_DB_TABLE_NAME.employee,
            'cachedServiceIDList',
            serviceIDList as unknown as object,
            environment.defaultCacheLifeTimeInSeconds
        );
        this.navigationService.setIsLoading(false);
        return modelList;
    }
}
