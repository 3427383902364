<div class="login-form-container">
    <button id="close-button" mat-icon-button aria-label="close bottom sheet" mat-dialog-close (click)="closeBottomSheet()">
        <mat-icon>close</mat-icon>
    </button>
    <h1>Log In</h1>
    <iframe [src]="url | safeResourceUrl" title="Login Form"></iframe>
    <span class="span-forget-password"
        ><strong>Forgot password?</strong> <a (click)="forgetPassword()" class="forget-password-link">Reset Password</a></span
    >
    <span class="span-create-account"
        ><strong>Not a member yet?</strong> <a (click)="createAccount()" class="create-account-link">Create an Account</a></span
    >
</div>
