<form [formGroup]="formGroup">
    <div class="step-container">
        <h1 qa="select-employee-title" class="step-title">Select Employee</h1>
        <p qa="multi-service-no-employees-subtitle" *ngIf="isBookMultiServiceEnabled" class="multi-service-subtitle">
            Only employees available for all the services selected will be presented here.
        </p>
        <p
            qa="multi-service-no-employees"
            *ngIf="isBookMultiServiceEnabled && noEmployeeAvailable"
            class="multi-service-no-employee-available"
        >
            No employee available. Please go back to the service page to adjust.
        </p>
        <div class="tp-radio-container">
            <mat-radio-group class="tp-radio-group" formControlName="employee">
                <mat-radio-button qa="select-employee" class="tp-radio-button" *ngFor="let employee of employeeList" [value]="employee.id">
                    {{ employee.name }}
                    <div class="price-container" *ngIf="shouldShowServicePrice">
                        <span qa="service-price" class="service-price">
                            <ng-template [ngIf]="employee.id === '0'" [ngIfElse]="employeePrice">
                                <ng-template [ngIf]="minEmployeePrice === maxEmployeePrice" [ngIfElse]="firstAvailabilityWithPriceRange">{{
                                    minEmployeePrice | currency: '' : 'symbol' : (minEmployeePrice % 1 != 0 ? '1.2' : '1.0') : localeId
                                }}</ng-template>
                                <ng-template #firstAvailabilityWithPriceRange>
                                    {{
                                        minEmployeePrice | currency: '' : 'symbol' : (minEmployeePrice % 1 != 0 ? '1.2' : '1.0') : localeId
                                    }}
                                    -
                                    {{
                                        maxEmployeePrice | currency: '' : 'symbol' : (maxEmployeePrice % 1 != 0 ? '1.2' : '1.0') : localeId
                                    }}</ng-template
                                >
                            </ng-template>
                            <ng-template #employeePrice>
                                {{
                                    getEmployeePrice(employee)
                                        | currency: '' : 'symbol' : (getEmployeePrice(employee) % 1 != 0 ? '1.2' : '1.0') : localeId
                                }}</ng-template
                            >
                        </span>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</form>
