import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddServiceComponent } from './components/booking-steps/add-service/add-service.component';
import { EnterYourInformationComponent } from './components/booking-steps/enter-your-information/enter-your-information.component';
import { ReviewRequestComponent } from './components/booking-steps/review-request/review-request.component';
import { SelectDateTimeComponent } from './components/booking-steps/select-date-time/select-date-time.component';
import { SelectEmployeeComponent } from './components/booking-steps/select-employee/select-employee.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ContentComponent } from './components/content/content.component';
import { ErrorComponent } from './components/error/error.component';
import { BaseRouteGuard } from './guards/baseRoute.guard';
import { PathValidationGuard } from './guards/pathValidation.guard';

const employeePathValidationList = ['service'];

const datetimePathValidationList = employeePathValidationList.concat('employee');

const clientPathValidationList = datetimePathValidationList.concat('datetime');

const reviewRequestAndConfirmationValidationList = clientPathValidationList.concat('client');

const routes: Routes = [
    {
        path: '',
        redirectTo: 'booking/service',
        pathMatch: 'full',
    },
    {
        path: 'booking',
        component: ContentComponent,
        canActivate: [BaseRouteGuard],
        children: [
            {
                path: 'service',
                component: AddServiceComponent,
            },
            {
                path: 'employee',
                component: SelectEmployeeComponent,
                canActivate: [PathValidationGuard],
                data: {
                    pathValidationList: employeePathValidationList,
                },
            },
            {
                path: 'datetime',
                component: SelectDateTimeComponent,
                canActivate: [PathValidationGuard],
                data: {
                    pathValidationList: datetimePathValidationList,
                },
            },
            {
                path: 'client',
                component: EnterYourInformationComponent,
                canActivate: [PathValidationGuard],
                data: {
                    pathValidationList: clientPathValidationList,
                },
            },
            {
                path: 'review',
                component: ReviewRequestComponent,
                canActivate: [PathValidationGuard],
                data: {
                    pathValidationList: reviewRequestAndConfirmationValidationList,
                },
            },
        ],
    },
    {
        path: 'booking/submitted',
        component: ConfirmationComponent,
        canActivate: [PathValidationGuard],
        data: {
            pathValidationList: reviewRequestAndConfirmationValidationList,
        },
    },
    {
        path: 'booking/error',
        component: ErrorComponent,
    },
    {
        path: 'booking/invalid',
        component: ErrorComponent,
    },
    {
        path: 'booking/incorrectTime',
        component: ErrorComponent,
    },
    {
        path: '**',
        redirectTo: 'booking/service',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [BaseRouteGuard],
})
export class AppRoutingModule {}
