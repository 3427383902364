import { Component, HostBinding, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { EnterYourInformationComponent } from '@app/components/booking-steps/enter-your-information/enter-your-information.component';
import { Client } from '@app/models/client.model';
import { Site } from '@app/models/site.model';
import { ContentService } from '@app/services/content.service';
import { ErrorDialogService } from '@app/services/error-dialog.service';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { CUSTOMER_ID, LocalStorageService, SITE } from '@app/services/local-storage.service';
import { UserAccountService } from '@app/services/user-account.service';

@Component({
    selector: 'app-create-account-form',
    templateUrl: './create-account-form.component.html',
    styleUrls: ['./create-account-form.component.scss'],
})
export class CreateAccountFormComponent {
    @HostBinding('class') classes = 'create-account-component-container';
    @ViewChild(EnterYourInformationComponent) enterYourInformationComponent: EnterYourInformationComponent | undefined;
    isSaving = false;
    actionButtonName = '';
    private site: Site;
    public userAccountFeatureEnabled: boolean = true;

    constructor(
        private bottomSheet: MatBottomSheet,
        private localStorageService: LocalStorageService,
        private router: Router,
        public contentService: ContentService,
        private userAccountService: UserAccountService,
        private errorDialogService: ErrorDialogService,
        private featureFlagService: FeatureFlagService
    ) {
        this.actionButtonName =
            this.contentService.selectedStepIndex === this.contentService.steps?.find((x) => x.stepRoute === 'booking/client')?.index
                ? 'Review Details'
                : 'Create Account';
        this.site = this.localStorageService.get(SITE) as Site;
        this.userAccountFeatureEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts);
    }

    public closeBottomSheet() {
        this.bottomSheet.dismiss();
    }

    public onReviewDetails() {
        this.isSaving = this.enterYourInformationComponent?.isValidFields() ?? false;
        if (this.isSaving) {
            const formValue = this.enterYourInformationComponent?.formGroup.value;
            const client = new Client(formValue);
            const pwd = formValue.password as string;
            const customerID = this.localStorageService.getString(CUSTOMER_ID);
            //Below NOSONAR comment is to escape from SonarCloud Code Analysis
            const customerIdWithoutPrefix = customerID?.replace(/.*?(\d+)/, '$1'); //NOSONAR
            this.userAccountService.create(client, pwd, customerIdWithoutPrefix).subscribe({
                next: () => {
                    this.contentService.isAccountCreatedFromClientScreen = true;
                    this.userAccountService.handleAccountCreation(
                        this.site,
                        this.contentService.selectedStepIndex,
                        this.navigateToRoute.bind(this)
                    );
                },
                error: (error) => {
                    this.isSaving = false;
                    this.errorDialogService.openErrorDialog(error.error?.message);
                },
                complete: () => {
                    this.closeBottomSheet();
                },
            });
        }
    }

    private navigateToRoute(selectedStep: any) {
        void this.router.navigate([selectedStep.stepRoute], {
            queryParams: {
                DSID: this.localStorageService.getString(CUSTOMER_ID),
            },
        });

        this.contentService.isStepCompleted = selectedStep.index;
    }
}
