import { Component, HostBinding, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Pet } from '@app/models/pet.model';
import { PetType } from '@app/models/petType.model';
import { ContentService } from '@app/services/content.service';
import { PetService } from '@app/services/pet.service';
import { PetInformationComponent } from '../pet-information/pet-information.component';

@Component({
    selector: 'app-add-pet',
    templateUrl: './add-pet.component.html',
    styleUrls: ['./add-pet.component.scss'],
})
export class AddPetComponent {
    @HostBinding('class') classes = 'add-pet-component-container';
    @ViewChild(PetInformationComponent) petInformationComponent: PetInformationComponent | undefined;
    isValidPetForm = false;
    isSaving = false;

    constructor(
        private bottomSheetAddPet: MatBottomSheet,
        public contentService: ContentService,
        private petService: PetService
    ) {}

    public closeBottomSheet() {
        this.bottomSheetAddPet.dismiss();
    }

    public onReviewDetails() {
        if (this.isValidPetForm) {
            this.isSaving = true;
            const formValue = this.petInformationComponent?.formGroup.value;

            const pet: Pet = {
                name: formValue.name,
            };

            if (formValue.petType) {
                const petType: PetType = {
                    id: formValue.petType.id,
                    name: formValue.petType.name,
                    petBreedList: [],
                };

                if (formValue.petBreed) {
                    petType.petBreedList.push({
                        id: formValue.petBreed.id,
                        name: formValue.petBreed.name,
                        typeID: formValue.petBreed.typeID,
                    });
                }
                pet.petType = petType;
            }

            if (formValue.weight) {
                pet.weight = formValue.weight;
            }
            this.petService.addPet(pet);
            this.closeBottomSheet();
        }
    }
}
