export const environment = {
    production: false,
    env: 'stage',
    region: 'us-east-2',
    identityPoolID: 'us-east-2:e2414489-5e2a-4b54-a05e-8abc27492bf9',
    serviceUrl: 'https://6edx5etzincnlgp6le3rgukibi.appsync-api.us-east-2.amazonaws.com/graphql',
    calendarAvailabilitySearchThreshold: 1000,
    dsbNewRelicLicenseKey: '',
    dsbNewRelicApplicationID: '',
    dsbNewRelicAccountID: '',
    dsbNewRelicTrustKey: '',
    launchdarklyApiKey: '63f4a51c969ff013a6efe2b0',
    gaTrackingID: '',
    baseCardConnectUrl: 'https://daysmart-uat.cardconnect.com',
    authService: 'https://client-auth.stage.daysmart.com',
    localesUrl: 'https://cultures.s3.amazonaws.com/',
    clientAuthServiceApiUrl: 'https://client-auth.stage.core.dssvc.io/api',
    clientAuthServiceApiKey: 'KZLhXo75EvaZmMxJvlrkyaF1NxxrZ4rU12YyPhVo',
    posApiUrl: 'https://stage-api-cloud.saloniris.com/api/v2',
    posApiSecretKey: '842fce77e41b4f20811567c423620bd1',
    dsbApiUrl: 'https://api.stage.dsb.daysmart.com',
    dsbApiSecretKey: 'WsYSZIGQas5sXqBPz4xHZ69Y5G8OWIOaamwUW3w8',
    defaultCacheLifeTimeInSeconds: 300,
    serviceListCacheLifeTimeInSeconds: 30,
    employeeListCacheLifeTimeInSeconds: 60,
};
