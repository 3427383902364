import { Injectable } from '@angular/core';
import { CreditCard } from '@app/models/creditCard.model';
import { StripeElements } from '@stripe/stripe-js';
import { CreditCardApiService } from './api/credit-card-api.service';

@Injectable({
    providedIn: 'root',
})
export class CreditCardService {
    constructor(private creditCardApiService: CreditCardApiService) {}
    private _creditCard!: CreditCard | undefined;
    private _stripePaymentElements: StripeElements | undefined;

    public validateCreditCard(creditCard: CreditCard) {
        return this.creditCardApiService.validateCreditCard(creditCard);
    }
    public setCreditCardInformation(creditCard: CreditCard | undefined) {
        this._creditCard = creditCard;
    }
    public getCreditCardInformation() {
        return this._creditCard;
    }
    public resetCreditCardInformation() {
        this._creditCard = undefined;
    }

    public setStripePaymentElements(elements: StripeElements) {
        this._stripePaymentElements = elements;
    }
    public getStripePaymentElements() {
        return this._stripePaymentElements;
    }
    public resetStripePaymentElements() {
        this._stripePaymentElements = undefined;
    }
}
