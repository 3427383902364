import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';

export class Employee {
    public id: string;
    public name: string;
    public durationExceptionList?: DurationException[];
    public employeeServicePriceList: EmployeeServicePrice[];
    public firstAvailable?: boolean;
    public webOrder?: number;

    constructor(employee: Employee) {
        this.id = employee.id;
        this.name = employee.name;
        this.durationExceptionList = employee.durationExceptionList;
        this.employeeServicePriceList = employee.employeeServicePriceList;
        this.firstAvailable = false;
        this.webOrder = employee.webOrder;
    }

    public static deserializeAppSyncResponse(appSyncResponse: ApolloQueryResult<AppSyncResponse>): Employee[] {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: Employee[]) => obj.map((e: Employee) => e), [
            'getEmployeeList',
            'employeeList',
        ]);
    }
}

export class DurationException {
    public serviceID: string;
    public duration: number;

    constructor(durationException: DurationException) {
        this.serviceID = durationException.serviceID;
        this.duration = durationException.duration;
    }
}

export class EmployeeServicePrice {
    public serviceID: string;
    public price: number;

    constructor(employeeServicePrice: EmployeeServicePrice) {
        this.serviceID = employeeServicePrice.serviceID;
        this.price = employeeServicePrice.price;
    }
}
