<!-- Loading spinner -->
<div *ngIf="isLoading" qa="loading-spinner" id="loading-spinner" class="loading-container flex-content-center">
    <mat-progress-spinner style="margin: auto" diameter="48" color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>

<div class="app-container" qa="app-container">
    <app-title-bar></app-title-bar>
    <div class="page-container">
        <router-outlet></router-outlet>
    </div>
</div>
