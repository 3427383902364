import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Site } from '@app/models/site.model';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, SITE } from '@app/services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class PathValidationGuard {
    retValue: boolean = true;
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const site = this.localStorageService.get(SITE) as Site;
        const supportsPets = site?.supportsPets;
        const pathValidationList = route.data.pathValidationList;
        this.retValue = true;

        const navigation = this.router.getCurrentNavigation();
        if (navigation && route.routeConfig?.path === 'booking/submitted' && navigation.extras.state?.shouldNavigate) {
            return true;
        }

        pathValidationList.forEach((element: any) => {
            if (element === 'service') {
                if (!appointmentRequest.service || appointmentRequest.service.length < 1) {
                    return this.navigateToRoute(element);
                }
            } else if (element === 'employee') {
                if (!appointmentRequest.employee || appointmentRequest.employee.length < 1) {
                    return this.navigateToRoute(element);
                }
            } else if (element === 'datetime') {
                if (!appointmentRequest.datetime) {
                    return this.navigateToRoute(element);
                }
                const date = new Date(appointmentRequest.datetime);
                if (isNaN(date.valueOf())) {
                    return this.navigateToRoute(element);
                }
            } else if (element === 'client') {
                if (!appointmentRequest.client || (supportsPets && !appointmentRequest.petInfo)) {
                    return this.navigateToRoute(element);
                }
            }
        });

        if (route.routeConfig?.path === 'booking/submitted') {
            return this.navigateToRoute('review');
        }
        return this.retValue;
    }

    navigateToRoute(partOfRoute: string): boolean {
        const customerID = this.localStorageService.getString(CUSTOMER_ID);

        void this.router.navigate(['/booking/' + partOfRoute], {
            queryParams: {
                DSID: customerID,
            },
        });
        this.retValue = false;
        return this.retValue;
    }
}
