import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { DsbCookie, DsbCookieService } from './dsb-cookies.service';

export interface Tokens {
    idToken?: string;
    accessToken?: string;
    refreshToken?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthTokenService {
    private readonly _defaultAccessAndIdTokenExpirationMinutes = 60;
    private readonly _defaultRefreshTokenExpirationDays = 180;
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.clientAuthServiceApiKey,
    });

    constructor(
        private readonly cookieService: DsbCookieService,
        private readonly httpClient: HttpClient
    ) {}

    public hasRefreshToken(): boolean {
        return this.cookieService.check(DsbCookie.DC_Client_Refresh_Token);
    }

    public getIdToken(): Observable<string | undefined> {
        return this.getTokens().pipe(
            map((value) => {
                return value?.idToken;
            })
        );
    }

    public getAccessToken(): Observable<string | undefined> {
        return this.getTokens().pipe(
            map((value) => {
                return value?.accessToken;
            })
        );
    }

    public getTokens(): Observable<Tokens> {
        return new Observable<Tokens>((subscriber) => {
            const idToken = this.cookieService.get(DsbCookie.DC_Client_Id_Token);
            const accessToken = this.cookieService.get(DsbCookie.DC_Client_Access_Token);
            const refreshToken = this.cookieService.get(DsbCookie.DC_Client_Refresh_Token);
            if (!idToken || !accessToken) {
                if (!refreshToken) {
                    subscriber.next();
                    subscriber.complete();
                } else {
                    this.refreshIdAndAccessTokens(refreshToken)
                        .pipe(
                            catchError((error) => {
                                throw error;
                            }),
                            finalize(() => {
                                subscriber.complete();
                            })
                        )
                        .subscribe(({ refreshedIdToken, refreshedAccessToken }) => {
                            this.saveIdTokenAndAccessTokensToCookie(refreshedIdToken, refreshedAccessToken);
                            subscriber.next({
                                idToken: refreshedIdToken,
                                accessToken: refreshedAccessToken,
                                refreshToken,
                            });
                            subscriber.complete();
                        });
                }
            } else {
                subscriber.next({
                    idToken,
                    accessToken,
                    refreshToken,
                });
                subscriber.complete();
            }
        });
    }

    public getEmailFromIdToken(idToken: string): any {
        const jsonTokenData = this.GetPropertiesFromIdToken(idToken);
        return jsonTokenData.email;
    }

    public getCustomerIdFromIdToken(idToken: string): number {
        const jsonTokenData = this.GetPropertiesFromIdToken(idToken);
        return +jsonTokenData['custom:customerId'];
    }

    public getOnlineIdFromIdToken(idToken: string): any {
        const jsonTokenData = this.GetPropertiesFromIdToken(idToken);
        return jsonTokenData['custom:onlineId'];
    }

    private GetPropertiesFromIdToken(idToken: string) {
        const idTokenArray = idToken.split('.');
        if (idTokenArray.length !== 3) {
            throw new Error('Unexpected ID Token');
        }
        const tokenData = idTokenArray[1];
        const decodedTokenData = atob(tokenData);
        const jsonTokenData = JSON.parse(decodedTokenData);
        return jsonTokenData;
    }

    private refreshIdAndAccessTokens(refreshToken: string): Observable<{ refreshedIdToken: string; refreshedAccessToken: string }> {
        const body = {
            refreshToken: refreshToken,
        };
        const refreshTokenExp = this.cookieService.get(DsbCookie.REFRESH_TOKEN_EXP);
        if (!refreshTokenExp || moment(refreshTokenExp) < moment()) {
            this.clearAllCookies();
            return of();
        }
        const url = `${environment.clientAuthServiceApiUrl}/refreshAccess`;
        return this.httpClient.post(url, body, { headers: this.headers }).pipe(
            catchError((err) => {
                this.clearAllCookies();
                throw err;
            }),
            map((response: any) => {
                return {
                    refreshedIdToken: response.idToken,
                    refreshedAccessToken: response.accessToken,
                };
            })
        );
    }

    public clearAllCookies(): void {
        this.cookieService.clearAllCookies();
    }

    public saveAllTokensToCookie(idToken: string, accessToken: string, refreshToken: string): void {
        const domain = this.cookieService.getDomain();
        const refreshExpiration = moment().add(this._defaultRefreshTokenExpirationDays, 'day');
        const refreshDate = refreshExpiration.format('YYYY-MM-DD[T]HH:mm:ss');
        this.saveIdTokenAndAccessTokensToCookie(idToken, accessToken);
        this.cookieService.set(DsbCookie.DC_Client_Refresh_Token, refreshToken, refreshExpiration.toDate(), '/', domain, true, 'None');
        this.cookieService.set(DsbCookie.REFRESH_TOKEN_EXP, refreshDate, refreshExpiration.toDate(), '/', domain, true, 'None');
    }

    private saveIdTokenAndAccessTokensToCookie(idToken: string, accessToken: string): void {
        const domain = this.cookieService.getDomain();
        const expiration = moment().add(this._defaultAccessAndIdTokenExpirationMinutes, 'minute');
        this.cookieService.set(DsbCookie.DC_Client_Id_Token, idToken, expiration.toDate(), '/', domain, true, 'None');
        this.cookieService.set(DsbCookie.DC_Client_Access_Token, accessToken, expiration.toDate(), '/', domain, true, 'None');
    }
}
