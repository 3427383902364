import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeResourceUrl',
})
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(url: string) {
        const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
        return this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(sanitizedUrl!)); // NOSONAR
    }
}
