/**
 * Represents one call to the getAvailabilitiesList endpoint and stores the range that was used for the call.
 * This model is used to track calls that are initiated and are still in progress.
 */
export class AvailabilitiesCall {
    public startDate: Date;
    public endDate: Date;

    constructor(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
