import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Availability } from '@app/models/availability.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { AvailabilitiesService } from '@app/services/availabilities.service';
import { EmployeeService } from '@app/services/employee.service';
import { APPOINTMENT_REQUEST, LocalStorageService, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { convertTo12Hour, convertTo24Hour, dateToStringNoTimeZone } from '@app/shared/helpers/extensions';
import { Observable, Subject, Subscription, forkJoin, from, map, of, takeUntil } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomCalendarHeaderComponent } from '../select-date-time/custom-calendar-header/custom-calendar-header.component';
import { SelectDateTimeService } from '../select-date-time/select-date-time.service';
import { AvailabilitiesCall } from './models/availabilitiesCall.model';
import { DayInWeek } from './models/dayInWeek.model';
import { Month } from './models/month.model';
import { MonthAvailability } from './models/monthAvailability.model';
import { Week } from './models/week.model';

export enum HorizontalScrollDirection {
    Left,
    Right,
}

@Component({
    selector: 'app-select-date-time',
    templateUrl: './select-date-time.component.html',
    styleUrls: ['./select-date-time.component.scss'],
    providers: [SelectDateTimeService],
})
export class SelectDateTimeComponent implements OnInit, OnDestroy {
    /**
     * The current selected date on the screen.
     */
    public selected = new Date();
    /**
     * An array that holds the data for the 7 day display on the screen (week list).
     */
    public dateList: DayInWeek[] = [];

    /**
     * An array that holds the morning time slots (AM times).
     */
    public morningTimeSlots: string[] = [];
    /**
     * An array that holds the afternoon time slots (PM times).
     */
    public afternoonTimeSlots: string[] = [];
    /**
     * The minimum calendar date that is enabled.
     */
    public minDate = new Date();
    /**
     * The maximum calendar date that is enabled
     */
    public maxDate: Date | undefined = undefined;
    /**
     * An array that holds availabilities fetched from the API by month and year.
     */
    public monthAvailabilities: MonthAvailability[] = [];
    public isCalendarLoading = false;
    public isForwardButtonDisabled: boolean = false;
    public isBackButtonDisabled: boolean = false;
    public shouldDisableRightChevron: boolean = true;
    public shouldDisableRightChevronMaxAdvanceBook: boolean = false;
    public shouldDisableLeftChevron: boolean = false;
    public customCalendarHeaderComponent = CustomCalendarHeaderComponent;
    public horizontalScrollDirection: HorizontalScrollDirection | undefined;
    private _destroyed$: Subject<void>;
    private subscriptions: Subscription[] = [];
    private employeeIdList: string[] = [];
    /**
     * An array that holds a list of models that represent calls to the API that are initiated and still in progress.
     */
    private availabilitiesCalls: AvailabilitiesCall[] = [];
    public isScheduleForEmployeeFound: boolean = true;

    // Size of the window width
    public windowWidth!: number;
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.windowWidth = window.innerWidth;
    }

    constructor(
        private elementRef: ElementRef,
        private availabilitiesService: AvailabilitiesService,
        private selectDateTimeService: SelectDateTimeService,
        private navigationService: NavigationService,
        private localStorageService: LocalStorageService,
        public cd: ChangeDetectorRef,
        private employeeService: EmployeeService
    ) {
        this._destroyed$ = new Subject();
    }

    ngOnInit() {
        this.onResize(null);
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        this.employeeIdList = this.employeeService.getEmployeesFromServiceList(appointmentRequest.service);
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        if (siteBookingSettings?.appointmentMaxAdvanceBooking) {
            this.maxDate = this.adjustDays(new Date(), siteBookingSettings.appointmentMaxAdvanceBooking);
        }

        if (appointmentRequest.datetime) {
            const dateFromLS = new Date(appointmentRequest.datetime);
            const today = new Date();
            let startDate = today;
            // If the date from local storage is in a future month the whole month should be fetched
            // Else if the date is from the current month but from the future the start date for the fetch
            // availabilities operation needs to be the current day.
            if (
                dateFromLS.getFullYear() > today.getFullYear() ||
                (dateFromLS.getFullYear() === today.getFullYear() && dateFromLS.getMonth() > today.getMonth())
            ) {
                startDate = new Date(dateFromLS.getFullYear(), dateFromLS.getMonth(), 1);
            }
            void this.fetchAvailabilities(startDate, dateFromLS);
        } else {
            void this.fetchAvailabilities();
        }

        this.subscriptions.push(
            this.selectDateTimeService.nextMonthClicked.subscribe((activeDate) => {
                this.nextMonthClicked(activeDate);
            })
        );
        this.subscriptions.push(
            this.selectDateTimeService.previousMonthClicked.subscribe((activeDate) => {
                this.previousMonthClicked(activeDate);
            })
        );
        this.subscriptions.push(
            this.selectDateTimeService.onAvailabilityDataFetched.subscribe(() => {
                this.selectTimeSlotFromLocalStorage();
                // Select first day of the (this.dateList) week list, if the current selected day is not one of the week list
                if (
                    this.dateList.length &&
                    !this.dateList.some((dl) => this.selectDateTimeService.compareDateWithoutTime(dl.date, this.selected) === 0)
                ) {
                    this.cd.detectChanges();
                    this.manipulateDayOrTimeSlot(
                        `#day-slot-${this.dateList[0].date.getDate()}-${this.dateList[0].date.getMonth()}`,
                        'on',
                        'day-slot-active'
                    );
                }
            })
        );
        this.subscriptions.push(
            this.navigationService.getIsCalendarLoading().subscribe((isCalendarLoading) => {
                if (this.isCalendarLoading !== isCalendarLoading) {
                    this.isCalendarLoading = isCalendarLoading;
                    this.isLoadingChanged();
                    this.cd.detectChanges();
                }
            })
        );
        this.subscriptions.push(
            this.selectDateTimeService.onAsyncCallChevronRightFinished.subscribe((isFinished: boolean) => {
                this.shouldDisableRightChevron = !isFinished;
                this.cd.detectChanges();
            })
        );
        this.subscriptions.push(
            this.selectDateTimeService.onAsyncCallChevronLeftFinished.subscribe((isFinished: boolean) => {
                this.shouldDisableLeftChevron = !isFinished;
                this.cd.detectChanges();
            })
        );
    }

    public get HorizontalScrollDirection() {
        return HorizontalScrollDirection;
    }

    /**
     * Checks to see if the date to check should be enabled or disabled in the calendar.
     * The function is called for every day in the current month on calendar load.
     * @param date Date to check
     * @returns A boolean indicator if the date to check should be enabled or disabled in the calendar
     */
    public filterDates = (date: any): boolean => {
        if (!date) {
            return false;
        }
        date = this.selectDateTimeService.getDateString(new Date(date.toString()));
        const availabilityFound = this.monthAvailabilities
            .find((m) => m.month === new Date(date).getMonth())
            ?.availabilities.filter((a) => a.start.includes(date.split('T')[0]));
        return !!availabilityFound?.length;
    };

    /**
     * Handles the click event on the right chevron (moving to the next month).
     */
    public nextMonthClicked(activeDate: any) {
        const tempDateNextMonth = this.adjustMonth(activeDate, 1);
        const isNextMonthLoaded = this.monthAvailabilities.filter(
            (m) => m.month === tempDateNextMonth.getMonth() && m.year === tempDateNextMonth.getFullYear()
        );
        if (!isNextMonthLoaded.length) {
            this.fetchFullMonthAvailabilities(this.selectDateTimeService.getNextMonth(activeDate), [], false, true);
        }
    }

    /**
     * Handles the click event on the left chevron (moving to the previous month).
     */
    public previousMonthClicked(activeDate: any) {
        const tempDatePreviousMonth = this.adjustMonth(activeDate, -1);
        const isPreviousMonthLoaded = this.monthAvailabilities.filter(
            (m) => m.month === tempDatePreviousMonth.getMonth() && m.year === tempDatePreviousMonth.getFullYear()
        );
        if (!isPreviousMonthLoaded.length) {
            this.fetchFullMonthAvailabilities(this.selectDateTimeService.getPreviousMonth(activeDate), [], false, true);
        }
    }

    /**
     * Handles the event generated by picking a new date on the calendar popup by updating the picker value and
     * updating the current selected date.
     * @param event - Datepicker date changed event.
     */
    public async onDateChange(event: any) {
        this.selected = this.selectDateTimeService.removeTimeFromDate(new Date(event.value));
        this.localStorageService.setNested(APPOINTMENT_REQUEST, 'datetime', dateToStringNoTimeZone(this.selected));
        this.removeEmployeeFromLSForFirstAvailable();

        // If the picked date is close to the end of the month check to see if the next month is loaded and if it's not fetch it from the API
        const isNextMonthLoaded = this.monthAvailabilities.filter((m) => m.month === this.selected.getMonth() + 1);
        if (this.selectDateTimeService.getEndOfMonth(this.selected).getDate() - this.selected.getDate() < 7 && !isNextMonthLoaded.length) {
            this.selectDateTimeService.asyncCallFinishedChevronRight(false);
            this.fetchFullMonthAvailabilities(
                this.selectDateTimeService.getNextMonth(this.selected),
                [],
                false,
                false,
                HorizontalScrollDirection.Right
            );
        }

        // Remove outdated availabilities but exclude months based on current selected date
        let monthToExcudeFromDelete;
        if (this.selected.getDate() >= 15) {
            monthToExcudeFromDelete = this.adjustMonth(this.selected, 1);
        } else {
            monthToExcudeFromDelete = this.adjustMonth(this.selected, -1);
        }
        this.removeOutDatedMonthAvailabilities([new Month(monthToExcudeFromDelete.getFullYear(), monthToExcudeFromDelete.getMonth())]);

        // If the picked date is close to the start of the month check to see if the previous month is loaded and if it's not fetch it from the API
        const isPreviousMonthLoaded = this.monthAvailabilities.filter((m) => m.month === this.selected.getMonth() - 1);
        if (this.selected.getDate() < 7 && !isPreviousMonthLoaded.length) {
            this.selectDateTimeService.asyncCallFinishedChevronLeft(false);
            this.fetchFullMonthAvailabilities(
                this.selectDateTimeService.getPreviousMonth(this.selected),
                [],
                false,
                false,
                HorizontalScrollDirection.Left
            );
        }
        this.updateDaySlots(this.selected);
        this.splitSelectedDateAvailabilityTimes();
        this.manipulateDayOrTimeSlot('time-slot-active', 'off', 'time-slot-active');
        this.manipulateDayOrTimeSlot(`#day-slot-${this.selected.getDate()}-${this.selected.getMonth()}`, 'on', 'day-slot-active');
        this.updateDaysInWeek(this.dateList, this.monthAvailabilities);
        this.shouldDisableRightChevronMaxAdvanceBook = this.shouldDisableRightChevronMaxAdvance();
        this.setStepValidState();
    }

    /**
     * Handles the week scroll event (when the user clicks on the chevrons to move through the 7 day display).
     * @param direction - Direction of the movement.
     * Remarks: 7 day display = week list = this.dateList
     */
    public scrollWeekOnClick(direction: HorizontalScrollDirection) {
        if (direction === undefined) {
            return;
        }

        const firstDateCopy = new Date(this.dateList[0].date);
        const isWithinCurrentMonth = (date: Date) =>
            date.getMonth() === this.dateList[0].date.getMonth() && date.getFullYear() === this.dateList[0].date.getFullYear();
        if (direction === HorizontalScrollDirection.Left) {
            this.scrollWeekLeft(firstDateCopy, isWithinCurrentMonth);
        } else {
            this.scrollWeekRight(firstDateCopy, isWithinCurrentMonth);
        }
        const firstDayWithAvailability = this.dateList.find((dl) => dl.hasAvailabilities);
        if (firstDayWithAvailability) {
            this.selected = this.selectDateTimeService.removeTimeFromDate(firstDayWithAvailability.date);
            this.manipulateDayOrTimeSlot('time-slot-active', 'off', 'time-slot-active');
            this.localStorageService.setNested(APPOINTMENT_REQUEST, 'datetime', dateToStringNoTimeZone(this.selected));
            this.removeEmployeeFromLSForFirstAvailable();
            this.setStepValidState();
        }
        this.splitSelectedDateAvailabilityTimes();
        this.shouldDisableRightChevronMaxAdvanceBook = this.shouldDisableRightChevronMaxAdvance();
        this.manipulateDayOrTimeSlot(`#day-slot-${this.selected.getDate()}-${this.selected.getMonth()}`, 'on', 'day-slot-active');
    }

    /**
     * Checks if the left navigation chevron for the week list needs to be enabled or disabled.
     * @returns True - if the previous chevron for the week list is disabled;
     * False - if the previous chevron for the week list is not disabled.
     */
    public previousWeekEnabled() {
        const tempDateTime = new Date();
        if (!this.dateList[0]?.date) {
            return false;
        }
        if (this.selectDateTimeService.compareDateWithoutTime(this.dateList[0].date, tempDateTime) === 0) {
            return true;
        }
        return false;
    }

    /**
     * Handles the click on a time slot event.
     * @param partOfDay - An indicator if the time is a morning time or an afternoon time.
     * @param id - The index of the time slot element.
     */
    public async onTimeSlotChange(partOfDay: string, id: number) {
        this.manipulateDayOrTimeSlot('time-slot-active', 'off', 'time-slot-active');
        let newTimeParts: string[] = [];
        if (partOfDay.includes('morning')) {
            newTimeParts = convertTo24Hour(this.morningTimeSlots[id], true).split(':');
        } else if (partOfDay.includes('afternoon')) {
            newTimeParts = convertTo24Hour(this.afternoonTimeSlots[id], false).split(':');
        }
        this.selected.setHours(+newTimeParts[0], +newTimeParts[1]);

        this.localStorageService.setNested(APPOINTMENT_REQUEST, 'datetime', dateToStringNoTimeZone(this.selected));
        await this.saveEmployeeToLSForFirstAvailable();
        this.manipulateDayOrTimeSlot(`#${partOfDay}${id}`, 'on', 'time-slot-active');
        this.setStepValidState();
    }

    /**
     * The handler for the new day slot selected event.
     * Outdated months will be removed and new time slots will be generated for the selected day.
     * @param $event - The select new day slot event.
     */
    public onDaySlotChange($event: any) {
        this.manipulateDayOrTimeSlot('day-slot-active', 'off', 'day-slot-active');
        this.manipulateDayOrTimeSlot('time-slot-active', 'off', 'time-slot-active');

        this.selected = new Date($event.value);
        const monthsInWeekList = this.getMonthsInWeekList();
        const monthToExclude = this.getMonthsToExclude();
        if (monthToExclude) {
            monthsInWeekList.push(monthToExclude);
        }
        this.removeOutDatedMonthAvailabilities(monthsInWeekList);
        this.splitSelectedDateAvailabilityTimes();
        this.manipulateDayOrTimeSlot(
            `#day-slot-${new Date($event.value).getDate()}-${new Date($event.value).getMonth()}`,
            'on',
            'day-slot-active'
        );
        this.preselectTimeSlot();
        this.setStepValidState();
    }

    /**
     * Matches screen resolution with the media queries.
     * The function is called from the places where we need to show or hide chevrons depending on the design.
     * @returns True - The result is true if the screen matches one of the media queries for tablet or desktop devices.
     * False - The result is false if the screen does not match any of the media queries.
     */
    public isMatchingResolution(): boolean {
        const mediaQueries = [
            'screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)',
            'screen and (min-width: 1080px) and (max-width: 1080px) and (device-width: 1080px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1024px) and (max-width: 1024px) and (device-width: 1024px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1180px) and (max-width: 1180px) and (device-width: 1180px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1194px) and (max-width: 1194px) and (device-width: 1194px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1366px) and (max-width: 1366px) and (device-width: 1366px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1368px) and (max-width: 1368px) and (device-width: 1368px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-device-width: 602px) and (orientation: landscape) and (min-resolution: 1.331dppx) and (max-resolution: 1.332dppx)',
            'screen and (min-device-width: 602px) and (orientation: landscape) and (min-resolution: 2dppx) and (device-aspect-ratio: 40 / 23)',
            'screen and (min-width: 1280px) and (device-width: 1280px) and (max-width: 1280px) and (orientation: landscape) and (min-resolution: 1dppx) and (device-aspect-ratio: 8 / 5)',
            'screen and (min-width: 1024px)',
        ];

        return mediaQueries.some((query) => window.matchMedia(query).matches);
    }

    /**
     * Checks to see if the week has availabilities
     * @returns True if some days have availabilities
     */
    public isWeekAvailable() {
        return this.dateList.some((dl) => dl.hasAvailabilities);
    }

    /**
     * Saves the auto-chosen employee to local storage if the First Available option was used
     */
    private async saveEmployeeToLSForFirstAvailable() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;

        if (appointmentRequest.datetime) {
            // Find the availability that was picked (the specific time slot).
            const pickedAvailability = this.monthAvailabilities
                .find((ma) => ma.availabilities.find((a) => a.start === appointmentRequest.datetime.toString()))
                ?.availabilities.find((a) => a.start === appointmentRequest.datetime.toString());
            const employeeIDList = this.employeeService.getEmployeesFromServiceList(appointmentRequest.service);
            const serviceIDList = appointmentRequest.service.map((service) => service.id);
            from(this.employeeService.getEmployeeList(employeeIDList, serviceIDList))
                .pipe(takeUntil(this._destroyed$))
                .subscribe((employeeList) => {
                    // Filter the list of all available employees by the employeeID from the picked availability
                    const employeeToSave = employeeList.filter((e) => e.id === pickedAvailability?.employeeID.toString());
                    if (appointmentRequest.employee[0].firstAvailable) {
                        employeeToSave[0].firstAvailable = true;
                    }
                    if (employeeList?.length && employeeToSave) {
                        this.localStorageService.setNested(APPOINTMENT_REQUEST, 'employee', employeeToSave);
                    }
                    this.setStepValidState();
                });
        }
    }

    /**
     * Remove an employee from local storage if the chosen option is 'First Available'
     * @param appointmentRequest The appointment request from local storage
     */
    private removeEmployeeFromLSForFirstAvailable(appointmentRequest?: AppointmentRequest) {
        let apptRequest;
        if (!appointmentRequest) {
            apptRequest = appointmentRequest;
        } else {
            apptRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        }

        if (apptRequest?.employee.length && apptRequest?.employee[0].firstAvailable && apptRequest.employee[0].id !== '0') {
            const serviceIdList = apptRequest.service.map((service) => service.id);
            const employeeServicePriceList = this.employeeService.getEmployeeServicePriceListForFirstAvailability(serviceIdList);
            this.localStorageService.setNested(APPOINTMENT_REQUEST, 'employee', [
                {
                    id: '0',
                    name: 'First Available',
                    employeeServicePriceList: employeeServicePriceList,
                    firstAvailable: true,
                },
            ]);
        }
    }

    /**
     * Get the month indexes from the current week list
     * @returns A list of month indexes
     */
    private getMonthsInWeekList(): Month[] {
        const months: Month[] = [];
        for (const day of this.dateList) {
            const month = day.date.getMonth();
            if (months.findIndex((m) => m.month === month) === -1) {
                months.push(new Month(day.date.getFullYear(), month));
            }
        }
        return months;
    }

    /**
     * Returns an object containing the year and month to exclude from a removing of outdated month availabilities.
     * The month to exclude is determined based on the day of the first date in the list.
     * If the day is >= 15, the month to exclude is the next month.
     * If the day is < 15, the month to exclude is the previous month.
     * @returns a Month indexes.
     */
    private getMonthsToExclude() {
        let month!: Month;
        const day = this.dateList[0].date.getDate();
        if (day >= 15) {
            month = {
                year: this.adjustMonth(this.dateList[0].date, 1).getFullYear(),
                month: this.adjustMonth(this.dateList[0].date, 1).getMonth(),
            };
        } else if (day < 15) {
            month = {
                year: this.adjustMonth(this.dateList[0].date, -1).getFullYear(),
                month: this.adjustMonth(this.dateList[0].date, -1).getMonth(),
            };
        }

        return month;
    }

    /**
     * Populates the monthAvailabilities list based on the passed in availabilities and by creating or updating MonthAvailability instances.
     * @param availabilities - A list of availabilities that are to be added to the monthAvailabilities list.
     */
    private populateMonthAvailabilities(availabilities: Availability[]) {
        // Break down the input availabilities into months
        const months: Availability[][] = [];

        for (const availability of availabilities) {
            availability.start = dateToStringNoTimeZone(new Date(availability.start));
            availability.end = dateToStringNoTimeZone(new Date(availability.end));
            const month = availability.start.slice(0, 7);
            const index = months.findIndex((list) => list[0]?.start.slice(0, 7) === month);
            if (index === -1) {
                months.push([availability]);
            } else {
                months[index].push(availability);
            }
        }
        // For every broken down month list of availabilities create/update a MonthAvailability object and add it to the monthAvailabilities list
        for (const month of months) {
            // Check if the month exists in the monthAvailabilities list
            const monthIndex = new Date(month[0].start).getMonth();
            const monthExists = this.monthAvailabilities.findIndex((monthAvailability) => {
                return monthAvailability.month === monthIndex;
            });
            // If the month is not present in the monthAvailabilities list create a new MonthAvailability object
            if (monthExists === -1) {
                this.monthAvailabilities.push(new MonthAvailability(month));
                // If the month exists in the monthAvailabilities list update the existing MonthAvailability object
            } else {
                this.monthAvailabilities.find((m) => m.month === monthIndex)?.updateMonthAvailability(month);
            }
        }
    }

    /**
     * Fetches the availabilities from the API.
     * The fetching cosists of two calls. The first call will be awaited and will fetch 7 days starting from fetchStartDate.
     * The second call will fetch the rest of that month. The second call is not awaited.
     * @param initialFetchStartDate - The date from which the first 7 days of availabilities will be loaded.
     */
    private async fetchAvailabilities(initialFetchStartDate?: Date, selectedDate?: Date) {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;

        const startDate = initialFetchStartDate ?? new Date();
        // Increase the startDate by 6 days so that we have our initial 7 day fetch range
        let endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
        if (selectedDate) {
            endDate = new Date(selectedDate.getTime() + 6 * 24 * 60 * 60 * 1000);
        }

        if (endDate >= startDate) {
            const availabilities$: Observable<Availability[]>[] = [];
            // If the the chosen employee is 'First Available' make a call for each employee
            if (appointmentRequest?.employee[0]?.firstAvailable && this.employeeIdList.length) {
                this.navigationService.setIsLoading(true);
                this.fetchAvailabilitiesForAllEmployees(availabilities$, this.employeeIdList, startDate, endDate);
            } else {
                availabilities$.push(this.getAvailabilitiesInRange(startDate, endDate));
            }
            // Once the availabilities are fetched duplicates need to be removet (First Available case)
            forkJoin(availabilities$)
                // The pipe will flatten the returned array since it is an array of arrays if the 'First Available' option was choosen
                .pipe(
                    map((availabilities) => availabilities.reduce((acc, cur) => acc.concat(cur), [])),
                    catchError((error) => {
                        if (error.message.includes('No schedules found in the database for the following employeeID')) {
                            this.isScheduleForEmployeeFound = false;
                        }
                        return of([]);
                    })
                )
                .subscribe((availabilities) => {
                    this.handleInitialSevenDaysAvailabilities(availabilities, selectedDate, startDate, appointmentRequest);
                    this.loadRestOfMonth(endDate, appointmentRequest);
                    this.loadMonthBorders(appointmentRequest, selectedDate, startDate);
                });
        }
    }

    /**
     * A helper function that does some additional setup with the availabilities that are returned from the API for the first seven days.
     * @param availabilities The availabilities that are returned from the API.
     * @param selectedDate The current selected date.
     * @param startDate The start date for the fetch availabilities API call.
     * @param appointmentRequest The appointment request from local storage.
     */
    private handleInitialSevenDaysAvailabilities(
        availabilities: Availability[],
        selectedDate: Date | undefined,
        startDate: Date,
        appointmentRequest: AppointmentRequest
    ) {
        availabilities = this.removeDuplicateAvailabilities(availabilities);

        if (availabilities) {
            this.populateMonthAvailabilities(availabilities);
        }

        if (selectedDate) {
            this.updateDaySlots(selectedDate);
        } else {
            this.updateDaySlots(startDate);
        }

        // If the chosen appointmentRequest.datetime exists in local storage use that as selected date time,
        // Else if chosen appointmentRequest.datetime does not exist (the case when the user comes for the first time on the screen) use the first fetched availability,
        // Else set the selected date as current calendar date
        if (appointmentRequest.datetime) {
            this.selected = new Date(appointmentRequest.datetime);
        } else if (this.monthAvailabilities?.[0]?.availabilities?.[0]?.start) {
            this.selected = new Date(this.monthAvailabilities[0].availabilities[0].start);
        } else {
            this.selected = new Date();
        }
        this.splitSelectedDateAvailabilityTimes();
        this.updateDaysInWeek(this.dateList, this.monthAvailabilities);
        if (appointmentRequest.datetime) {
            this.preselectTimeSlot();
        }
        // Select day slot based on this.selected
        this.manipulateDayOrTimeSlot(`#day-slot-${this.selected.getDate()}-${this.selected.getMonth()}`, 'on', 'day-slot-active');
        this.selectDateTimeService.availabilityDataFetched();
        this.shouldDisableRightChevronMaxAdvanceBook = this.shouldDisableRightChevronMaxAdvance();
        this.setStepValidState();
        this.navigationService.setIsLoading(false);
    }

    /**
     * A helper for the fetch availabilities function. Loads the rest of the month that comes after the initial 7 day load of fetchAvailabilities.
     * @param endDate The end date for the fetch availabilities API call.
     * @param appointmentRequest The appointment request from local storage.
     */
    private loadRestOfMonth(endDate: Date, appointmentRequest: AppointmentRequest) {
        const nextCallStartDate = new Date(endDate.getTime() + 24 * 60 * 60 * 1000);
        let nextCallEndDate = this.selectDateTimeService.getEndOfMonth(nextCallStartDate);
        if (nextCallStartDate.getDate() > 28) {
            nextCallEndDate = this.selectDateTimeService.getEndOfMonth(this.adjustMonth(nextCallStartDate, 1));
        }
        if (appointmentRequest?.employee[0].firstAvailable && this.employeeIdList.length) {
            const availabilities$: Observable<Availability[]>[] = [];
            this.fetchAvailabilitiesForAllEmployees(availabilities$, this.employeeIdList, nextCallStartDate, nextCallEndDate);
            forkJoin(availabilities$)
                .pipe(
                    map((availabilities) => availabilities.reduce((acc, cur) => acc.concat(cur), [])),
                    catchError((error) => {
                        if (error.message.includes('No schedules found in the database for the following employeeID')) {
                            this.isScheduleForEmployeeFound = false;
                        }
                        return of([]);
                    })
                )
                .subscribe((availabilities) => {
                    this.handleAPIAvailabilitiesResponse(availabilities);
                    if (!this.isScheduleForEmployeeFound) {
                        this.navigationService.setIsLoading(false);
                    }
                });
        } else {
            void this.getAvailabilitiesInRangeAsync(nextCallStartDate, nextCallEndDate, HorizontalScrollDirection.Right);
        }
    }

    /**
     * A helper for the fetchAvailabilities function. Loads the availabilities for the case where days from one month are only partially needed.
     * @param appointmentRequest The appointment request from local storage.
     * @param selectedDate The current selected date.
     * @param startDate The start date for the fetch availabilities API call.
     */
    private loadMonthBorders(appointmentRequest: AppointmentRequest, selectedDate: Date | undefined, startDate: Date) {
        const today = new Date();
        if (
            selectedDate &&
            selectedDate.getDate() < 15 &&
            (selectedDate.getFullYear() > startDate.getFullYear() ||
                (selectedDate.getFullYear() === startDate.getFullYear() && selectedDate.getMonth() > today.getMonth()))
        ) {
            let onePreviousMonthStartDate = this.selectDateTimeService.getPreviousMonth(selectedDate);
            if (
                onePreviousMonthStartDate.getFullYear() === today.getFullYear() &&
                onePreviousMonthStartDate.getMonth() === today.getMonth()
            ) {
                onePreviousMonthStartDate = today;
            }
            const onePreviousMonthEndDate = this.selectDateTimeService.getEndOfMonth(onePreviousMonthStartDate);
            const availabilities$: Observable<Availability[]>[] = [];
            if (appointmentRequest?.employee[0].firstAvailable && this.employeeIdList.length) {
                this.fetchAvailabilitiesForAllEmployees(
                    availabilities$,
                    this.employeeIdList,
                    onePreviousMonthStartDate,
                    onePreviousMonthEndDate
                );
            } else {
                void this.getAvailabilitiesInRangeAsync(onePreviousMonthStartDate, onePreviousMonthEndDate, HorizontalScrollDirection.Left);
            }
            forkJoin(availabilities$)
                .pipe(map((availabilities) => availabilities.reduce((acc, cur) => acc.concat(cur), [])))
                .subscribe((availabilities) => {
                    this.handleAPIAvailabilitiesResponse(availabilities);
                });
        }
    }

    /**
     * A helper function that does some additional setup with the availabilities that are returned from the API.
     * @param availabilities The availabilities that are returned from the API.
     */
    private handleAPIAvailabilitiesResponse(availabilities: Availability[]) {
        availabilities = this.removeDuplicateAvailabilities(availabilities);
        this.populateMonthAvailabilities(availabilities);
        this.selectDateTimeService.asyncCallFinishedChevronRight(true);
        this.updateDaysInWeek(this.dateList, this.monthAvailabilities);
        this.selectDateTimeService.availabilityDataFetched();
        this.splitSelectedDateAvailabilityTimes();
        this.cd.detectChanges();
    }

    /**
     * Removes duplicate availabilities from an array of Availability objects by randomly choosing
     * one duplicate from each group of duplicates with the same start and end times.
     * @param availabilities - An array of Availability objects that may contain duplicates.
     * @returns An array of Availability objects with duplicates removed.
     */
    private removeDuplicateAvailabilities(availabilities: Availability[]) {
        // Group duplicates by start and end times as the key
        const groupedDuplicates: { [key: string]: Availability[] } = {};

        availabilities.forEach((item) => {
            const startDate = this.selectDateTimeService.removeSecondsFromDate(new Date(item.start));
            const endDate = this.selectDateTimeService.removeSecondsFromDate(new Date(item.end));
            const key = `${startDate}-${endDate}`;
            if (!groupedDuplicates[key]) {
                groupedDuplicates[key] = [];
            }
            groupedDuplicates[key].push(item);
        });

        // Randomly choose one duplicate from each group and flatten the groups
        const filteredAvailabilities: Availability[] = [];

        for (const key in groupedDuplicates) {
            if (groupedDuplicates.hasOwnProperty(key)) {
                const duplicatesGroup = groupedDuplicates[key];
                filteredAvailabilities.push(duplicatesGroup[this.randomIndex(duplicatesGroup)]);
            }
        }

        return filteredAvailabilities;
    }

    /**
     * Generates a random index for a given array.
     *
     * @param arr - The array for which a random index is generated.
     * @returns A random index within the bounds of the input array.
     */
    private randomIndex(arr: Availability[]): number {
        return Math.floor(Math.random() * arr.length);
    }

    /**
     * Fetches availabilities for all employees that are registered in the chosen service in local storage.
     * This is used with the 'First Available' option
     * @param availabilities$ A reference to an arrray that needs to be populated with the results from the API calls.
     * @param appointmentRequest The appoitment request from local storage.
     * @param startDate The start date of the API getAvailabilities call.
     * @param endDate The end date of the API getAvailabilities call.
     * @param showCalendarSpinner An indicator if the popup calendar spinner should be shown.
     */
    private fetchAvailabilitiesForAllEmployees(
        availabilities$: Observable<Availability[]>[],
        employeeIDList: string[],
        startDate: Date,
        endDate: Date,
        showCalendarSpinner: boolean = false
    ) {
        for (const employeeID of employeeIDList) {
            availabilities$.push(this.getAvailabilitiesInRange(startDate, endDate, false, showCalendarSpinner, employeeID));
        }
    }

    /**
     * Fetches the availabilities from the API for one month.
     * This function will also remove outdated availabilities by month from memory.
     * @param firstDateOfTheMonth - The start date for the fetch operation (first day of the month).
     * @param additionalMonthIndexesForExludeFromRemove - Month indexes of the months that are not to be removed from memory, besides
     * the current selected month (that is always in memory).
     */
    private fetchFullMonthAvailabilities(
        firstDateOfTheMonth: Date,
        additionalMonthIndexesForExludeFromRemove: Month[],
        showSpinner: boolean = false,
        showCalendarSpinner: boolean = false,
        direction?: HorizontalScrollDirection
    ) {
        const tempDate = new Date();
        if (this.selectDateTimeService.compareDateWithoutTime(firstDateOfTheMonth, tempDate) === -1) {
            firstDateOfTheMonth = tempDate;
        }
        const startDate = new Date(firstDateOfTheMonth);
        const endDate = this.selectDateTimeService.getEndOfMonth(firstDateOfTheMonth);
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;

        if (appointmentRequest?.employee[0].firstAvailable && this.employeeIdList.length) {
            const availabilities$: Observable<Availability[]>[] = [];
            const availabilitiesCall = new AvailabilitiesCall(startDate, endDate);
            this.availabilitiesCalls.push(availabilitiesCall);
            this.fetchAvailabilitiesForAllEmployees(availabilities$, this.employeeIdList, startDate, endDate, showCalendarSpinner);
            forkJoin(availabilities$)
                .pipe(map((availabilities) => availabilities.reduce((acc, cur) => acc.concat(cur), [])))
                .subscribe((availabilities) => {
                    availabilities = this.removeDuplicateAvailabilities(availabilities);
                    this.removeOutDatedMonthAvailabilities([
                        new Month(startDate.getFullYear(), startDate.getMonth()),
                        ...additionalMonthIndexesForExludeFromRemove,
                    ]);
                    this.populateMonthAvailabilities(availabilities);
                    this.updateDaysInWeek(this.dateList, this.monthAvailabilities);
                    if (direction === HorizontalScrollDirection.Right) {
                        this.selectDateTimeService.asyncCallFinishedChevronRight(true);
                    } else if (direction === HorizontalScrollDirection.Left) {
                        this.selectDateTimeService.asyncCallFinishedChevronLeft(true);
                    }
                    this.selectDateTimeService.calendarDataChanged();
                    this.navigationService.setIsCalendarLoading(false);
                    this.removeFromAvailabilitiesCalls(availabilitiesCall);
                });
        } else {
            const availabilitiesCall = new AvailabilitiesCall(startDate, endDate);
            this.availabilitiesCalls.push(availabilitiesCall);
            void this.getAvailabilitiesInRangeAsync(
                startDate,
                endDate,
                direction,
                showSpinner,
                showCalendarSpinner,
                undefined,
                availabilitiesCall
            );
        }
    }

    /**
     * Removes an availability call from the availabilitiesCalls.
     * @param availabilitiesCallToRemove Availibility call to remove.
     */
    private removeFromAvailabilitiesCalls(availabilitiesCallToRemove: AvailabilitiesCall) {
        const indexOfItemToRemove = this.availabilitiesCalls.indexOf(availabilitiesCallToRemove);
        if (indexOfItemToRemove > -1) {
            this.availabilitiesCalls.splice(indexOfItemToRemove, 1);
        }
    }

    /**
     * Checks to ses if specific call with startDate and endDate is in progress.
     * @returns True - if a call currently is in progress;
     *          False - if a call currently is not in progress.
     */
    private callToAvailabilitiesInitiated(startDate: Date, endDate: Date) {
        return this.availabilitiesCalls.find(
            (c) => c.startDate.getTime() === startDate.getTime() && c.endDate.getTime() === endDate.getTime()
        );
    }

    /**
     * Calls the service in order to fetch availabilities for a given date range.
     * This call is not awaited.
     * @param startDate Start date of the availabilities fetch range.
     * @param endDate End date of the availabilities fetch range.
     * @param showSpinner An indicator of whether the spinner should be displayed while loading the data from the API.
     */
    private async getAvailabilitiesInRangeAsync(
        startDate: Date,
        endDate: Date,
        direction?: HorizontalScrollDirection,
        showSpinner?: boolean,
        showCalendarSpinner?: boolean,
        employeeID?: string,
        availabilitiesCall?: AvailabilitiesCall
    ) {
        if (direction === HorizontalScrollDirection.Right) {
            this.selectDateTimeService.asyncCallFinishedChevronRight(false);
        } else if (direction === HorizontalScrollDirection.Left) {
            this.selectDateTimeService.asyncCallFinishedChevronLeft(false);
        }
        return this.availabilitiesService
            .getAvailabilities(
                this.selectDateTimeService.getDateString(startDate),
                this.selectDateTimeService.getDateString(endDate),
                availabilitiesCall ? false : showSpinner,
                showCalendarSpinner,
                employeeID
            )
            .pipe(
                takeUntil(this._destroyed$),
                catchError((error) => {
                    if (error.message.includes('No schedules found in the database for the following employeeID')) {
                        this.isScheduleForEmployeeFound = false;
                    }
                    return of([]);
                })
            )
            .subscribe((availabilities) => {
                if (availabilities) {
                    this.populateMonthAvailabilities(availabilities);
                    if (availabilitiesCall) {
                        this.removeFromAvailabilitiesCalls(availabilitiesCall);
                        this.updateDaysInWeek(this.dateList, this.monthAvailabilities);
                    }
                    if (showCalendarSpinner) {
                        this.navigationService.setIsCalendarLoading(false);
                    }
                }
                this.selectDateTimeService.asyncCallFinishedChevronRight(true);
                this.selectDateTimeService.asyncCallFinishedChevronLeft(true);
                if (!this.isScheduleForEmployeeFound) {
                    this.navigationService.setIsCalendarLoading(false);
                }
            });
    }

    /**
     * Calls the service in order to fetch availabilities for a given date range.
     * This call is awaited.
     * @param startDate Start date of the availabilities fetch range.
     * @param endDate End date of the availabilities fetch range.
     * @param showSpinner An indicator of whether the spinner should be displayed while loading the data from the API.
     */
    private getAvailabilitiesInRange(
        startDate: Date,
        endDate: Date,
        showSpinner?: boolean,
        showCalendarSpinner?: boolean,
        employeeID?: string
    ): Observable<Availability[]> {
        return this.availabilitiesService
            .getAvailabilities(
                this.selectDateTimeService.getDateString(startDate),
                this.selectDateTimeService.getDateString(endDate),
                showSpinner,
                showCalendarSpinner,
                employeeID
            )
            .pipe(takeUntil(this._destroyed$));
    }

    /**
     * Splits the availabilities for a certain day into AM and PM times and adds them to their respective lists.
     */
    private splitSelectedDateAvailabilityTimes() {
        this.morningTimeSlots = [];
        this.afternoonTimeSlots = [];

        const selectedDateString = this.selectDateTimeService.getDateString(this.selected).split('T')[0];

        // Select all availabilities for the current selected date
        const timesInDay = this.monthAvailabilities
            .find((m) => m.month === this.selected.getMonth())
            ?.availabilities.filter((a) => a.start.includes(selectedDateString));

        if (!timesInDay) {
            return;
        }

        timesInDay.sort((a1, a2) => new Date(a1.start).getTime() - new Date(a2.start).getTime());

        // Splits the selected availabilities into morning and afternoon times
        timesInDay.forEach((availability) => {
            const time = availability.start.slice(availability.start.indexOf('T') + 1);
            const dateObj = new Date(`1970-01-01T${time}`);
            const newTime = dateObj.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
            if (dateObj.getHours() < 12) {
                this.morningTimeSlots.push(newTime);
            } else {
                this.afternoonTimeSlots.push(newTime);
            }
        });
        this.cd.detectChanges();
    }

    /**
     * Selects the time slot based on the time slot (datetime) that was previously saved in local storage if one exists.
     * @returns Nothing
     */
    private selectTimeSlotFromLocalStorage() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;

        if (!appointmentRequest?.datetime) {
            return;
        }

        if (appointmentRequest?.employee[0].id === '0') {
            this.manipulateDayOrTimeSlot('time-slot-active', 'off', 'time-slot-active');
            return;
        }

        const timeInDay = convertTo12Hour(
            this.selectDateTimeService.getDateString(new Date(appointmentRequest.datetime)).split('T')[1],
            true
        );
        const partOfDay = timeInDay?.includes('AM') ? 'ts-morning-' : 'ts-afternoon-';
        const timeSlots = timeInDay && partOfDay === 'ts-morning-' ? this.morningTimeSlots : this.afternoonTimeSlots;
        const partOfDayIndex = timeSlots.findIndex((t) => t === timeInDay);

        if (timeInDay) {
            const selectedTimeSlot = this.elementRef.nativeElement.querySelector(`#${partOfDay}${partOfDayIndex}`);
            if (selectedTimeSlot) {
                selectedTimeSlot.classList.add('time-slot-active');
            }
        }

        this.setStepValidState();
    }

    /**
     * Sets the state of the form (this impacts the navigation from the screen).
     */
    private setStepValidState() {
        const selectedTimeSlot = this.elementRef.nativeElement.querySelector('.time-slot-active');
        if (!selectedTimeSlot) {
            this.navigationService.setIsStepFormValid(false);
        } else {
            this.navigationService.setIsStepFormValid(true);
        }
    }

    /**
     * Removes all months except the current selected month and months that are passed in the monthExcludedFromDelete array.
     * @param monthExcludedFromDelete - Month indexes of the months that are to be excluded from the remove operation.
     */
    private removeOutDatedMonthAvailabilities(monthExcludedFromDelete: Month[]) {
        if (!monthExcludedFromDelete.find((m) => m.year === this.selected.getFullYear() && m.month === this.selected.getMonth())) {
            monthExcludedFromDelete.push(new Month(this.selected.getFullYear(), this.selected.getMonth()));
        }
        this.monthAvailabilities = this.monthAvailabilities.filter((monthAvailability) =>
            monthExcludedFromDelete.find((m) => m.year === monthAvailability.year && m.month === monthAvailability.month)
        );
    }

    /**
     * Adds or subtracts months from a passed in date.
     * @param date - A date to which months will be added or subtracted from.
     * @param number - A positive or negative number, depending if months need to be added or subtracted.
     * @returns A new date with the added or subtracted months.
     */
    private adjustMonth(date: Date, number: number): Date {
        const newDate = new Date(date.getTime());
        const currentMonth = newDate.getMonth();
        newDate.setMonth(currentMonth + number);
        return newDate;
    }

    /**
     * Adds or subtracts days from a passed in date.
     * @param date - A date to which days will be added or subtracted from.
     * @param number - A positive or negative number, depending if days need to be added or subtracted.
     * @returns A new date with the added or subtracted days.
     */
    private adjustDays(date: Date, number: number): Date {
        const newDate = new Date(date.getTime());
        newDate.setDate(newDate.getDate() + number);
        return newDate;
    }

    /**
     * Handles the left navigation in the 7 day list.
     * @param firstDate - The current first day in the list (before the movement).
     * @param isWithinCurrentMonth - Function reference for checking if a date is within a month
     */
    private scrollWeekLeft(firstDate: Date, isWithinCurrentMonth: Function) {
        const tempDate = new Date();
        firstDate.setDate(firstDate.getDate() - 7);
        // If the start date is in the past set the first date in the 7 day display to todays date
        if (this.selectDateTimeService.compareDateWithoutTime(firstDate, tempDate) === -1) {
            firstDate = new Date();
        } else {
            // Else check if the there is need to fetch the previous month from the API
            const tempDatePreviousMonth = this.adjustMonth(firstDate, -1);
            const isPreviousMonthUnavailable =
                !isWithinCurrentMonth(tempDatePreviousMonth) &&
                firstDate.getDate() < 15 &&
                !this.isMonthAvailabilitiesLoaded(tempDatePreviousMonth);
            if (isPreviousMonthUnavailable) {
                const startDate = this.selectDateTimeService.getPreviousMonth(firstDate);
                const isMonthIndexUnavailable = this.monthAvailabilities.findIndex((m) => m.month === firstDate.getMonth() - 1) === -1;
                const isBeforeStartDate = this.selectDateTimeService.compareDateWithoutTimeAndDay(startDate, tempDate) !== -1;
                if (firstDate.getDate() < 8 && isMonthIndexUnavailable && isBeforeStartDate) {
                    this.selectDateTimeService.asyncCallFinishedChevronLeft(false);
                }
                if (
                    (firstDate.getDate() > 8 || !this.shouldDisableLeftChevron || (firstDate.getDate() < 8 && isMonthIndexUnavailable)) &&
                    isMonthIndexUnavailable &&
                    isBeforeStartDate &&
                    !this.callToAvailabilitiesInitiated(startDate, this.selectDateTimeService.getEndOfMonth(startDate))
                ) {
                    const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
                    const isFirstAvailable = appointmentRequest?.employee[0].firstAvailable && this.employeeIdList.length;
                    this.fetchFullMonthAvailabilities(
                        startDate,
                        [new Month(firstDate.getFullYear(), firstDate.getMonth())],
                        false,
                        false,
                        isFirstAvailable ? HorizontalScrollDirection.Left : undefined
                    );
                }
            }
        }
        // Update the 7 day list
        this.updateDaySlots(firstDate);
        this.updateDaysInWeek(this.dateList, this.monthAvailabilities);
    }

    /**
     * Handles the right navigation in the 7 day list.
     * @param firstDate - The current first day in the list (before the movement).
     * @param isWithinCurrentMonth - Function reference for checking if a date is within a month
     */
    private scrollWeekRight(firstDate: Date, isWithinCurrentMonth: Function) {
        firstDate.setDate(firstDate.getDate() + 7);

        // Check if the there is need to fetch the next month from the API
        const tempDateNextMonth = this.adjustMonth(firstDate, 1);
        if (!isWithinCurrentMonth(tempDateNextMonth) && firstDate.getDate() >= 15 && !this.isMonthAvailabilitiesLoaded(tempDateNextMonth)) {
            if (firstDate.getDate() > 21 && this.monthAvailabilities.findIndex((m) => m.month === firstDate.getMonth() + 1) === -1) {
                this.selectDateTimeService.asyncCallFinishedChevronRight(false);
            }
            const startDate = this.selectDateTimeService.getNextMonth(firstDate);
            if (
                (firstDate.getDate() < 21 ||
                    !this.shouldDisableRightChevron ||
                    (firstDate.getDate() > 21 && this.monthAvailabilities.findIndex((m) => m.month === firstDate.getMonth() + 1) === -1)) &&
                this.monthAvailabilities.findIndex((m) => m.month === firstDate.getMonth() + 1) === -1 &&
                !this.callToAvailabilitiesInitiated(startDate, this.selectDateTimeService.getEndOfMonth(startDate))
            ) {
                const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
                const isFirstAvailable = appointmentRequest?.employee[0].firstAvailable && this.employeeIdList.length;
                this.fetchFullMonthAvailabilities(
                    startDate,
                    [new Month(firstDate.getFullYear(), firstDate.getMonth())],
                    false,
                    false,
                    isFirstAvailable ? HorizontalScrollDirection.Right : undefined
                );
            }
        }
        // Update the 7 day list
        this.updateDaySlots(firstDate);
        this.updateDaysInWeek(this.dateList, this.monthAvailabilities);
    }

    /**
     * Checks to see if the last day of the dateList is after the max advance booking date
     * @returns True - If the right chevron should be disabled; False - If the right chevron shouldn't be disabled.
     */
    private shouldDisableRightChevronMaxAdvance() {
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        if (!siteBookingSettings?.appointmentMaxAdvanceBooking) {
            return false;
        }

        const lastDateOfTheWeek = this.dateList[6].date;
        const newActiveDate = new Date(new Date().getTime() + siteBookingSettings.appointmentMaxAdvanceBooking * 24 * 60 * 60 * 1000);
        if (this.selectDateTimeService.compareDateWithoutTime(lastDateOfTheWeek, newActiveDate) !== -1) {
            return true;
        }
        return false;
    }

    /**
     * Checks to see if availabilities are loaded for a given month.
     * @param date - A date for whos month the check is performed.
     * @returns True - If the availabilities are loaded; False - If the availabilities are not loaded.
     */
    private isMonthAvailabilitiesLoaded(date: Date): boolean {
        return this.monthAvailabilities.some((m) => m.month === date.getMonth() && m.year === date.getFullYear());
    }

    /**
     * Updates the week list with new days.
     * @param startDate - The first day of the week list.
     */
    private updateDaySlots(startDate: Date) {
        this.dateList = new Week(startDate).daysInWeek;
    }

    /**
     * Selects a time slot if a datetime that was previously chosen exists in local storage.
     * This handles the case where the user returns to this screen.
     */
    private preselectTimeSlot() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (!appointmentRequest?.datetime) {
            return;
        }

        const timeSlot = new Date(appointmentRequest.datetime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
        let partOfDay = undefined;
        let foundTimeSlotIndex = undefined;
        if (timeSlot.endsWith('AM')) {
            foundTimeSlotIndex = this.morningTimeSlots.findIndex((mts) => mts === timeSlot);
            partOfDay = 'ts-morning-';
        } else {
            foundTimeSlotIndex = this.afternoonTimeSlots.findIndex((ats) => ats === timeSlot);
            partOfDay = 'ts-afternoon-';
        }

        // The compareDateWithoutTime check will check if the user is currently on the day that is saved in local storage.
        // Only in this case the time slot is preselected, and if the user navigates to a different day, no time slot is preselected.
        if (
            foundTimeSlotIndex !== -1 &&
            this.selectDateTimeService.compareDateWithoutTime(this.selected, new Date(appointmentRequest.datetime)) === 0
        ) {
            this.manipulateDayOrTimeSlot(`#${partOfDay}${foundTimeSlotIndex}`, 'on', 'time-slot-active');
        }
    }

    /**
     * Selects or deselects a day or time slot.
     * @param slotToManipulate - An indicator if a day or time slot is to be selected/deselected.
     * @param manipulateAction - An indicator if the slotToManipulate needs to be selected or deselected.
     * @param cssClass - A name of an css class that is to be added ir removed in order to selected/deselected the slotToManipulate.
     */
    private manipulateDayOrTimeSlot(slotToManipulate: string, manipulateAction: string, cssClass: string) {
        if (manipulateAction === 'on') {
            const selectedSlot = this.elementRef.nativeElement.querySelector(slotToManipulate);
            selectedSlot?.classList.add(cssClass);
        } else if (manipulateAction === 'off') {
            const previousSelectedSlot = this.elementRef.nativeElement.querySelector(`.${slotToManipulate}`);
            if (previousSelectedSlot) {
                previousSelectedSlot.classList.remove(cssClass);
            }
        }
    }

    /**
     * Add/remove the spinner on the calendar popup during the api call
     */
    private isLoadingChanged() {
        const calendarContent = document.getElementsByClassName('mat-calendar-content')[0] as HTMLElement;
        const overlayContainer = document.getElementsByClassName('cdk-overlay-container')[0] as HTMLElement;
        const overlayPane = document.getElementsByClassName('cdk-overlay-pane')[0] as HTMLElement;
        const overlayBackdrop = document.getElementsByClassName('cdk-overlay-backdrop')[0] as HTMLElement;

        if (this.isCalendarLoading && calendarContent) {
            this.addSpinnerElements(calendarContent, overlayContainer, overlayPane, overlayBackdrop);
        } else {
            this.removeSpinnerElements(calendarContent, overlayContainer, overlayPane, overlayBackdrop);
        }
    }

    /**
     * Add css classes to show the spinner on the popup during the api call
     */
    private addSpinnerElements(
        calendarContent: HTMLElement,
        overlayContainer: HTMLElement,
        overlayPane: HTMLElement,
        overlayBackdrop: HTMLElement
    ) {
        this.cd.detectChanges();
        const calendarTable = document.getElementsByClassName('mat-calendar-table')[0] as HTMLElement;
        const divNode = document.createElement('span');
        divNode.classList.add('calendar-spinner');

        if (calendarTable) {
            calendarTable.style.display = 'none';
        }
        calendarContent.classList.add('calendar-content-spinner');
        if (calendarContent.childElementCount === 1) {
            calendarContent.appendChild(divNode);
        }
        if (overlayContainer) {
            overlayContainer.classList.add('disable-click');
        }
        if (overlayPane) {
            overlayPane.classList.add('disable-click');
        }
        if (overlayBackdrop) {
            overlayBackdrop.classList.add('disable-click');
        }
    }

    /**
     * Remove css classes for the spinner on the popup when api call is finished
     */
    private removeSpinnerElements(
        calendarContent: HTMLElement,
        overlayContainer: HTMLElement,
        overlayPane: HTMLElement,
        overlayBackdrop: HTMLElement
    ) {
        const calendarTable = document.getElementsByClassName('mat-calendar-table')[0] as HTMLElement;

        if (calendarTable) {
            calendarTable.style.display = 'table';
        }
        if (calendarContent?.classList.contains('calendar-content-spinner')) {
            calendarContent.classList.remove('calendar-content-spinner');
        }
        if (overlayContainer) {
            overlayContainer.classList.remove('disable-click');
        }
        if (overlayPane) {
            overlayPane.classList.remove('disable-click');
        }
        if (overlayBackdrop) {
            overlayBackdrop.classList.remove('disable-click');
        }
        this.selectDateTimeService.calendarDataChanged();
    }

    /**
     * Updates the hasAvailabilities status of each day in the given dateList based on the montAvailabilities provided.
     * @param dateList An array of DayInWeek objects representing the days within a week.
     * @param montAvailabilities An array of MonthAvailability objects representing the availabilities for each month.
     */
    private updateDaysInWeek(dateList: DayInWeek[], montAvailabilities: MonthAvailability[]): void {
        dateList.forEach((day) => {
            const date = day.date;
            const monthAvailability = montAvailabilities.find((ma) => ma.month === date.getMonth() && ma.year === date.getFullYear());

            if (monthAvailability) {
                const hasAvailabilities = monthAvailability.availabilities.some((a) => new Date(a.start).getDate() === date.getDate());
                day.hasAvailabilities = hasAvailabilities;
            }
        });

        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.subscriptions = [];
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
