import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Site } from '@app/models/site.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { ContentService } from '@app/services/content.service';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { CUSTOMER_ID, LocalStorageService, SITE, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { PetService } from '@app/services/pet.service';
import { UserAccountService } from '@app/services/user-account.service';
import { hexToRgb, sanitizeColor } from '@app/shared/helpers/extensions';
import { environment } from '@environments/environment';
import { CreateAccountFormComponent } from '../create-account-form/create-account-form.component';

export enum ScreenSize {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop',
}

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
    @HostBinding('class') classes = 'login-component-container';
    public url: string = '';
    private site: Site;
    public customerIdWithoutPrefix: string = '';
    public userAccountFeatureEnabled: boolean = true;
    constructor(
        private localStorageService: LocalStorageService,
        private userAccountService: UserAccountService,
        private bottomSheet: MatBottomSheet,
        public contentService: ContentService,
        private router: Router,
        private featureFlagService: FeatureFlagService,
        private petService: PetService
    ) {
        this.site = this.localStorageService.get(SITE) as Site;
    }

    ngOnInit() {
        this.userAccountFeatureEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts);
        const authService = environment.authService;
        const customerID = this.localStorageService.getString(CUSTOMER_ID);
        this.customerIdWithoutPrefix = customerID?.replace(/.*?(\d+)/, '$1'); //NOSONAR
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        const themeColor = hexToRgb(siteBookingSettings.themeColor);
        const sanitizedThemeColor = sanitizeColor(themeColor);
        const screenSize = this.getScreenSize();
        this.url = `${authService}?customerID=${this.customerIdWithoutPrefix}&removeExistingTokens=1&color=${sanitizedThemeColor}&viewSize=${screenSize}&application=dsb`;
    }

    @HostListener('window:message', ['$event'])
    public onMessageReceived(event: any): void {
        if (
            event.data &&
            event.data.data &&
            event.data.result === 'success' &&
            event.data.data.idToken &&
            event.data.data.accessToken &&
            event.data.data.refreshToken
        ) {
            this.userAccountService
                .login(event.data.data.idToken, event.data.data.accessToken, event.data.data.refreshToken, this.customerIdWithoutPrefix)
                .subscribe({
                    next: () => {
                        this.closeBottomSheet();
                        this.userAccountService.handleAccountCreation(
                            this.site,
                            this.contentService.selectedStepIndex,
                            this.navigateToRoute.bind(this)
                        );
                    },
                    error: (error) => {
                        console.error('Login error', error);
                    },
                });
        }
    }

    public getScreenSize(): ScreenSize {
        const tabletQueries = [
            'screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)',
            'screen and (min-width: 1080px) and (max-width: 1080px) and (device-width: 1080px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1024px) and (max-width: 1024px) and (device-width: 1024px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1180px) and (max-width: 1180px) and (device-width: 1180px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1194px) and (max-width: 1194px) and (device-width: 1194px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1366px) and (max-width: 1366px) and (device-width: 1366px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-width: 1368px) and (max-width: 1368px) and (device-width: 1368px) and (orientation: landscape) and (min-resolution: 1dppx)',
            'screen and (min-device-width: 602px) and (orientation: landscape) and (min-resolution: 1.331dppx) and (max-resolution: 1.332dppx)',
            'screen and (min-device-width: 602px) and (orientation: landscape) and (min-resolution: 2dppx) and (device-aspect-ratio: 40 / 23)',
            'screen and (min-width: 1280px) and (device-width: 1280px) and (max-width: 1280px) and (orientation: landscape) and (min-resolution: 1dppx) and (device-aspect-ratio: 8 / 5)',
        ];
        const desktopQueries = ['screen and (min-width: 1024px)'];

        if (tabletQueries.some((query) => window.matchMedia(query).matches)) {
            return ScreenSize.Tablet;
        } else if (desktopQueries.some((query) => window.matchMedia(query).matches)) {
            return ScreenSize.Desktop;
        } else {
            return ScreenSize.Mobile;
        }
    }

    public closeBottomSheet() {
        this.bottomSheet.dismiss();
    }

    public createAccount() {
        this.bottomSheet.open(CreateAccountFormComponent, { disableClose: true });
    }

    public forgetPassword() {
        const url = this.site?.bookingURL + '/User/ForgotPassword';
        window.location.href = url.startsWith('http') ? url : 'https://' + url;
    }

    private navigateToRoute(selectedStep: any) {
        void this.router.navigate([selectedStep.stepRoute], {
            queryParams: {
                DSID: this.localStorageService.getString(CUSTOMER_ID),
            },
        });

        this.contentService.isStepCompleted = selectedStep.index;
    }
}
