import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from './constants';

export interface IConfig {
    production: boolean;
    env: string;
    region: string;
    identityPoolID: string;
    serviceUrl: string;
    calendarAvailabilitySearchThreshold: number;
    dsbNewRelicLicenseKey: string;
    dsbNewRelicApplicationID: string;
    dsbNewRelicAccountID: string;
    dsbNewRelicTrustKey: string;
    launchdarklyApiKey: string;
    gaTrackingID: string;
    baseCardConnectUrl: string;
    authService: string;
    localesUrl: string;
    clientAuthServiceApiUrl: string;
    clientAuthServiceApiKey: string;
    posApiUrl: string;
    posApiSecretKey: string;
    dsbApiUrl: string;
    dsbApiSecretKey: string;
    defaultCacheLifeTimeInSeconds: number;
    serviceListCacheLifeTimeInSeconds: number;
    employeeListCacheLifeTimeInSeconds: number;
    reCaptchaSiteKey: string;
    googleMapsPlacesApiKey: string;
}

@Injectable()
export class ConfigService {
    private cachedConfig!: IConfig;

    public get config(): IConfig {
        return this.cachedConfig;
    }

    constructor(@Inject(APP_CONFIG) config: IConfig) {
        this.cachedConfig = config;
    }
}
