import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ButtonName } from '@app/components/content/content.component';
import { CreateAccountFormComponent } from '@app/components/user-accounts/create-account-form/create-account-form.component';
import { LoginFormComponent } from '@app/components/user-accounts/login-form/login-form.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Client } from '@app/models/client.model';
import { Site } from '@app/models/site.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { ContentService } from '@app/services/content.service';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, SITE, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { SiteService } from '@app/services/site.service';
import { UserAccountService } from '@app/services/user-account.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

declare let google: any;

@Component({
    selector: 'app-enter-your-information',
    templateUrl: './enter-your-information.component.html',
    styleUrls: ['./enter-your-information.component.scss'],
})
export class EnterYourInformationComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() disabled: boolean = false;
    @Input() showLoginSection: boolean = true;
    @Input() showClientInfoPageTitle: boolean = true;
    @Input() canShowPasswordField: boolean = false;
    @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

    public ngUnsubscribe: Subject<any> = new Subject();
    public supportsPets: boolean = false;
    public formGroup: FormGroup;
    public keyDecimal: boolean = false;
    public userAccountFeatureEnabled: boolean = true;
    public clientAddressFeatureEnabled: boolean = true;
    public userLoggedIn = false;
    public accountBookingRequireAddressFields = true;
    public accountBookingShowAddressFields = true;

    private navigationButtonSubscription!: Subscription;
    private readonly emailRegEx = /^[^@]+@[A-Za-z]+(\.[A-Za-z]+)+$/;
    private minLength = 8;
    private specialCharacters = '~!@#$%^&*()_+={}[]|:;"\'<>,.?/';
    private readonly passwordRegEx = new RegExp('^(?=.{' + this.minLength + ',})(?!.* )(?=.*[' + this.specialCharacters + ']).*$');
    private _destroyed$: Subject<void>;

    constructor(
        private fb: FormBuilder,
        private navigationService: NavigationService,
        private localStorageService: LocalStorageService,
        private featureFlagService: FeatureFlagService,
        private userAccountService: UserAccountService,
        private bottomSheet: MatBottomSheet,
        private contentService: ContentService,
        private ngZone: NgZone,
        private siteService: SiteService
    ) {
        this._destroyed$ = new Subject();
        const site = this.localStorageService.get(SITE) as Site;
        this.supportsPets = site?.supportsPets;
        this.userLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        this.formGroup = this.fb.group({
            firstName: new FormControl('', [Validators.required, this.noWhiteSpaceValidator()]),
            lastName: new FormControl('', [Validators.required, this.noWhiteSpaceValidator()]),
            email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegEx)]),
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[- 0-9()]+')]),
            password: new FormControl(''),
            enableEmailMarketing: new FormControl(true),
            enableTextMarketing: new FormControl(true),
            address1: new FormControl(''),
            address2: new FormControl(''),
            city: new FormControl(''),
            state: new FormControl(''),
            zipCode: new FormControl(''),
        });

        this.contentService?.dataUpdated$?.pipe(take(1)).subscribe((data) => {
            if (data === this.contentService.logout) {
                const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
                appointmentRequest.client = new Client();
                this.localStorageService.set(APPOINTMENT_REQUEST, appointmentRequest);
            }
        });
    }

    ngOnInit() {
        this.userAccountFeatureEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts);
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (
                (buttonName === ButtonName.PetInformation && this.supportsPets) ||
                (buttonName === ButtonName.ReviewDetails && !this.supportsPets)
            ) {
                const client = new Client(this.formGroup.value);
                client.firstName = client.firstName.trim();
                client.lastName = client.lastName.trim();
                client.phoneNumber = client.phoneNumber.trim();
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'client', client);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            } else if (status === 'INVALID') {
                this.navigationService.setIsStepFormValid(false);
            }
        });
        this.previouslyAddedClientInfo();
        if (this.canShowPasswordField) {
            const passwordControl = this.formGroup.get('password');
            passwordControl?.setValidators([Validators.required, Validators.pattern(this.passwordRegEx)]);
            passwordControl?.updateValueAndValidity();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.clientAddressFeatureEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.ClientAddress);
            this.getSiteBookingSettings().subscribe((settings) => {
                if (settings) {
                    this.accountBookingRequireAddressFields = settings.accountBookingRequireAddressFields ?? false;
                    this.accountBookingShowAddressFields = settings.accountBookingShowAddressFields ?? false;
                    if (this.clientAddressFeatureEnabled && this.accountBookingShowAddressFields) {
                        this.loadAutocomplete();
                        if (this.accountBookingRequireAddressFields) {
                            this.setRequiredFields();
                        }
                    }
                }
                this.navigationService.setIsLoading(false);
            });
        });
    }

    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    public onClickLogIn() {
        this.bottomSheet.open(LoginFormComponent, { disableClose: true });
    }

    public onClickCreateAccount() {
        this.bottomSheet.open(CreateAccountFormComponent, { disableClose: true });
    }

    public areFieldsValid(): boolean {
        const fields = ['firstName', 'lastName', 'phoneNumber'];
        fields.forEach((field) => this.formGroup.controls[field].setValue(this.formGroup.controls[field].value.trim()));
        return !fields.some((field) => this.formGroup.controls[field].invalid);
    }

    private getSiteBookingSettings(): Observable<SiteBookingSettings | null> {
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        if (siteBookingSettings) {
            return of(siteBookingSettings);
        } else {
            const customerID = this.localStorageService.getString(CUSTOMER_ID);
            return this.siteService.getSiteBookingSettings(customerID).pipe(
                tap((result) => this.localStorageService.set(SITE_BOOKING_SETTINGS, result)),
                catchError(() => of(null)),
                takeUntil(this._destroyed$)
            );
        }
    }

    private loadAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.searchInput?.nativeElement, {
            types: ['geocode'],
        });
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place = autocomplete.getPlace();
                this.extractAddressDetails(place);
            });
        });
    }

    private extractAddressDetails(place: any) {
        if (!place.address_components) {
            return;
        }
        ['city', 'state', 'zipCode'].forEach((field) => {
            this.formGroup.get(field)?.setValue('');
        });
        let streetNumber = '';
        let isStreetNumberOrRouteExist = false;
        for (const component of place.address_components) {
            const types = component.types;
            if (types.includes('street_number')) {
                isStreetNumberOrRouteExist = true;
                streetNumber = component.long_name.trim();
            } else if (types.includes('route')) {
                isStreetNumberOrRouteExist = true;
                const streetAddress = component.long_name.trim();
                const address1 = streetNumber ? streetNumber + ' ' + streetAddress : streetAddress;
                this.formGroup.controls.address1.setValue(address1);
            } else if (types.includes('premise') || types.includes('neighborhood')) {
                if (!isStreetNumberOrRouteExist) {
                    this.formGroup.controls.address1.setValue(component.long_name.trim());
                }
            } else if (types.includes('locality')) {
                this.formGroup.controls.city.setValue(component.long_name.trim());
            } else if (types.includes('administrative_area_level_1')) {
                this.formGroup.controls.state.setValue(component.long_name.trim());
            } else if (types.includes('postal_code')) {
                this.formGroup.controls.zipCode.setValue(component.long_name.trim());
            }
        }
    }

    private previouslyAddedClientInfo() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (appointmentRequest?.client?.firstName) {
            this.formGroup.setValue({
                firstName: appointmentRequest.client.firstName,
                lastName: appointmentRequest.client.lastName,
                email: appointmentRequest.client.email,
                phoneNumber: appointmentRequest.client.phoneNumber,
                password: '',
                enableEmailMarketing: appointmentRequest.client.enableEmailMarketing ?? false,
                enableTextMarketing: appointmentRequest.client.enableTextMarketing ?? false,
                address1: appointmentRequest.client.address1 ?? '',
                address2: appointmentRequest.client.address2 ?? '',
                city: appointmentRequest.client.city ?? '',
                state: appointmentRequest.client.state ?? '',
                zipCode: appointmentRequest.client.zipCode ?? '',
            });
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }

    private noWhiteSpaceValidator(): ValidatorFn {
        return (control: FormControl | AbstractControl): { [key: string]: any } | null => {
            if (control.value && control.value.trim().length === 0) {
                return { whitespace: true };
            }
            return null;
        };
    }

    private setRequiredFields() {
        ['address1', 'address2', 'city', 'state', 'zipCode'].forEach((field) => {
            this.formGroup.get(field)?.setValidators([Validators.required, this.noWhiteSpaceValidator()]);
            this.formGroup.get(field)?.updateValueAndValidity();
        });
    }
}
