import { Injectable } from '@angular/core';
import { Site } from '@app/models/site.model';
import { SiteApiService } from './api/site-api.service';

@Injectable({
    providedIn: 'root',
})
export class SiteService {
    constructor(private siteApiService: SiteApiService) {}

    public getSiteBookingSettings(daySmartAccountID: string) {
        return this.siteApiService.getSiteBookingSettings(daySmartAccountID);
    }
    public getSite(daySmartAccountID: string): Promise<Site> {
        return this.siteApiService.getSite(daySmartAccountID);
    }
}
