import { Injectable } from '@angular/core';
import { Pet } from '@app/models/pet.model';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { PetApiService } from './api/pet-api.service';
import { LocalStorageService, PET_LIST } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class PetService {
    private petListSubject = new BehaviorSubject<Pet[] | null>(null);
    public petList$ = this.petListSubject.asObservable();
    constructor(
        private petApiService: PetApiService,
        private localStorageService: LocalStorageService
    ) {}

    public getPetTypeList() {
        return this.petApiService.getPetTypeList();
    }

    getPetList(daySmartAccountId: string, onlineID: string, forceClear: boolean = false): Observable<Pet[]> {
        if (forceClear) {
            this.localStorageService.remove(PET_LIST);
        }

        let petList: Pet[] = [];

        if (!this.localStorageService.has(PET_LIST)) {
            return this.petApiService.getPetList(daySmartAccountId, onlineID).pipe(
                tap((data) => {
                    // Update local variable with fetched data
                    this.localStorageService.set(PET_LIST, data);
                    petList = data;
                    this.petListSubject.next(data);
                }),
                catchError((error) => {
                    console.error('Error fetching pet list:', error);
                    // Return an empty array on error
                    return of([] as Pet[]);
                })
            );
        } else {
            petList = this.localStorageService.get(PET_LIST) as Pet[];
            this.petListSubject.next(petList);
            return of(petList);
        }
    }

    public updatePetList(newPets: Pet[]) {
        this.localStorageService.set(PET_LIST, newPets);
        this.petListSubject.next(newPets);
    }

    public addPet(pet: Pet) {
        const currentPets = this.petListSubject.getValue() || [];
        currentPets?.push(pet);
        this.updatePetList(currentPets);
    }

    clearPetList(): void {
        if (this.localStorageService.has(PET_LIST)) {
            this.localStorageService.remove(PET_LIST);
        }
    }
}
