import { Injectable } from '@angular/core';
import { ServiceApiService } from '@api-services/service-api.service';
import { Service } from '@app/models/service.model';

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    constructor(private serviceApiService: ServiceApiService) {}

    public getServiceList() {
        return this.serviceApiService.getServiceList();
    }

    public getResourceIDsFromServiceList(serviceList: Service[]) {
        const resourceIDs = new Set(serviceList.flatMap((service) => service.resourceIDList));
        return Array.from(resourceIDs);
    }
}
