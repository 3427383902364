import { Injectable } from '@angular/core';
import { SetupIntent } from '@app/models/setupIntent.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { environment } from '@environments/environment';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class StripeApiService {
    private customerID;

    constructor(
        private appSyncService: AppSyncService,
        private localStorageService: LocalStorageService
    ) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }

    public async getStripePublishableKey(): Promise<string> {
        const client = this.appSyncService.getClient(environment.serviceUrl);
        const getStripePublishableKeyQuery = gql`
            query getStripePublishableKey($daySmartAccountID: String!) {
                getStripePublishableKey(input: { daySmartAccountID: $daySmartAccountID }) {
                    stripePublishableKey
                }
            }
        `;

        const queryResult = await client.query({
            query: getStripePublishableKeyQuery,
            variables: { daySmartAccountID: this.customerID },
            fetchPolicy: 'no-cache',
        });
        const result = queryResult as ApolloQueryResult<AppSyncResponse>;
        return result.data['getStripePublishableKey']['stripePublishableKey'] as string;
    }

    public async createSetupIntent(): Promise<SetupIntent> {
        const client = this.appSyncService.getClient(environment.serviceUrl);
        const createSetupIntentMutation = gql`
            mutation createSetupIntent($daySmartAccountID: String!) {
                createSetupIntent(input: { daySmartAccountID: $daySmartAccountID }) {
                    clientSecret
                    setupIntentID
                }
            }
        `;
        const mutationResult = await client.mutate({
            mutation: createSetupIntentMutation,
            variables: { daySmartAccountID: this.customerID },
        });
        const response = SetupIntent.deserializeAppSyncResponse(mutationResult);
        return response;
    }

    public async getStripeSurchargeRate(): Promise<number> {
        const client = this.appSyncService.getClient(environment.serviceUrl);
        const getStripeSurchargeRateQuery = gql`
            query getStripeSurchargeRate($daySmartAccountID: String!) {
                getStripeSurchargeRate(input: { daySmartAccountID: $daySmartAccountID }) {
                    rate
                }
            }
        `;

        const queryResult = await client.query({
            query: getStripeSurchargeRateQuery,
            variables: { daySmartAccountID: this.customerID },
            fetchPolicy: 'no-cache',
        });
        const result = queryResult as ApolloQueryResult<AppSyncResponse>;
        return result.data['getStripeSurchargeRate']['rate'] as number;
    }
}
