import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Pet } from '@app/models/pet.model';
import { PetBreed, PetType } from '@app/models/petType.model';
import { APPOINTMENT_REQUEST, LocalStorageService } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { PetService } from '@app/services/pet.service';
import { from, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-pet-information',
    templateUrl: './pet-information.component.html',
    styleUrls: ['./pet-information.component.scss'],
})
export class PetInformationComponent implements OnInit, OnDestroy {
    public ngUnsubscribe: Subject<any> = new Subject();
    private navigationButtonSubscription!: Subscription;
    public formGroup: FormGroup;
    petTypeList: PetType[] = [];
    petBreedList: PetBreed[] = [];
    private _destroyed$: Subject<void>;
    isValidForm: boolean = false;

    @Input() disabled: boolean = false;
    @Input() showAddPetTitle: boolean = false;
    @Output() isValidFormChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(
        private petService: PetService,
        private fb: FormBuilder,
        private navigationService: NavigationService,
        private localStorageService: LocalStorageService
    ) {
        this._destroyed$ = new Subject();
        this.formGroup = this.fb.group({
            name: new FormControl(null, [Validators.required, this.noWhiteSpaceValidator()]),
            petType: new FormControl(null),
            petBreed: new FormControl(null),
            weight: new FormControl(null),
        });
    }

    ngOnInit() {
        this.fetchPetTypes();
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (buttonName === ButtonName.ReviewDetails) {
                const formValues = this.formGroup.value;
                const pet: Pet = {
                    name: formValues.name,
                };

                // Conditionally add optional fields only if they have values
                if (formValues.petType) {
                    pet.petType = formValues.petType;
                }
                if (formValues.petBreed) {
                    pet.petBreed = formValues.petBreed;
                }
                if (formValues.weight) {
                    pet.weight = formValues.weight;
                }

                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'petInfo', pet);
                this.petService.addPet(pet);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            this.isValidForm = status === 'VALID';

            if (!this.showAddPetTitle) {
                this.navigationService.setIsStepFormValid(this.isValidForm);
            }

            this.isValidFormChange.emit(this.isValidForm);
        });
        this.previouslySelectedPetInfo();
    }

    private previouslySelectedPetInfo(): void {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const petInfo = appointmentRequest?.petInfo;

        if (petInfo && !this.showAddPetTitle) {
            this.updatePetBreedList(petInfo.petType);
            this.updateFormGroup(petInfo);
        } else {
            this.resetFormGroup();
        }
    }

    private updatePetBreedList(petType: PetType | undefined): void {
        if (petType?.petBreedList?.length) {
            this.petBreedList = petType.petBreedList;
        } else {
            this.petBreedList = [];
        }
    }

    private updateFormGroup(petInfo: Pet): void {
        this.formGroup.setValue({
            name: petInfo.name,
            petType: petInfo.petType || null,
            petBreed: this.petBreedList.length > 0 ? this.petBreedList : null,
            weight: petInfo.weight || '',
        });
    }

    private resetFormGroup(): void {
        this.formGroup.reset();
    }

    /** Need this to have a default selection for mat-select */
    public comparePetTypeOptions(p1: PetType, p2: PetType): boolean {
        return p1 && p2 && p1.name === p2.name;
    }
    public comparePetBreedOptions(p1: PetBreed, p2: PetBreed): boolean {
        return p1 && p2 && p1.name === p2.name;
    }

    private fetchPetTypes() {
        from(this.petService.getPetTypeList())
            .pipe(takeUntil(this._destroyed$))
            .subscribe((petTypeList) => {
                this.petTypeList = petTypeList;
                this.formGroup.updateValueAndValidity();
            });
    }

    public petTypeChange() {
        const petType: PetType = this.formGroup.get('petType')!.value;
        this.petBreedList = petType.petBreedList;
        const petBreed = this.formGroup.get('petBreed');
        petBreed!.reset();
        petBreed!.updateValueAndValidity();
    }
    public noWhiteSpaceValidator(): ValidatorFn {
        return (control: FormControl | AbstractControl): { [key: string]: any } | null => {
            if (control.value && control.value.trim().length === 0) {
                return { whitespace: true };
            }
            return null;
        };
    }

    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
