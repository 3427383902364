<div class="calendar-header">
    <button mat-icon-button class="button-previous chevron-position" (click)="previousClicked()" [disabled]="previousEnabled()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span class="calendar-header-label header-label">{{ periodLabel }}</span>
    <button
        mat-icon-button
        qa="forward-button-arrow"
        class="button-next chevron-position"
        (click)="nextClicked()"
        [disabled]="nextEnabled()"
    >
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>
