import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { SelectDateTimeService } from '@app/components/booking-steps/select-date-time/select-date-time.service';

@Directive({
    selector: '[horizontalScroll]',
    exportAs: 'horizontalScroll',
})
export class HorizontalScrollDirective {
    @Input('horizontalScroll') tagName!: string;
    public mouseDown = false;
    public startX!: number;
    public scrollLeft!: number;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        private selectDateTimeService: SelectDateTimeService
    ) {}

    @HostListener('wheel', ['$event'])
    public onScroll(event: WheelEvent) {
        const horizontalScrollBox = document.querySelector('.horizontal-scroll-box') as HTMLElement;
        if (!horizontalScrollBox) {
            return;
        }

        if (!this.isPartOfTheHorizontalBox()) {
            return;
        }
        const scrollWidth = (horizontalScrollBox.scrollLeft += event.deltaY);
        this.toggleArrowIcons(horizontalScrollBox, scrollWidth);
        event.preventDefault();
    }

    @HostListener('mousedown', ['$event'])
    public onMouseDown(event: MouseEvent) {
        this.mouseDown = true;
        this.startX = event.pageX - this.element.nativeElement.offsetLeft;
        this.scrollLeft = this.element.nativeElement.scrollLeft;
        this.renderer.addClass(this.element.nativeElement, 'dragging');
    }

    @HostListener('touchstart', ['$event'])
    public onTouchStart(event: TouchEvent) {
        this.mouseDown = true;
        this.startX = event.touches[0].pageX - this.element.nativeElement.offsetLeft;
        this.scrollLeft = this.element.nativeElement.scrollLeft;
        this.renderer.addClass(this.element.nativeElement, 'dragging');
    }

    @HostListener('mouseup')
    public onMouseUp() {
        this.stopDragging();
    }

    @HostListener('touchend')
    public onTouchEnd() {
        this.stopDragging();
    }

    @HostListener('mouseleave')
    public onMouseLeave() {
        this.stopDragging();
    }

    @HostListener('touchcancel')
    public onTouchCancel() {
        this.stopDragging();
    }

    @HostListener('mousemove', ['$event'])
    public onMouseMove(event: MouseEvent) {
        event.preventDefault();
        if (!this.mouseDown) {
            return;
        }
        this.renderer.addClass(this.element.nativeElement, 'pointer-event');
        const x = event.pageX - this.element.nativeElement.offsetLeft;
        const scroll = x - this.startX;
        this.element.nativeElement.scrollLeft = this.scrollLeft - scroll;
        this.toggleArrowIcons(this.element.nativeElement, this.element.nativeElement.scrollLeft);
    }

    @HostListener('touchmove', ['$event'])
    public onTouchMove(event: TouchEvent) {
        const horizontalScrollBox = document.querySelector('.horizontal-scroll-box') as HTMLElement;
        if (!horizontalScrollBox) {
            return;
        }
        this.renderer.addClass(this.element.nativeElement, 'pointer-event');
        const x = event.touches[0].pageX - this.element.nativeElement.offsetLeft;
        const scroll = x - this.startX;
        this.element.nativeElement.scrollLeft = this.scrollLeft - scroll;
        this.toggleArrowIcons(this.element.nativeElement, this.element.nativeElement.scrollLeft);
    }

    public scrollOnClick(direction: string) {
        const scrollWidth = (this.element.nativeElement.scrollLeft += direction === 'left' ? -250 : 250);
        this.toggleArrowIcons(this.element.nativeElement, scrollWidth);
    }

    private stopDragging() {
        this.mouseDown = false;
        this.renderer.removeClass(this.element.nativeElement, 'dragging');
        this.renderer.removeClass(this.element.nativeElement, 'pointer-event');
    }

    private isPartOfTheHorizontalBox(): boolean {
        if (!this.element) {
            return false;
        }

        if (this.element.nativeElement.classList.contains(this.tagName)) {
            return true;
        }

        return this.isPartOfTheHorizontalBox();
    }

    public toggleArrowIcons(horizontalScrollBox: HTMLElement, scrollVal: number) {
        const arrowIcons = document.querySelectorAll('.icon .mat-icon');

        if (!arrowIcons[0].parentElement || !arrowIcons[1].parentElement) {
            return;
        }

        const maxScrollableWidth = horizontalScrollBox.scrollWidth - horizontalScrollBox.clientWidth;
        const isAtStart = scrollVal <= 0;
        const isAtEnd = maxScrollableWidth - scrollVal <= 1;

        arrowIcons[0].parentElement.style.display = isAtStart ? 'none' : 'flex';
        arrowIcons[1].parentElement.style.display = isAtEnd ? 'none' : 'flex';
    }
}
