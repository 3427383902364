<div *ngIf="!disabled" class="step-container">
    <div class="header-container title-gap">
        <h1 *ngIf="showClientInfoPageTitle" qa="client-pet-information-title" class="step-header-main">
            {{ supportsPets ? 'Client & Pet Information' : 'Client information' }}
            <span *ngIf="supportsPets" class="mini-step-header"> 1 of 2</span>
        </h1>
        <h2 *ngIf="!userAccountFeatureEnabled || !showLoginSection" class="step-header-secondary">All fields are required.</h2>
        <ng-container *ngIf="userAccountFeatureEnabled && showLoginSection && !userLoggedIn">
            <button id="log-in-button" mat-stroked-button (click)="onClickLogIn()" qa="log-in-button">Log In</button>
            <button id="create-an-account-button" mat-stroked-button (click)="onClickCreateAccount()" qa="create-an-account-button">
                Create an Account
            </button>
            <h1 class="line">or</h1>
            <h2 class="step-header-secondary user-accounts">Continue as a guest</h2>
        </ng-container>
    </div>
    <form class="form-container" [formGroup]="formGroup">
        <div class="form-field">
            <label>
                First Name
                <span>*</span>
            </label>
            <mat-form-field>
                <input matInput qa="first-name" name="firstName" autocomplete="turnoff" formControlName="firstName" maxlength="20" />
            </mat-form-field>
            <mat-error *ngIf="formGroup.controls.firstName.touched && formGroup.controls.firstName.invalid" class="error-message"
                >Please enter a valid first name</mat-error
            >
        </div>
        <div class="form-field">
            <label>
                Last Name
                <span>*</span>
            </label>
            <mat-form-field>
                <input matInput qa="last-name" name="lastName" autocomplete="turnoff" formControlName="lastName" maxlength="20" />
            </mat-form-field>
            <mat-error *ngIf="formGroup.controls.lastName.touched && formGroup.controls.lastName.invalid" class="error-message"
                >Please enter a valid last name</mat-error
            >
        </div>
        <div class="form-field" [ngClass]="{ 'error-message-margin': formGroup.controls.email.invalid }">
            <label>
                Email
                <span>*</span>
            </label>
            <mat-form-field [floatLabel]="'auto'">
                <input
                    placeholder="email@address.com"
                    matInput
                    type="email"
                    name="email"
                    formControlName="email"
                    autocomplete="turnoff"
                    maxlength="60"
                    qa="email"
                />
            </mat-form-field>
            <mat-error *ngIf="formGroup.controls.email.touched && formGroup.controls.email.invalid" class="error-message">
                Please enter a valid email
            </mat-error>
        </div>
        <div class="form-field">
            <label>
                Phone Number
                <span>*</span>
            </label>
            <mat-form-field [floatLabel]="'auto'">
                <input
                    placeholder="(555) 555-555"
                    type="tel"
                    matInput
                    name="phoneNumber"
                    autocomplete="turnoff"
                    formControlName="phoneNumber"
                    maxlength="30"
                    qa="phone-number"
                />
            </mat-form-field>
        </div>
        <div class="form-field" *ngIf="canShowPasswordField">
            <label>
                Password
                <span>*</span>
            </label>
            <mat-form-field>
                <input matInput type="password" qa="password" name="password" formControlName="password" autocomplete="turnoff" />
            </mat-form-field>
            <mat-error
                [ngClass]="{
                    'password-instruction': !formGroup.controls.password.touched || formGroup.controls.password.valid,
                    'password-instruction-invalid': formGroup.controls.password.invalid && formGroup.controls.password.touched
                }"
                >A password must contain a minimum of 8 characters and at least 1 special character.</mat-error
            >
        </div>
        <div class="form-field">
            <p class="updates-text">
                How you would like to receive<br class="hidden-ss" />
                updates and promotions?
            </p>
            <mat-checkbox formControlName="enableEmailMarketing">Email</mat-checkbox>
            <mat-checkbox formControlName="enableTextMarketing">Text messages</mat-checkbox>
        </div>
        <div *ngIf="clientAddressFeatureEnabled && accountBookingShowAddressFields">
            <div class="form-field">
                <label>
                    Address Line 1
                    <span *ngIf="accountBookingRequireAddressFields">*</span>
                </label>
                <mat-form-field>
                    <input
                        #searchInput
                        matInput
                        type="text"
                        qa="address-line-1"
                        name="address1"
                        autocomplete="on"
                        formControlName="address1"
                        maxlength="60"
                    />
                </mat-form-field>
                <mat-error *ngIf="formGroup.controls.address1.touched && formGroup.controls.address1.invalid" class="error-message">
                    Please enter a valid Address Line 1
                </mat-error>
            </div>
            <div class="form-field">
                <label>
                    Address Line 2
                    <span *ngIf="accountBookingRequireAddressFields">*</span>
                </label>
                <mat-form-field>
                    <input
                        matInput
                        type="text"
                        qa="address-line-2"
                        name="address2"
                        autocomplete="on"
                        formControlName="address2"
                        maxlength="30"
                    />
                </mat-form-field>
                <mat-error *ngIf="formGroup.controls.address2.touched && formGroup.controls.address2.invalid" class="error-message">
                    Please enter a valid Address Line 2
                </mat-error>
            </div>
            <div class="form-field">
                <label>
                    City
                    <span *ngIf="accountBookingRequireAddressFields">*</span>
                </label>
                <mat-form-field>
                    <input matInput type="text" qa="city" name="city" autocomplete="on" formControlName="city" maxlength="30" />
                </mat-form-field>
                <mat-error *ngIf="formGroup.controls.city.touched && formGroup.controls.city.invalid" class="error-message">
                    Please enter a valid City
                </mat-error>
            </div>
            <div class="form-row">
                <div class="form-field">
                    <label>
                        State
                        <span *ngIf="accountBookingRequireAddressFields">*</span>
                    </label>
                    <mat-form-field>
                        <input matInput type="text" qa="state" name="state" autocomplete="on" formControlName="state" maxlength="30" />
                    </mat-form-field>
                    <mat-error *ngIf="formGroup.controls.state.touched && formGroup.controls.state.invalid" class="error-message">
                        Please enter a valid State
                    </mat-error>
                </div>
                <div class="form-field">
                    <label>
                        Zip Code
                        <span *ngIf="accountBookingRequireAddressFields">*</span>
                    </label>
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            qa="zipCode"
                            name="zipCode"
                            autocomplete="on"
                            formControlName="zipCode"
                            maxlength="20"
                        />
                    </mat-form-field>
                    <mat-error *ngIf="formGroup.controls.zipCode.touched && formGroup.controls.zipCode.invalid" class="error-message">
                        Please enter a valid Zip Code
                    </mat-error>
                </div>
            </div>
        </div>
    </form>
</div>
