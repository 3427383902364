import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Pet } from '@app/models/pet.model';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, ONLINE_ID, PET_LIST } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { PetService } from '@app/services/pet.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AddPetComponent } from '../add-pet/add-pet.component';

@Component({
    selector: 'app-pet-list',
    templateUrl: './pet-list.component.html',
    styleUrls: ['./pet-list.component.scss'],
})
export class PetListComponent implements OnInit, OnDestroy {
    pets: Pet[] = [];
    selectedPetName: string = '';
    public formGroup: FormGroup;
    private ngUnsubscribe: Subject<any> = new Subject();
    private navigationButtonSubscription!: Subscription;
    @Input() disabled: boolean = false;
    constructor(
        private localStorageService: LocalStorageService,
        private petService: PetService,
        private navigationService: NavigationService,
        private fb: FormBuilder,
        private bottomSheet: MatBottomSheet
    ) {
        this.formGroup = this.fb.group({
            petList: new FormControl('', Validators.required),
        });
    }

    ngOnInit(): void {
        this.fetchPetList();

        this.navigationButtonSubscription = this.navigationService
            .getNavigationButtonName()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((buttonName) => {
                if (buttonName === ButtonName.ReviewDetails) {
                    const selectedPet = this.pets?.find((pet) => pet.name === this.formGroup.value.petList);
                    this.localStorageService.setNested(APPOINTMENT_REQUEST, 'petInfo', selectedPet);
                }
            });

        this.formGroup.statusChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((status) => this.navigationService.setIsStepFormValid(status === 'VALID'));

        this.previouslySelectedPetInfo();
    }

    private previouslySelectedPetInfo(): void {
        const petList = (this.localStorageService.get(PET_LIST) as Pet[]) || [];
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;

        const appointmentPetInfo = appointmentRequest?.petInfo || null;

        if (appointmentPetInfo) {
            const isPetMatching = petList?.some(
                (pet) =>
                    pet.name === appointmentPetInfo.name &&
                    pet.petType?.name === appointmentPetInfo.petType?.name &&
                    pet.petBreed?.name === appointmentPetInfo.petBreed?.name &&
                    pet.weight === appointmentPetInfo.weight
            );

            if (isPetMatching) {
                this.formGroup.patchValue({ petList: appointmentPetInfo.name });
            } else {
                this.formGroup.patchValue({ petList: null });
            }
            this.navigationService.setIsStepFormValid(isPetMatching);
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }

    private fetchPetList(): void {
        const customerID = this.localStorageService.getString(CUSTOMER_ID);
        const onlineID = this.localStorageService.getString(ONLINE_ID);

        this.petService
            .getPetList(customerID, onlineID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((pets) => {
                this.pets = pets || [];
                const previouslySelectedPetName = this.formGroup.value.petList;

                this.selectedPetName = this.pets.some((pet) => pet.name === previouslySelectedPetName) ? previouslySelectedPetName : '';

                this.formGroup.patchValue({ petList: this.selectedPetName });
            });
    }

    addPet() {
        this.bottomSheet.open(AddPetComponent, { disableClose: true });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.complete();
        this.navigationButtonSubscription.unsubscribe();
    }
}
