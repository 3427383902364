import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SelectDateTimeService {
    public nextMonthClicked: Subject<Date> = new Subject();
    public previousMonthClicked: Subject<Date> = new Subject();
    public onCalendarDataChanged: Subject<void> = new Subject();
    public onAsyncCallChevronRightFinished: Subject<boolean> = new Subject();
    public onAsyncCallChevronLeftFinished: Subject<boolean> = new Subject();
    public onAvailabilityDataFetched: Subject<void> = new Subject();

    public nextClicked(activeDate: any) {
        this.nextMonthClicked.next(activeDate);
    }

    public previousClicked(activeDate: any) {
        this.previousMonthClicked.next(activeDate);
    }

    public calendarDataChanged() {
        this.onCalendarDataChanged.next();
    }

    public asyncCallFinishedChevronRight(isFinished: boolean) {
        this.onAsyncCallChevronRightFinished.next(isFinished);
    }

    public asyncCallFinishedChevronLeft(isFinished: boolean) {
        this.onAsyncCallChevronLeftFinished.next(isFinished);
    }

    public availabilityDataFetched() {
        this.onAvailabilityDataFetched.next();
    }

    public getEndOfMonth = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    public getNextMonth = (currentDate: Date) => {
        return currentDate.getMonth() === 11
            ? new Date(currentDate.getFullYear() + 1, 0, 1)
            : new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    };

    public getPreviousMonth = (currentDate: Date) => {
        return currentDate.getMonth() === 0
            ? new Date(currentDate.getFullYear() - 1, 11, 1)
            : new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    };

    getDateString(date: Date) {
        const pad = function (num: number) {
            return (num < 10 ? '0' : '') + num;
        };

        return (
            date.getFullYear() +
            '-' +
            pad(date.getMonth() + 1) +
            '-' +
            pad(date.getDate()) +
            'T' +
            pad(date.getHours()) +
            ':' +
            pad(date.getMinutes()) +
            ':' +
            pad(date.getSeconds()) +
            '.' +
            date.getMilliseconds()
        );
    }

    /**
     * Takes a date object and returns a new date object with time set to 00:00:00.
     *
     * @param {Date} date - The input date object to remove time from.
     * @returns {Date} - A new date object representing the original date with time set to 00:00:00.
     */
    removeTimeFromDate(date: Date): Date {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const formattedMonth = month.toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');

        const dateString = `${year}-${formattedMonth}-${formattedDay}T00:00:00.000`;
        return new Date(dateString);
    }

    /**
     * Takes a date object and returns a formatted date string with seconds removed.
     *
     * @param {Date} date - The input date object to remove seconds from.
     * @returns {string} - A formatted date string with the original date and time, but seconds set to 00.
     */
    removeSecondsFromDate(date: Date): string {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedMonth = month.toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        const dateString = `${year}-${formattedMonth}-${formattedDay}T${formattedHours}:${formattedMinutes}:00.000`;
        return dateString;
    }

    /**
     * Compare two date objects without time
     * @param date1 First date
     * @param date2 Second date
     * @returns 0 - Dates are equal; 1 - First date comes after than second date; -1 - First date comes before second day.
     */
    compareDateWithoutTime(date1: Date, date2: Date) {
        const year1 = date1.getFullYear();
        const month1 = date1.getMonth();
        const day1 = date1.getDate();
        const year2 = date2.getFullYear();
        const month2 = date2.getMonth();
        const day2 = date2.getDate();
        if (year1 === year2 && month1 === month2 && day1 === day2) {
            return 0;
        } else if (year1 > year2 || (year1 === year2 && month1 > month2) || (year1 === year2 && month1 === month2 && day1 > day2)) {
            return 1;
        } else {
            return -1;
        }
    }

    /**
     * Compare two date objects without time and day
     * @param date1 First date
     * @param date2 Second date
     * @returns 0 - Dates are equal; 1 - First date comes after than second date; -1 - First date comes before second day.
     */
    compareDateWithoutTimeAndDay(date1: Date, date2: Date) {
        const year1 = date1.getFullYear();
        const month1 = date1.getMonth();
        const year2 = date2.getFullYear();
        const month2 = date2.getMonth();
        if (year1 === year2 && month1 === month2) {
            return 0;
        } else if (year1 > year2 || (year1 === year2 && month1 > month2)) {
            return 1;
        } else {
            return -1;
        }
    }
}
