import { Injectable } from '@angular/core';
import { AvailabilitiesApiService } from './api/availabilities-api.service';

@Injectable({
    providedIn: 'root',
})
export class AvailabilitiesService {
    constructor(private availabilitiesApiService: AvailabilitiesApiService) {}

    public getAvailabilities(
        dateStart: string,
        dateEnd: string,
        showSpinner: boolean = true,
        showCalendarSpinner: boolean = false,
        employeeId: string | undefined = undefined
    ) {
        return this.availabilitiesApiService.getAvailabilities(dateStart, dateEnd, showSpinner, showCalendarSpinner, employeeId);
    }
}
