import { Availability } from '@app/models/availability.model';

/**
 * A model that holds the availability information for one month.
 * The model can hold availabilities for multiple employees.
 * @class
 */
export class MonthAvailability {
    public month!: number;
    public year!: number;
    public availabilities!: Availability[];

    constructor(availabilities: Availability[]) {
        if (availabilities.length) {
            this.month = new Date(availabilities[0].start).getMonth();
            this.year = new Date(availabilities[0].start).getFullYear();
            this.availabilities = availabilities;
        }
        if (!this.availabilities) {
            this.availabilities = [];
        }
    }

    /**
     * Updates the month availabilities.
     * During the update the function will check that only availabilities for one month are passed in.
     * Duplicates will be ignored and only differing availabilities will be appended.
     * @param availabilities - Availabilities that are to be added to the month.
     */
    updateMonthAvailability(availabilities: Availability[]) {
        if (this.checkOnlyOneMonthInAvailabilities(availabilities)) {
            throw new Error('Please only pass in availabilities for one month');
        }

        for (const availability of availabilities) {
            const item = availability;
            let found = false;
            for (const avl of this.availabilities) {
                if (JSON.stringify(avl) === JSON.stringify(item)) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.availabilities.push(item);
            }
        }
    }

    /**
     * A helper function that checks if the passed in availabilities are tied to one month only.
     * @param availabilities - Availabilities to check.
     * @returns True - if the availabilities are tied to more than one month;
     * False - if the availabilities are tied to only one month.
     */
    private checkOnlyOneMonthInAvailabilities(availabilities: Availability[]) {
        const months = new Set();
        for (const availability of availabilities) {
            const startMonth = new Date(availability.start).getMonth();
            months.add(startMonth);
        }
        return months.size > 1;
    }
}
