/**
 * A simple month representation.
 */
export class Month {
    year: number;
    month: number;

    constructor(year: number, month: number) {
        this.year = year;
        this.month = month;
    }
}
