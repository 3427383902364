import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

export enum DsbCookie {
    DC_Client_Id_Token = 'DC_Client_Id_Token',
    DC_Client_Access_Token = 'DC_Client_Access_Token',
    DC_Client_Refresh_Token = 'DC_Client_Refresh_Token',
    REFRESH_TOKEN_EXP = 'DC_Client_Refresh_Token_Exp',
}

@Injectable({
    providedIn: 'root',
})
export class DsbCookieService extends CookieService {
    public getDomain(): string {
        let domain = location.host;
        if (domain.indexOf('.') > -1) {
            domain = domain.substring(domain.indexOf('.'));
        } else if (domain.indexOf(':') > -1) {
            domain = domain.substring(0, domain.indexOf(':'));
        }
        return domain;
    }

    public clearAllCookies(): void {
        const domain = this.getDomain();
        this.deleteAll('/', domain);
    }
}
