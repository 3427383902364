<div class="add-pet-form-container">
    <button
        id="close-button"
        mat-icon-button
        aria-label="close bottom sheet"
        mat-dialog-close
        (click)="closeBottomSheet()"
        [disabled]="isSaving"
    >
        <mat-icon>close</mat-icon>
    </button>
    <h1><strong>Add a Pet</strong></h1>
    <app-pet-information (isValidFormChange)="isValidPetForm = $event" [showAddPetTitle]="true" [disabled]="false"></app-pet-information>
    <button id="review-details" mat-button class="primary fixed-bottom-button" (click)="onReviewDetails()" [disabled]="!isValidPetForm">
        <ng-container *ngIf="!isSaving"
            >Review Details<svg
                class="forward-button-arrow"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.456562 0.709778C0.0665625 1.09978 0.0665625 1.72978 0.456562 2.11978L4.33656
                 5.99978L0.456562 9.87978C0.0665625 10.2698 0.0665625 10.8998 0.456562 11.2898C0.846563
                  11.6798 1.47656 11.6798 1.86656 11.2898L6.45656 6.69978C6.84656 6.30978 6.84656 5.67978
                   6.45656 5.28978L1.86656 0.699778C1.48656 0.319778 0.846563 0.319778 0.456562 0.709778Z"
                    fill="white"
                />
            </svg>
        </ng-container>
        <div *ngIf="isSaving">
            <mat-spinner [diameter]="25"></mat-spinner>
        </div>
    </button>
</div>
