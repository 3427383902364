import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { EmployeeAppointmentSortOrderEnum } from '@app/models/EmployeeAppointmentSortOrderEnum';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { APPOINTMENT_REQUEST, LocalStorageService, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { Employee } from '@models/employee.model';
import { EmployeeService } from '@services/employee.service';
import { Subject, Subscription, from, takeUntil } from 'rxjs';

@Component({
    selector: 'app-select-employee',
    templateUrl: './select-employee.component.html',
    styleUrls: ['./select-employee.component.scss'],
})
export class SelectEmployeeComponent implements OnInit, OnDestroy {
    employeeList: Employee[] = [];
    public formGroup: FormGroup;
    public ngUnsubscribe: Subject<any> = new Subject();
    private _destroyed$: Subject<void>;
    private navigationButtonSubscription!: Subscription;
    private isFirstAvailableFFOn = false;
    private isServicePriceAndDurationFeatureActive: boolean = false;
    public minEmployeePrice: number = 0;
    public maxEmployeePrice: number = 0;
    public showDescription: boolean = false;
    private isBookMultiServiceFeatureActive: boolean = false;
    private sortEmployeeByWebOrder: boolean = false;

    shouldShowServicePrice = false;
    isBookMultiServiceEnabled = false;
    noEmployeeAvailable = false;

    constructor(
        private employeeService: EmployeeService,
        private fb: FormBuilder,
        private localStorageService: LocalStorageService,
        private featureFlagService: FeatureFlagService,
        private navigationService: NavigationService,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        this.isFirstAvailableFFOn = this.featureFlagService.getFeatureFlag(FeatureKey.FirstAvailable);
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.isServicePriceAndDurationFeatureActive = this.featureFlagService.getFeatureFlag(FeatureKey.ShowPriceAndDuration);
        this.shouldShowServicePrice = this.isServicePriceAndDurationFeatureActive && siteBookingSettings.showPricesWithEmployees;
        this.isBookMultiServiceEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.BookMultiService);
        this.sortEmployeeByWebOrder = siteBookingSettings.employeeAppointmentSortOrder === EmployeeAppointmentSortOrderEnum.WebsiteOrder;
        this._destroyed$ = new Subject();
        this.formGroup = this.fb.group({
            employee: new FormControl('', Validators.required),
        });
        this.isBookMultiServiceFeatureActive = this.featureFlagService.getFeatureFlag(FeatureKey.BookMultiService);
    }

    ngOnInit() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        let employeeIdsForSelectedServices: string[] = [];
        let selectedServicesIds: string[] = [];
        if (appointmentRequest?.service?.length) {
            employeeIdsForSelectedServices = this.employeeService.getEmployeesFromServiceList(appointmentRequest.service);
            selectedServicesIds = appointmentRequest.service.map((service) => service.id);
            this.showDescription = this.isBookMultiServiceFeatureActive && appointmentRequest.service.length > 1;
        }

        if (employeeIdsForSelectedServices?.length) {
            this.fetchEmployees(employeeIdsForSelectedServices, selectedServicesIds);
        } else {
            this.noEmployeeAvailable = true;
        }

        /* istanbul ignore next */
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (buttonName === ButtonName.SelectDateTime) {
                const employee = this.employeeList.filter((e) => e.id === this.formGroup.value.employee);
                if (employee?.length === 1 && employee[0].id === '0') {
                    employee[0].firstAvailable = true;
                }
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'employee', employee);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            }
        });
        this.previouslySelectedEmployee();
    }

    private fetchEmployees(employeeIdList: string[], serviceIdList: string[]) {
        from(this.employeeService.getEmployeeList(employeeIdList, serviceIdList))
            .pipe(takeUntil(this._destroyed$))
            .subscribe((employeeList) => {
                this.employeeList = this.sortEmployeeList(employeeList);
                if (this.isFirstAvailableFFOn) {
                    const [minPrice, maxPrice] = this.getMinAndMaxEmployeePrice(this.employeeList);
                    this.minEmployeePrice = minPrice;
                    this.maxEmployeePrice = maxPrice;
                    const employeeServicePrice = this.employeeService.getEmployeeServicePriceListForFirstAvailability(serviceIdList);
                    if (this.employeeList.length >= 2) {
                        this.employeeList.unshift({
                            id: '0',
                            name: 'First Available',
                            employeeServicePriceList: employeeServicePrice,
                        });
                    }
                }

                this.formGroup.updateValueAndValidity();
            });
    }

    private sortEmployeeList(employeeList: Employee[]) {
        if (this.sortEmployeeByWebOrder) {
            const hasWebOrderEmployees = employeeList
                ?.filter((e) => e.webOrder !== undefined && e.webOrder !== null)
                .sort((a, b) => (a.webOrder || 0) - (b.webOrder || 0));
            const noWebOrderEmployees = employeeList
                ?.filter((e) => e.webOrder === undefined || e.webOrder === null)
                .sort((a, b) => a.name.localeCompare(b.name, this.localeId));
            return [...hasWebOrderEmployees, ...noWebOrderEmployees];
        }
        return employeeList?.sort((a, b) => a.name.localeCompare(b.name, this.localeId));
    }

    getEmployeePrice(employee: Employee) {
        const employeeServicePrices = employee.employeeServicePriceList.map((e) => e.price);
        const sum = employeeServicePrices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return sum;
    }

    getMinAndMaxEmployeePrice(employeeList: Employee[]) {
        const pricesPerEmployees = [];
        for (const employee of employeeList) {
            const employeeServicePrice = this.getEmployeePrice(employee);
            pricesPerEmployees.push(employeeServicePrice);
        }
        const minPrice = Math.min(...pricesPerEmployees);
        const maxPrice = Math.max(...pricesPerEmployees);
        return [minPrice, maxPrice];
    }

    private previouslySelectedEmployee() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (appointmentRequest?.employee?.length > 0) {
            if (appointmentRequest.employee[0].firstAvailable) {
                this.formGroup.patchValue({ employee: '0' });
            } else {
                this.formGroup.patchValue({ employee: appointmentRequest.employee[0].id });
            }
            this.navigationService.setIsStepFormValid(true);
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }
    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
