import { Injectable } from '@angular/core';
import { Availability } from '@app/models/availability.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { environment } from '@environments/environment';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { from, map, Observable } from 'rxjs';
import { AppSyncService } from '../app-sync.service';
import { AppointmentRequestService } from '../appointment-request.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { ServiceService } from '../service.service';
import { AppointmentRequest } from './../../models/appointmentRequest.model';
import { NavigationService } from './../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class AvailabilitiesApiService {
    constructor(
        private appSyncService: AppSyncService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private serviceService: ServiceService,
        private appointmentRequestService: AppointmentRequestService
    ) {}

    getAvailabilities(
        dateStart: string,
        dateEnd: string,
        showSpinner: boolean,
        showCalendarSpinner: boolean,
        employeeId: string | undefined
    ): Observable<Availability[]> {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getAvailabilitiesQuery = gql`
            query getAvailabilitiesList(
                $daySmartAccountID: ID!
                $employeeId: ID!
                $resourceIDList: [ID]
                $duration: Int!
                $dateStart: String!
                $dateEnd: String
                $accountForBookingInAdvance: Boolean
                $accountForMaxAdvanceBooking: Boolean
            ) {
                getAvailabilitiesList(
                    input: {
                        daySmartAccountID: $daySmartAccountID
                        employeeID: $employeeId
                        resourceIDList: $resourceIDList
                        duration: $duration
                        dateStart: $dateStart
                        dateEnd: $dateEnd
                        accountForBookingInAdvance: $accountForBookingInAdvance
                        accountForMaxAdvanceBooking: $accountForMaxAdvanceBooking
                    }
                ) {
                    availabilitiesList {
                        employeeID
                        end
                        resourceIDList
                        start
                    }
                }
            }
        `;

        const daySmartAccountID = this.localStorageService.getString(CUSTOMER_ID);
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;

        if (!employeeId && appointmentRequest.employee[0].firstAvailable) {
            employeeId = '0';
        } else if (!employeeId) {
            employeeId = appointmentRequest.employee[0].id;
        }
        const duration = this.appointmentRequestService.getAppointmentRequestDuration(appointmentRequest);
        const accountForBookingInAdvance = true;
        const accountForMaxAdvanceBooking = true;
        const resourceIDList = this.serviceService.getResourceIDsFromServiceList(appointmentRequest.service);
        if (showSpinner) {
            this.navigationService.setIsLoading(true);
        }

        if (showCalendarSpinner) {
            this.navigationService.setIsCalendarLoading(true);
        }

        return from(
            client.query({
                query: getAvailabilitiesQuery,
                variables: {
                    daySmartAccountID,
                    employeeId,
                    resourceIDList,
                    duration,
                    dateStart,
                    dateEnd,
                    accountForBookingInAdvance,
                    accountForMaxAdvanceBooking,
                },
                fetchPolicy: 'no-cache',
            })
        ).pipe(
            map((queryResult) => {
                const modelList = Availability.deserializeAppSyncResponse(queryResult as ApolloQueryResult<AppSyncResponse>);

                if (showSpinner) {
                    this.navigationService.setIsLoading(false);
                }

                return modelList;
            })
        );
    }
}
