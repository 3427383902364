import { Inject, Injectable } from '@angular/core';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { LOCAL_STORAGE, StorageTranscoders, WebStorageService } from 'ngx-webstorage-service';
import { BehaviorSubject, Observable } from 'rxjs';

export const CUSTOMER_ID = 'CID';
export const SITE_BOOKING_SETTINGS = 'siteSettings';
export const SITE = 'site';
export const PET_LIST = 'petList';
export const ONLINE_ID = 'onlineID';
export const APPOINTMENT_REQUEST = 'appointmentRequest';
@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {}

    private siteBookingSettingsSubject = new BehaviorSubject<SiteBookingSettings | null>(null);
    localStorageSiteBookingSettings$: Observable<SiteBookingSettings | null> = this.siteBookingSettingsSubject.asObservable();

    public get(key: string): object {
        return this.storage.get(key);
    }
    public getString(key: string): string {
        const value = this.storage.get(key, StorageTranscoders.STRING);
        return this.removeDuplicateQuotes(value);
    }

    public clear(keys?: string[]): void {
        if (keys) {
            keys.forEach((val) => this.storage.remove(val));
        } else {
            this.storage.clear();
        }
    }

    public set(key: string, value: any): void {
        this.storage.set(key, value);
        if (key === SITE_BOOKING_SETTINGS) {
            this.siteBookingSettingsSubject.next(value as SiteBookingSettings);
        }
    }

    public setString(key: string, value: string): void {
        this.storage.set(key, value, StorageTranscoders.STRING);
    }

    public setNested(key: string, nestedKey: string, value: any): void {
        if (this.has(key)) {
            const fullValue = this.get(key) as any;
            fullValue[nestedKey] = value;

            this.set(key, fullValue);
        } else {
            const fullValue = {} as any;
            fullValue[nestedKey] = value;

            this.set(key, fullValue);
        }
    }

    public has(key: string): boolean {
        return this.storage.has(key);
    }

    public remove(key: string): void {
        this.storage.remove(key);
        if (key === SITE_BOOKING_SETTINGS) {
            this.siteBookingSettingsSubject.next(null);
        }
    }

    removeDuplicateQuotes(value: string) {
        if (value?.startsWith('"')) {
            value = JSON.parse(value);
        }
        return value;
    }
}
