import { Injectable } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ButtonName, DsbStep } from '@app/components/content/content.component';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    readonly logout = 'logout';
    buttonName: string = ButtonName.SelectEmployee;
    selectedStepIndex: number = 0;
    stepper!: MatStepper;
    isStepCompleted = 0;
    isAccountCreatedFromClientScreen = false;
    steps: DsbStep[] | undefined;
    private dataSubject = new Subject<any>();
    public dataUpdated$ = this.dataSubject.asObservable();
    sendData = (data: any) => this.dataSubject.next(data);
}
