import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserAccountApiService {
    private userGroup = 'UserAccounts';
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.clientAuthServiceApiKey,
    });

    constructor(private readonly httpClient: HttpClient) {}

    public createUserAccount(email: string, password: string, customerId: string): Observable<any> {
        const url = `${environment.clientAuthServiceApiUrl}/signUp`;
        const body = {
            email: email,
            password: password,
            customerId: +customerId,
            userGroup: this.userGroup,
        };
        return this.httpClient.post(url, body, { headers: this.headers });
    }
}
