import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '@app/models/client.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PosApiService {
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        secret_key: environment.posApiSecretKey,
    });
    constructor(private readonly httpClient: HttpClient) {}

    public getFromUserAccount(onlineId: string, customerId: string): Observable<any> {
        const url = `${environment.posApiUrl}/Client/GetFromUserAccount`;
        const body = {
            OnlineAccountGuid: onlineId,
            CustomerId: +customerId,
        };
        return this.httpClient.post(url, body, { headers: this.headers });
    }

    public associateUserAccount(customerId: string, onlineId: string, client: Client): Observable<any> {
        const url = `${environment.posApiUrl}/Client/AssociateUserAccount`;
        const OptInStatus = {
            OptedIn: 2,
            OptedOut: 3,
        } as const;
        const body = {
            CustomerId: +customerId,
            Client: {
                OnlineID: onlineId,
                FirstName: client.firstName,
                LastName: client.lastName,
                CellPhone: client.phoneNumber,
                Email: client.email,
                SendMarketingEmails: client.enableEmailMarketing,
                OptInStatus: client.enableTextMarketing ? OptInStatus.OptedIn : OptInStatus.OptedOut,
            },
        };
        return this.httpClient.post(url, body, { headers: this.headers });
    }
}
