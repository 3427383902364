import { Injectable } from '@angular/core';
import { SetupIntentResult, Stripe, StripeElements, StripeElementsOptionsMode, loadStripe } from '@stripe/stripe-js';
import { StripeService } from './stripe.service';

@Injectable({
    providedIn: 'root',
})
export class StripeElementsService {
    private stripeMain: Stripe | null = null;

    constructor(private stripeService: StripeService) {}

    private async initStripe(): Promise<Stripe | null> {
        if (!this.stripeMain) {
            const apiKey = await this.stripeService.getStripePublishableKey();
            const stripeAccountID = this.stripeService.getStripeAccountID();
            this.stripeMain = await loadStripe(apiKey, { stripeAccount: stripeAccountID });
        }

        return this.stripeMain;
    }

    public async createStripeElementsForSetup(currency: string): Promise<StripeElements | undefined> {
        if (!this.stripeMain) {
            this.stripeMain = await this.initStripe();
        }

        const lowerCaseCurrency = currency.toLowerCase();

        const options: StripeElementsOptionsMode = {
            mode: 'setup',
            currency: lowerCaseCurrency,
            paymentMethodTypes: ['card'],
        };

        const stripeElements = this.stripeMain?.elements(options);

        return stripeElements;
    }

    public async confirmSetupIntent(
        clientSecret: string,
        stripePaymentElements: StripeElements,
        returnUrl: string
    ): Promise<SetupIntentResult | undefined> {
        return await this.stripeMain?.confirmSetup({
            elements: stripePaymentElements,
            clientSecret,
            confirmParams: {
                // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
                return_url: returnUrl,
            },
            redirect: 'if_required',
        });
    }
}
