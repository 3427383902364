import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Site } from '@app/models/site.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { CUSTOMER_ID, LocalStorageService, SITE, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { UserAccountService } from '@app/services/user-account.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { LoginFormComponent } from '../user-accounts/login-form/login-form.component';

@Component({
    selector: 'app-title-bar',
    templateUrl: './title-bar.component.html',
    styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent implements OnInit, OnDestroy {
    businessName!: string;
    customerID: string | undefined;
    siteBookingSavedSubscription!: Subscription;
    userAccountFeatureEnabled = false;
    userLoggedIn: boolean = false;
    public ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private localStorageService: LocalStorageService,
        private featureFlagService: FeatureFlagService,
        private userAccountService: UserAccountService,
        private bottomSheet: MatBottomSheet
    ) {}

    ngOnInit() {
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.userAccountFeatureEnabled =
            this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts) && siteBookingSettings?.allowClientOnlineAccountAccess === true;
        this.userLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);

        this.siteBookingSavedSubscription = this.localStorageService.localStorageSiteBookingSettings$.subscribe((siteBookingSettings) => {
            this.businessName = siteBookingSettings?.businessName ?? 'Business Name';
        });
        this.userAccountService.userAccountUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userAccount) => {
            this.userLoggedIn = userAccount !== undefined;
        });
    }

    onClickLogIn() {
        this.bottomSheet.open(LoginFormComponent, { disableClose: true });
    }

    onClickAccount() {
        const site = this.localStorageService.get(SITE) as Site;
        let url = site?.bookingURL + '/User#overview';
        if (!url.startsWith('http')) {
            url = 'https://' + url;
        }
        window.location.href = url;
    }

    ngOnDestroy(): void {
        this.siteBookingSavedSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
    }
}
