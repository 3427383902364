import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ButtonName } from '@app/components/content/content.component';
import { CreateAccountFormComponent } from '@app/components/user-accounts/create-account-form/create-account-form.component';
import { LoginFormComponent } from '@app/components/user-accounts/login-form/login-form.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Client } from '@app/models/client.model';
import { Site } from '@app/models/site.model';
import { ContentService } from '@app/services/content.service';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { APPOINTMENT_REQUEST, LocalStorageService, SITE } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { UserAccountService } from '@app/services/user-account.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-enter-your-information',
    templateUrl: './enter-your-information.component.html',
    styleUrls: ['./enter-your-information.component.scss'],
})
export class EnterYourInformationComponent implements OnInit, OnDestroy {
    @Input() disabled: boolean = false;
    @Input() showLoginSection: boolean = true;
    @Input() showClientInfoPageTitle: boolean = true;
    @Input() canShowPasswordField: boolean = false;
    public ngUnsubscribe: Subject<any> = new Subject();
    public supportsPets: boolean = false;
    public formGroup: FormGroup;
    public keyDecimal: boolean = false;

    private navigationButtonSubscription!: Subscription;
    public userAccountFeatureEnabled: boolean = true;
    public userLoggedIn = false;
    private readonly emailRegEx = /^[^@]+@[A-Za-z]+(\.[A-Za-z]+)+$/;
    private minLength = 8;
    private specialCharacters = '~!@#$%^&*()_+={}[]|:;"\'<>,.?/';
    private readonly passwordRegEx = new RegExp('^(?=.{' + this.minLength + ',})(?!.* )(?=.*[' + this.specialCharacters + ']).*$');

    constructor(
        private fb: FormBuilder,
        private navigationService: NavigationService,
        private localStorageService: LocalStorageService,
        private featureFlagService: FeatureFlagService,
        private userAccountService: UserAccountService,
        private bottomSheet: MatBottomSheet,
        private contentService: ContentService
    ) {
        const site = this.localStorageService.get(SITE) as Site;
        this.supportsPets = site?.supportsPets;
        this.userLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        this.formGroup = this.fb.group({
            firstName: new FormControl('', [Validators.required, this.noWhiteSpaceValidator()]),
            lastName: new FormControl('', [Validators.required, this.noWhiteSpaceValidator()]),
            email: new FormControl('', [Validators.required, Validators.pattern(this.emailRegEx)]),
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[- 0-9()]+')]),
            password: new FormControl(''),
            enableEmailMarketing: new FormControl(true),
            enableTextMarketing: new FormControl(true),
        });

        this.contentService?.dataUpdated$?.pipe(take(1)).subscribe((data) => {
            if (data === this.contentService.logout) {
                const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
                appointmentRequest.client = new Client();
                this.localStorageService.set(APPOINTMENT_REQUEST, appointmentRequest);
            }
        });
    }

    ngOnInit() {
        this.userAccountFeatureEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts);
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (
                (buttonName === ButtonName.PetInformation && this.supportsPets) ||
                (buttonName === ButtonName.ReviewDetails && !this.supportsPets)
            ) {
                const client = new Client(this.formGroup.value);
                client.firstName = client.firstName.trim();
                client.lastName = client.lastName.trim();
                client.phoneNumber = client.phoneNumber.trim();
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'client', client);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            } else if (status === 'INVALID') {
                this.navigationService.setIsStepFormValid(false);
            }
        });
        this.previouslyAddedClientInfo();

        if (this.canShowPasswordField) {
            const passwordControl = this.formGroup.get('password');
            passwordControl?.setValidators([Validators.required, Validators.pattern(this.passwordRegEx)]);
            passwordControl?.updateValueAndValidity();
        }
    }

    private previouslyAddedClientInfo() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (appointmentRequest?.client?.firstName) {
            this.formGroup.setValue({
                firstName: appointmentRequest.client.firstName,
                lastName: appointmentRequest.client.lastName,
                email: appointmentRequest.client.email,
                phoneNumber: appointmentRequest.client.phoneNumber,
                password: '',
                enableEmailMarketing: appointmentRequest.client.enableEmailMarketing ?? false,
                enableTextMarketing: appointmentRequest.client.enableTextMarketing ?? false,
            });
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }

    onClickLogIn() {
        this.bottomSheet.open(LoginFormComponent, { disableClose: true });
    }

    onClickCreateAccount() {
        this.bottomSheet.open(CreateAccountFormComponent, { disableClose: true });
    }

    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
    }

    public isValidFields(): boolean {
        this.formGroup.controls.firstName.setValue(this.formGroup.controls.firstName.value.trim());
        this.formGroup.controls.lastName.setValue(this.formGroup.controls.lastName.value.trim());
        this.formGroup.controls.phoneNumber.setValue(this.formGroup.controls.phoneNumber.value.trim());
        const invalidFields = Object.keys(this.formGroup.controls).filter((controlName) => this.formGroup.get(controlName)?.invalid);
        return invalidFields.length > 0 ? false : true;
    }

    private noWhiteSpaceValidator(): ValidatorFn {
        return (control: FormControl | AbstractControl): { [key: string]: any } | null => {
            if (control.value && control.value.trim().length === 0) {
                return { whitespace: true };
            }
            return null;
        };
    }
}
