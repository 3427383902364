<!-- eslint-disable max-len -->
<form [formGroup]="formGroup">
    <div *ngIf="!disabled" class="step-container">
        <div class="header-container">
            <h1 qa="client-pet-information-title" class="step-header-main">
                Client & Pet Information <span class="mini-step-header"> 2 of 2</span>
            </h1>
            <h2 class="step-header-secondary">SELECT A PET</h2>
        </div>
        <div class="tp-radio-container">
            <mat-radio-group class="tp-radio-group" formControlName="petList">
                <div class="service-info-container" *ngFor="let pet of pets">
                    <mat-radio-button class="tp-radio-button" [value]="pet.name">
                        <div class="pet-item">
                            <div class="pet-info">
                                <div class="pet-name">{{ pet.name }}</div>
                                <div class="pet-details">
                                    <span class="pet-type" *ngIf="pet.petType && pet.petType.name">{{ pet.petType.name }}</span>
                                    <span
                                        class="separator"
                                        *ngIf="
                                            pet.petType &&
                                            pet.petType.name &&
                                            pet.petType.petBreedList &&
                                            pet.petType.petBreedList.length > 0
                                        "
                                        >|</span
                                    >
                                    <span
                                        class="pet-breed"
                                        *ngIf="pet.petType && pet.petType.petBreedList && pet.petType.petBreedList.length > 0"
                                        >{{ pet.petType.petBreedList[0].name }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
        <button id="add-a-pet-link" class="add-pet" (click)="addPet()">+ Add A Pet</button>
    </div>
</form>
