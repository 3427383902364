import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Client } from '@app/models/client.model';
import { Service } from '@app/models/service.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { LocaleService } from '@app/services/locale.service';
import { UserAccountService } from '@app/services/user-account.service';
import { getBookingDate, getBookingTime } from '@app/shared/helpers/extensions';
import { AnimationOptions } from 'ngx-lottie';
@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
    @HostBinding('class') classes = 'confirmation-component-container';
    ticketID: number;
    appointmentRequest!: AppointmentRequest;
    formattedDate: string = '';
    formattedTime: string = '';
    employeeName: string = '';
    serviceName: string = '';
    clientName: string = '';
    changeRequestText = 'To change your request call:';
    phoneNumberExists = false;
    serviceDuration = 0;
    servicePrice = 0;
    isServicePriceAndDurationFeatureActive = false;
    currencyCode: string = '';
    siteSettings!: SiteBookingSettings;
    customerID!: string;
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
        private localeService: LocaleService,
        private featureFlagService: FeatureFlagService,
        private userAccountService: UserAccountService
    ) {
        const navigation = this.router.getCurrentNavigation();
        this.isServicePriceAndDurationFeatureActive = this.featureFlagService.getFeatureFlag(FeatureKey.ShowPriceAndDuration);

        if (navigation) {
            this.ticketID = navigation.extras.state?.ticketID;
        } else {
            throw new Error('Navigation is null');
        }
    }
    public animationOptions: AnimationOptions = {
        path: '../../../assets/appointment-submitted.json',
        loop: false,
    };
    async ngOnInit() {
        this.getDataFromLocalStorage();
        this.employeeName = this.appointmentRequest.employee[0].name;
        this.clientName = this.appointmentRequest.client.firstName + ' ' + this.appointmentRequest.client.lastName;
        await this.localeService.setLocale(this.siteSettings.customerCultureCode);
        this.currencyCode = this.localeService.getCurrencyCode() || 'USD';
        this.localStorageService.remove(APPOINTMENT_REQUEST);
        if (this.siteSettings.businessPhoneNumber) {
            this.phoneNumberExists = true;
        } else {
            this.changeRequestText = this.changeRequestText.replace(':', ' the business.');
        }
    }

    get getBookingDate() {
        return getBookingDate(this.appointmentRequest.datetime);
    }

    get getBookingTime() {
        return getBookingTime(this.appointmentRequest.datetime);
    }

    requestNewAppointment() {
        let client: Client | undefined = undefined;
        if (this.userAccountService.getCurrentUser() !== undefined) {
            client = this.appointmentRequest?.client;
        }
        if (client) {
            this.localStorageService.setNested(APPOINTMENT_REQUEST, 'client', client);
        }

        void this.router.navigate(['/booking/service'], {
            queryParams: {
                DSID: this.customerID,
            },
        });
    }
    getServicePrice(service: Service) {
        const employeeServicePrice = this.appointmentRequest.employee[0].employeeServicePriceList.find(
            (ep) => ep && ep.serviceID === service.id
        );
        return employeeServicePrice ? employeeServicePrice.price : 0;
    }
    getServiceDuration(service: Service) {
        const durationException = this.appointmentRequest.employee[0].durationExceptionList?.find((de) => de.serviceID === service.id);
        return durationException ? durationException.duration : service.duration;
    }

    private getDataFromLocalStorage() {
        this.appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        this.siteSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }
}
