import { Injectable } from '@angular/core';
import { EmployeeApiService } from '@api-services/employee-api.service';
import { Service } from '@app/models/service.model';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    constructor(private employeeApiService: EmployeeApiService) {}

    public getEmployeeList(employeeIDList?: string[], serviceIDList?: string[]) {
        return this.employeeApiService.getEmployeeList(employeeIDList, serviceIDList);
    }

    public getEmployeesFromServiceList(serviceList: Service[]) {
        if (!serviceList.length) {
            return [];
        }
        let commonEmployeeIds: Set<string> = new Set(serviceList[0].employeeIDList);

        for (let i = 1; i < serviceList.length; i++) {
            commonEmployeeIds = new Set(serviceList[i].employeeIDList?.filter((id) => commonEmployeeIds.has(id)));
        }
        return Array.from(commonEmployeeIds);
    }

    public getEmployeeServicePriceListForFirstAvailability(serviceIdList: string[]) {
        const employeeServicePrice = [];
        for (const serviceId of serviceIdList) {
            employeeServicePrice.push({
                serviceID: serviceId,
                price: 0,
            });
        }
        return employeeServicePrice;
    }
}
