import { Injectable } from '@angular/core';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Employee } from '@app/models/employee.model';
import { Service } from '@app/models/service.model';
import { AppointmentRequestModel } from '@app/models/syncAppointmentRequest.model';
import { Observable } from 'rxjs';
import { AppointmentRequestApiService } from './api/appointment-request-api.service';

@Injectable({
    providedIn: 'root',
})
export class AppointmentRequestService {
    constructor(private appointmentRequestApiService: AppointmentRequestApiService) {}

    public async createAppointmentRequest(
        appointmentRequestService: AppointmentRequestService,
        token: string
    ): Promise<AppointmentRequestModel> {
        return (await this.appointmentRequestApiService.createAppointmentRequest(
            appointmentRequestService,
            token
        )) as unknown as AppointmentRequestModel;
    }

    public getAppointmentRequest(requestID: string): Observable<number | undefined> {
        return this.appointmentRequestApiService.getAppointmentRequest(requestID);
    }

    public getAppointmentRequestDuration(appointmentRequest: AppointmentRequest) {
        let duration = 0;
        for (const service of appointmentRequest.service) {
            const employeeDurationException = appointmentRequest.employee[0].durationExceptionList?.find(
                (duration) => duration.serviceID === service.id
            );
            duration += employeeDurationException ? employeeDurationException.duration : service.duration;
        }
        return duration;
    }

    public getItemDuration(service: Service, employee: Employee) {
        const employeeDurationException = employee.durationExceptionList?.find((duration) => duration.serviceID === service.id);
        return employeeDurationException ? employeeDurationException.duration : service.duration;
    }

    public getServiceIDsFromAppointmentRequest(appointmentRequest: AppointmentRequest) {
        const serviceIDs = new Set(appointmentRequest.service.flatMap((service) => service.id));
        return Array.from(serviceIDs);
    }
}
