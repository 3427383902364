import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';
import { CreditCardProcessingMethods } from './creditCardProcessingMethodEnum';
import { EmployeeAppointmentSortOrderEnum } from './EmployeeAppointmentSortOrderEnum';

export class SiteBookingSettings {
    public businessName: string;
    public businessPhoneNumber: string;
    public businessAddress: string;
    public themeColor: string;
    public showPricesWithServices: boolean;
    public showPricesWithEmployees: boolean;
    public appointmentMaxAdvanceBooking: number;
    public requireCCWhenBooking?: boolean;
    public apptCancellationPolicy?: string;
    public creditCardProcessingEnabled?: boolean;
    public creditCardProcessingMethod?: CreditCardProcessingMethods;
    public stripeAccountID?: string;
    public customerCultureCode?: string;
    public allowClientOnlineAccountAccess?: boolean;
    public employeeAppointmentSortOrder?: EmployeeAppointmentSortOrderEnum;
    public serviceCategoryOrder?: string;
    public stripeSurchargeEnabled?: boolean;

    constructor(settings: SiteBookingSettings) {
        this.businessAddress = settings.businessAddress;
        this.businessName = settings.businessName;
        this.businessPhoneNumber = settings.businessPhoneNumber;
        this.themeColor = settings.themeColor;
        this.showPricesWithServices = settings.showPricesWithServices;
        this.showPricesWithEmployees = settings.showPricesWithEmployees;
        this.appointmentMaxAdvanceBooking = settings.appointmentMaxAdvanceBooking;
        this.requireCCWhenBooking = settings.requireCCWhenBooking;
        this.apptCancellationPolicy = settings.apptCancellationPolicy;
        this.creditCardProcessingEnabled = settings.creditCardProcessingEnabled;
        this.creditCardProcessingMethod = settings.creditCardProcessingMethod;
        this.stripeAccountID = settings.stripeAccountID;
        this.customerCultureCode = settings.customerCultureCode;
        this.allowClientOnlineAccountAccess = settings.allowClientOnlineAccountAccess;
        this.employeeAppointmentSortOrder = settings.employeeAppointmentSortOrder;
        this.serviceCategoryOrder = settings.serviceCategoryOrder;
        this.stripeSurchargeEnabled = settings.stripeSurchargeEnabled;
    }

    public static deserializeAppSyncResponse(appSyncResponse: ApolloQueryResult<AppSyncResponse>): SiteBookingSettings {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: SiteBookingSettings) => obj, [
            'getSiteBookingSettings',
            'siteBookingSettings',
        ]);
    }
}
