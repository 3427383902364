<!-- eslint-disable max-len -->
<div class="step-container">
    <div class="header-container">
        <h1 qa="select-date-time-title" class="step-header-main">Select Time</h1>
    </div>

    <div class="calendar-container" [ngClass]="{ 'justify-center': isMatchingResolution() }">
        <div
            class="icon"
            (click)="scrollWeekOnClick(HorizontalScrollDirection.Left)"
            [ngClass]="{
                'disable-chevron': previousWeekEnabled() || !isScheduleForEmployeeFound,
                'no-pointer': shouldDisableLeftChevron || !isScheduleForEmployeeFound
            }"
            *ngIf="!isMatchingResolution()"
        >
            <mat-icon *ngIf="!shouldDisableLeftChevron; else chevron" class="material-icons-round">keyboard_arrow_left</mat-icon>
            <ng-template #chevron>
                <mat-progress-spinner style="margin: auto" diameter="20" color="primary" mode="indeterminate"> </mat-progress-spinner>
            </ng-template>
        </div>

        <mat-form-field>
            <div
                class="calendar-datepicker"
                [ngClass]="{
                    'no-pointer': shouldDisableLeftChevron || shouldDisableRightChevron || !isScheduleForEmployeeFound
                }"
            >
                <input
                    [ngStyle]="{ 'width.ch': inputText.value.length, 'min-width.ch': 10 }"
                    #inputText
                    matInput
                    readonly
                    [matDatepicker]="picker"
                    [matDatepickerFilter]="filterDates"
                    (dateChange)="onDateChange($event)"
                    [min]="minDate"
                    [max]="maxDate"
                    [value]="selected ? selected : ''"
                />
                <mat-datepicker-toggle qa="date-picker-button" disabled="false" matIconSuffix [for]="picker"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #picker [calendarHeaderComponent]="customCalendarHeaderComponent"></mat-datepicker>
        </mat-form-field>

        <div
            class="icon"
            (click)="scrollWeekOnClick(HorizontalScrollDirection.Right)"
            *ngIf="!isMatchingResolution()"
            [ngClass]="{
                'disable-chevron': shouldDisableRightChevronMaxAdvanceBook || !isScheduleForEmployeeFound,
                'no-pointer': shouldDisableRightChevron || shouldDisableRightChevronMaxAdvanceBook || !isScheduleForEmployeeFound
            }"
        >
            <mat-icon qa="calendar-arrow-right" *ngIf="!shouldDisableRightChevron; else chevron" class="material-icons-round"
                >keyboard_arrow_right</mat-icon
            >
            <ng-template #chevron>
                <mat-progress-spinner style="margin: auto" diameter="20" color="primary" mode="indeterminate"> </mat-progress-spinner>
            </ng-template>
        </div>
    </div>

    <div qa="horizontal-scroll-datepicker" class="horizontal-scroll-container">
        <div
            class="icon"
            (click)="scrollWeekOnClick(HorizontalScrollDirection.Left)"
            [ngClass]="{
                'disable-chevron': previousWeekEnabled() || !isScheduleForEmployeeFound,
                'no-pointer': shouldDisableLeftChevron || !isScheduleForEmployeeFound
            }"
            *ngIf="isMatchingResolution()"
        >
            <mat-icon *ngIf="!shouldDisableLeftChevron; else chevron" class="material-icons-round margin-left"
                >keyboard_arrow_left</mat-icon
            >
            <ng-template #chevron>
                <mat-progress-spinner class="no-pointer" style="margin: auto" diameter="20" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </ng-template>
        </div>
        <mat-button-toggle-group #toggleGroup class="horizontal-scroll-box" [value]="selected">
            <mat-button-toggle
                class="toggle-button"
                (change)="onDaySlotChange($event)"
                *ngFor="let date of dateList"
                value="{{ date.date }}"
                qa="select-day-slot"
                id="day-slot-{{ date.dayInMonth }}-{{ date.date.getMonth() }}"
                [ngClass]="{ 'day-slot-disabled': !date.hasAvailabilities }"
            >
                <span class="week-day">{{ date.dayInWeek }}</span
                ><span class="week-date">{{ date.dayInMonth }}</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div
            class="icon"
            (click)="scrollWeekOnClick(HorizontalScrollDirection.Right)"
            *ngIf="isMatchingResolution()"
            [ngClass]="{
                'disable-chevron': shouldDisableRightChevronMaxAdvanceBook || !isScheduleForEmployeeFound,
                'no-pointer': shouldDisableRightChevron || shouldDisableRightChevronMaxAdvanceBook || !isScheduleForEmployeeFound
            }"
        >
            <mat-icon qa="calendar-arrow-right" *ngIf="!shouldDisableRightChevron; else chevron" class="material-icons-round margin-right"
                >keyboard_arrow_right</mat-icon
            >
            <ng-template #chevron>
                <mat-progress-spinner class="no-pointer" style="margin: auto" diameter="20" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </ng-template>
        </div>
    </div>
    <ng-container *ngIf="dateList.length > 0">
        <div class="time-container" *ngIf="isWeekAvailable()">
            <h2 class="time-header" *ngIf="morningTimeSlots.length">Morning</h2>
            <div class="time-slot-box">
                <ng-container *ngFor="let timeSlot of morningTimeSlots; let i = index">
                    <a qa="time" class="time-slot" id="ts-morning-{{ i }}" (click)="onTimeSlotChange('ts-morning-', i)">{{ timeSlot }}</a>
                </ng-container>
            </div>
        </div>

        <div class="time-container" *ngIf="isWeekAvailable()">
            <h2 class="time-header margin-top-20" *ngIf="afternoonTimeSlots.length">Afternoon</h2>
            <div class="time-slot-box">
                <ng-container *ngFor="let timeSlot of afternoonTimeSlots; let i = index">
                    <a qa="time" class="time-slot" id="ts-afternoon-{{ i }}" (click)="onTimeSlotChange('ts-afternoon-', i)">{{
                        timeSlot
                    }}</a>
                </ng-container>
            </div>
        </div>
        <div class="time-container no-appointments" *ngIf="!isWeekAvailable()">
            <p *ngIf="isScheduleForEmployeeFound; else noScheduleForEmployee">
                No appointments available,<br />
                please pick another range.
            </p>
            <ng-template #noScheduleForEmployee>
                <p>No appointments available.</p>
            </ng-template>
        </div>
    </ng-container>
</div>
