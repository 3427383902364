import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ScreenToShow {
    ScreenOne = 'ScreenOne',
    ScreenTwo = 'ScreenTwo',
    ScreenThree = 'ScreenThree',
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private buttonName = new Subject<string>();
    public showedScreen!: ScreenToShow;
    public isStepValid = new Subject<boolean>();
    public isLoading = new Subject<boolean>();
    public isCalendarLoading = new Subject<boolean>();
    public showSpinnerOnButton = new Subject<boolean>();

    setNavigationButtonName(buttonName: string) {
        this.buttonName.next(buttonName);
    }
    getNavigationButtonName() {
        return this.buttonName.asObservable();
    }

    setIsStepFormValid(isValid: boolean) {
        this.isStepValid.next(isValid);
    }

    getIsStepFormValid() {
        return this.isStepValid.asObservable();
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading.next(isLoading);
    }

    getIsLoading() {
        return this.isLoading.asObservable();
    }

    setIsCalendarLoading(isLoading: boolean) {
        this.isCalendarLoading.next(isLoading);
    }

    getIsCalendarLoading() {
        return this.isCalendarLoading.asObservable();
    }
    shouldShowSpinnerOnButton(showSpinner: boolean) {
        this.showSpinnerOnButton.next(showSpinner);
    }

    getShowSpinnerOnButton() {
        return this.showSpinnerOnButton.asObservable();
    }
}
