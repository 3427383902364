<!-- eslint-disable max-len -->
<div class="step-container">
    <h1 qa="review-details-title" class="step-title">Review Details</h1>
    <h2 class="step-title-sub margin-bottom-13">Your Information</h2>
    <div class="step-information-container">
        <span class="step-information-name">{{ appointmentRequest.client.firstName }} {{ appointmentRequest.client.lastName }}</span>
        <span class="step-information-pet" id="pet-info" *ngIf="supportsPets && appointmentRequest.petInfo">{{
            appointmentRequest.petInfo.name
        }}</span>
        <span class="step-information-phone">{{ appointmentRequest.client.phoneNumber }}</span>
        <span class="step-information-email">{{ appointmentRequest.client.email }}</span>
    </div>
    <div *ngIf="featureEnabledAndUserLoggedIn">
        <a (click)="onClickNotYou()" id="not-you"><p class="user-account-links">Not you?</p></a>
    </div>
    <h2 class="step-title-sub margin-bottom-8">Appointment Details</h2>
    <div class="step-appt-details-container">
        <div class="step-appt-details-row">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.2666 3C11.6416 3 12.7666 4.125 12.7666 5.5C12.7666 6.875 11.6416 8 10.2666 8C8.8916
                    8 7.7666 6.875 7.7666 5.5C7.7666 4.125 8.8916 3 10.2666 3ZM10.2666 15.5C13.6416 15.5 17.5166 17.1125
                    17.7666 18H2.7666C3.0541 17.1 6.9041 15.5 10.2666 15.5ZM10.2666 0.5C7.5041 0.5 5.2666 2.7375 5.2666
                    5.5C5.2666 8.2625 7.5041 10.5 10.2666 10.5C13.0291 10.5 15.2666 8.2625 15.2666 5.5C15.2666 2.7375
                    13.0291 0.5 10.2666 0.5ZM10.2666 13C6.9291 13 0.266602 14.675 0.266602 18V20.5H20.2666V18C20.2666
                    14.675 13.6041 13 10.2666 13Z"
                    fill="#656565"
                />
            </svg>
            <span qa="step-appt-details-text-employee" class="step-appt-details-text">{{ appointmentRequest.employee[0].name }}</span>
        </div>
        <div class="step-appt-details-service-row">
            <div
                class="step-appt-details-row service-info-row"
                [ngClass]="{ 'multi-service': appointmentRequest.service.length > 1 }"
                *ngFor="let service of appointmentRequest.service; index as i"
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    [ngClass]="{ serviceIconHidden: i > 0 }"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.4414 2.56609L6.2213 0.560547H6.09666C3.45781 0.565521 1.17185 2.39173 0.584218 4.96432C-0.00341779
                    7.5369 1.26291 10.1745 3.63788 11.3248V18.4632H5.09955C5.0522 19.1773 4.6172 19.8081 3.96647 20.1061L4.52168
                    21.919C5.96963 21.3657 6.95557 20.0111 7.03711 18.4632H8.53277L9.66585 11.3928L20.4301 9.87444L20.4414
                    2.56609ZM2.31142 6.22611C2.31712 4.16473 3.96699 2.4847 6.02792 2.44163L14.7073 3.66535V8.77553L6.02792
                    9.99925C3.96881 9.96227 2.31726 8.28554 2.31142 6.22611ZM7.68203 11.8913L6.91154
                    16.5709H5.52918V11.8913H7.68203ZM18.5612 8.23121L16.601 8.50315V3.97084L18.5612
                    4.24278V8.23121Z"
                        fill="#656565"
                    />
                </svg>
                <div class="step-appt-details-text-box">
                    <span class="step-appt-details-text">{{ service.name }}</span>
                    <span class="step-appt-details-text step-appt-details-text-sub"
                        ><a *ngIf="shouldShowServicePrice"
                            >{{
                                getServicePrice(service)
                                    | currency: '' : 'symbol' : (getServicePrice(service) % 1 != 0 ? '1.2' : '1.0') : localeId
                            }}
                            &nbsp; &#124; &nbsp;</a
                        >
                        <a *ngIf="shouldShowServiceDuration"> {{ getServiceDuration(service) }} mins</a>
                    </span>
                </div>
            </div>
            <div
                *ngIf="servicesWithDepositRequired.length"
                qa="step-appt-details-text-deposit"
                class="step-appt-details-text step-appt-details-deposit"
            >
                <span
                    ><svg class="info-icon" width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.49219 0.777344C4.05469 0.777344 0.492188 4.37109 0.492188 8.77734C0.492188 13.2148 4.05469 16.7773
                                8.49219 16.7773C12.8984 16.7773 16.4922 13.2148 16.4922 8.77734C16.4922 4.37109 12.8984 0.777344 8.49219
                                0.777344ZM8.49219 4.77734C9.02344 4.77734 9.49219 5.24609 9.49219 5.77734C9.49219 6.33984 9.02344 6.77734
                                8.49219 6.77734C7.92969 6.77734 7.49219 6.33984 7.49219 5.77734C7.49219 5.24609 7.92969 4.77734 8.49219
                                4.77734ZM9.74219 12.7773H7.24219C6.80469 12.7773 6.49219 12.4648 6.49219 12.0273C6.49219 11.6211 6.80469
                                11.2773 7.24219 11.2773H7.74219V9.27734H7.49219C7.05469 9.27734 6.74219 8.96484 6.74219 8.52734C6.74219
                                8.12109 7.05469 7.77734 7.49219 7.77734H8.49219C8.89844 7.77734 9.24219 8.12109 9.24219
                                8.52734V11.2773H9.74219C10.1484 11.2773 10.4922 11.6211 10.4922 12.0273C10.4922 12.4648
                                10.1484 12.7773 9.74219 12.7773Z"
                            fill="#222222"
                            fill-opacity="0.8"
                        />
                    </svg>
                </span>
                <span>
                    <span *ngFor="let serviceWithDeposit of servicesWithDepositRequired">
                        This business requires a
                        <span class="bold">{{
                            serviceWithDeposit.depositPercentage
                                ? serviceWithDeposit.depositPercentage + '%'
                                : (serviceWithDeposit.depositFixedAmount
                                  | currency
                                      : ''
                                      : 'symbol'
                                      : (serviceWithDeposit.depositFixedAmount && serviceWithDeposit.depositFixedAmount % 1 != 0
                                            ? '1.2'
                                            : '1.0')
                                      : localeId)
                        }}</span>
                        deposit for
                        <span [class.bold]="servicesWithDepositRequired.length > 1">{{
                            servicesWithDepositRequired.length > 1 ? serviceWithDeposit.name : 'this service'
                        }}</span
                        >.
                    </span>
                    <span
                        *ngIf="isDepositRequired() && siteSettings.creditCardProcessingEnabled"
                        qa="enter-credit-card"
                        class="blue-underline-text"
                        (click)="scrollToCCInformationContainer()"
                        >Enter Credit Card</span
                    ></span
                >
            </div>
        </div>
        <div *ngIf="siteSettings && siteSettings.businessAddress" class="step-appt-details-row">
            <svg class="location-icon" width="24" height="24" viewBox="2 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.8848 12.3398C11.7848 12.3398 10.8848 11.4398 10.8848 10.3398C10.8848 9.23984 11.7848
                    8.33984 12.8848 8.33984C13.9848 8.33984 14.8848 9.23984 14.8848 10.3398C14.8848 11.4398 13.9848 12.3398 12.8848
                    12.3398ZM18.8848 10.5398C18.8848 6.90984 16.2348 4.33984 12.8848 4.33984C9.53477 4.33984 6.88477 6.90984 6.88477
                    10.5398C6.88477 12.8798 8.83477 15.9798 12.8848 19.6798C16.9348 15.9798 18.8848 12.8798 18.8848 10.5398ZM12.8848
                    2.33984C17.0848 2.33984 20.8848 5.55984 20.8848 10.5398C20.8848 13.8598 18.2148 17.7898 12.8848
                    22.3398C7.55477 17.7898 4.88477 13.8598 4.88477 10.5398C4.88477 5.55984 8.68477 2.33984 12.8848 2.33984Z"
                    fill="#656565"
                />
            </svg>
            <div class="step-appt-details-text-box">
                <span class="step-appt-details-text">{{ siteSettings.businessName }}</span>
                <span class="step-appt-details-text step-appt-details-text-sub">{{ siteSettings.businessAddress }}</span>
            </div>
        </div>
        <div class="step-appt-details-row">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.2617 2H15.2617V0H13.2617V2H5.26172V0H3.26172V2H2.26172C1.15172 2 0.271719 2.9 0.271719 4L0.261719 18C0.261719
                    19.1 1.15172 20 2.26172 20H16.2617C17.3617 20 18.2617 19.1 18.2617 18V4C18.2617 2.9 17.3617 2 16.2617 2ZM16.2617
                    18H2.26172V8H16.2617V18ZM16.2617 6H2.26172V4H16.2617V6ZM9.26172 11H14.2617V16H9.26172V11Z"
                    fill="#656565"
                />
            </svg>
            <span class="step-appt-details-text">{{ getBookingDate }}</span>
        </div>
        <div class="step-appt-details-row">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.2517 0C4.73172 0 0.261719 4.48 0.261719 10C0.261719 15.52 4.73172 20 10.2517 20C15.7817 20 20.2617 15.52 20.2617
                    10C20.2617 4.48 15.7817 0 10.2517 0ZM10.2617 18C5.84172 18 2.26172 14.42 2.26172 10C2.26172 5.58 5.84172 2 10.2617
                    2C14.6817 2 18.2617 5.58 18.2617 10C18.2617 14.42 14.6817 18 10.2617 18ZM10.0417 5H9.98172C9.58172 5 9.26172 5.32
                    9.26172 5.72V10.44C9.26172 10.79 9.44172 11.12 9.75172 11.3L13.9017 13.79C14.2417 13.99 14.6817 13.89 14.8817
                    13.55C15.0917 13.21 14.9817 12.76 14.6317 12.56L10.7617 10.26V5.72C10.7617 5.32 10.4417 5 10.0417 5Z"
                    fill="#656565"
                />
            </svg>
            <span class="step-appt-details-text">{{ getBookingTime }}</span>
        </div>
    </div>
    <div *ngIf="showCommentsField" class="step-appt-comments-row">
        <label>Special requests or comments (optional)</label>
        <textarea matInput [(ngModel)]="comments" (blur)="saveComment()" rows="4" maxlength="80"></textarea>
    </div>
    <h2 *ngIf="showCCInformation" class="step-title-sub margin-16">Credit Card Information</h2>
    <div *ngIf="showCCInformation && isCardConnectEnabled" class="step-credit-card-information-container" #cCInformationContainer>
        <form [formGroup]="creditCardForm">
            <div class="form-field">
                <label> Card Number </label>
                <iframe qa="token-frame" title="Token Frame" id="tokenFrame" name="tokenFrame" [src]="url | safeResourceUrl"></iframe>
                <input type="hidden" name="cardToken" id="cardToken" formControlName="cardToken" />
                <ng-container *ngIf="cardToken?.touched && cardToken?.invalid">
                    <mat-error qa="validation-error-message" class="credit-card-form-error-message" *ngIf="cardToken?.errors?.required"
                        >Credit card number is required</mat-error
                    >
                    <mat-error
                        qa="validation-error-message"
                        class="credit-card-form-error-message"
                        *ngIf="cardToken?.errors?.invalidCreditCardNum"
                        >Invalid credit card number</mat-error
                    >
                </ng-container>
            </div>
            <div class="step-credit-card-information-row">
                <div class="form-field">
                    <label> Security Code </label>
                    <mat-form-field [floatLabel]="'auto'">
                        <input
                            matInput
                            qa="security-code"
                            placeholder="CVV"
                            name="securityCode"
                            autocomplete="off"
                            formControlName="securityCode"
                            type="text"
                            maxlength="4"
                            placeholder="CVV"
                        />
                    </mat-form-field>
                    <ng-container *ngIf="securityCode?.touched && securityCode?.invalid">
                        <mat-error
                            qa="validation-error-message"
                            class="credit-card-form-error-message"
                            *ngIf="securityCode?.errors?.required"
                            >Security code is required</mat-error
                        >
                        <mat-error
                            qa="validation-error-message"
                            class="credit-card-form-error-message"
                            *ngIf="!securityCode?.errors?.required"
                            >Invalid security code</mat-error
                        >
                    </ng-container>
                </div>
                <div class="form-field">
                    <label> Expiration </label>
                    <mat-form-field [floatLabel]="'auto'">
                        <input
                            type="text"
                            matInput
                            qa="expiration"
                            maxlength="5"
                            placeholder="MM/YY"
                            mask="00/00"
                            name="expiration"
                            autocomplete="off"
                            formControlName="expiration"
                        />
                    </mat-form-field>
                    <ng-container *ngIf="expiration?.touched && expiration?.invalid">
                        <mat-error qa="validation-error-message" class="credit-card-form-error-message" *ngIf="expiration?.errors?.required"
                            >Expiration is required</mat-error
                        >
                        <mat-error
                            qa="validation-error-message"
                            class="credit-card-form-error-message"
                            *ngIf="expiration?.errors?.creditCardExpired"
                            >Invalid expiration date</mat-error
                        >
                    </ng-container>
                </div>
                <div class="form-field">
                    <label> Zip Code </label>
                    <mat-form-field>
                        <input
                            matInput
                            qa="zip-code"
                            maxlength="5"
                            type="text"
                            name="zipCode"
                            autocomplete="off"
                            formControlName="zipCode"
                        />
                    </mat-form-field>
                    <ng-container *ngIf="zipCode?.touched && zipCode?.invalid">
                        <mat-error qa="validation-error-message" class="credit-card-form-error-message" *ngIf="zipCode?.errors?.required"
                            >Zip Code is required</mat-error
                        >
                        <mat-error qa="validation-error-message" class="credit-card-form-error-message" *ngIf="!zipCode?.errors?.required"
                            >Invalid zip code</mat-error
                        >
                    </ng-container>
                </div>
            </div>
        </form>
    </div>
    <div
        *ngIf="showCCInformation && isStripeEnabled"
        id="stripe-payment-element"
        data-testid="stripe-payment-element"
        class="stripe-payment-element"
    ></div>
    <div class="step-credit-card-information-row-policy">
        <p *ngIf="isDepositRequired() && siteSettings.creditCardProcessingEnabled">
            The deposit amount of
            <span class="bold">{{
                depositAmount | currency: '' : 'symbol' : (depositAmount && depositAmount % 1 != 0 ? '1.2' : '1.0') : localeId
            }}</span>
            will be charged once the request is approved.
        </p>
        <div *ngIf="showCCInformation">
            <p class="bold cancellation-policy-header">Cancellation Policy:</p>
            <p class="cancellation-policy">{{ siteSettings.apptCancellationPolicy }}</p>
        </div>
    </div>
</div>
