import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/components/confirmation-dialog/confirmation-dialog.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { CreditCard } from '@app/models/creditCard.model';
import { CreditCardProcessingMethods } from '@app/models/creditCardProcessingMethodEnum';
import { Service } from '@app/models/service.model';
import { Site } from '@app/models/site.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { StripeApiService } from '@app/services/api/stripe-api.service';
import { ContentService } from '@app/services/content.service';
import { CreditCardService } from '@app/services/credit-card.service';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, SITE, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { LocaleService } from '@app/services/locale.service';
import { StripeElementsService } from '@app/services/stripe-elements.service';
import { UserAccountService } from '@app/services/user-account.service';
import { getBookingDate, getBookingTime, hexToRgb, sanitizeColor } from '@app/shared/helpers/extensions';
import { environment } from '@environments/environment';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from './../../../services/navigation.service';

@Component({
    selector: 'app-review-request',
    templateUrl: './review-request.component.html',
    styleUrls: ['./review-request.component.scss'],
})
export class ReviewRequestComponent implements OnInit, OnDestroy {
    public creditCardForm!: FormGroup;
    @ViewChild('cCInformationContainer') cCInformationContainer!: ElementRef;
    public url: string = '';
    private isServicePriceAndDurationFeatureActive: boolean = false;
    private ngUnsubscribe: Subject<any> = new Subject();

    appointmentRequest!: AppointmentRequest;
    customerID!: string;
    siteSettings!: SiteBookingSettings;
    supportsPets!: boolean;
    shouldShowServicePrice = false;
    showCommentsField = false;
    shouldShowServiceDuration = false;
    requireCCWhenBooking = false;
    duration!: number;
    depositAmount!: number;
    showCCInformation = false;
    creditCardProcessingEnabled = false;
    creditCardProcessingMethod: CreditCardProcessingMethods = CreditCardProcessingMethods.None;
    featureEnabledAndUserLoggedIn = false;
    servicesWithDepositRequired: Service[] = [];
    public isLoading = false;
    public userLoggedIn = false;
    comments?: string;
    stripeSurchargeEnabled = false;
    stripeSurchargeRate!: number;

    public get isCardConnectEnabled() {
        return this.showCCInformation && this.creditCardProcessingMethod === CreditCardProcessingMethods.CardConnect;
    }

    public get isStripeEnabled() {
        return this.showCCInformation && this.creditCardProcessingMethod === CreditCardProcessingMethods.Stripe;
    }

    public get cardToken() {
        return this.creditCardForm.get('cardToken');
    }

    public get securityCode() {
        return this.creditCardForm.get('securityCode');
    }

    public get expiration() {
        return this.creditCardForm.get('expiration');
    }

    public get zipCode() {
        return this.creditCardForm.get('zipCode');
    }

    constructor(
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private featureFlagService: FeatureFlagService,
        private creditCardService: CreditCardService,
        private fb: FormBuilder,
        private bottomSheet: MatBottomSheet,
        private stripeElementsService: StripeElementsService,
        private localService: LocaleService,
        private userAccountService: UserAccountService,
        @Inject(LOCALE_ID) public localeId: string,
        public dialog: MatDialog,
        private contentService: ContentService,
        private stripeApiService: StripeApiService
    ) {}

    async ngOnInit() {
        this.getDataFromLocalStorage();
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
        this.isServicePriceAndDurationFeatureActive = this.featureFlagService.getFeatureFlag(FeatureKey.ShowPriceAndDuration);
        this.showCommentsField = this.featureFlagService.getFeatureFlag(FeatureKey.RequestComments);
        this.shouldShowServicePrice = this.isServicePriceAndDurationFeatureActive && this.siteSettings.showPricesWithServices;
        this.shouldShowServiceDuration = this.isServicePriceAndDurationFeatureActive;
        this.requireCCWhenBooking = this.siteSettings.requireCCWhenBooking ?? false;
        this.creditCardProcessingEnabled = this.siteSettings.creditCardProcessingEnabled ?? false;
        this.stripeSurchargeEnabled = this.siteSettings.stripeSurchargeEnabled ?? false;
        /* istanbul ignore next */
        this.creditCardProcessingMethod = this.siteSettings.creditCardProcessingMethod ?? CreditCardProcessingMethods.None; // NOSONAR the none will never occur
        this.userLoggedIn = this.userAccountService.getCurrentUser() !== undefined;
        this.featureEnabledAndUserLoggedIn =
            this.siteSettings?.allowClientOnlineAccountAccess === true &&
            this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts) &&
            this.userLoggedIn;
        this.createCreditCardForm();
        if (this.siteSettings && this.appointmentRequest) {
            this.navigationService.setIsStepFormValid(true);
        }

        this.showCCInformation = this.creditCardProcessingEnabled && (this.isDepositRequired() || this.requireCCWhenBooking);

        this.depositAmount = this.calculateTotalDepositAmount();
        this.servicesWithDepositRequired = this.appointmentRequest.service.filter((x) => x.depositRequired);
        if (this.isCardConnectEnabled) {
            this.createCreditCardUrl();

            // Listen to credit card number iframe event
            fromEvent(window, 'message')
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((event: any) => {
                    if ((event as MessageEvent).origin.indexOf('cardconnect.com') >= 0) {
                        const response = event['data'];
                        if (response) {
                            const data = JSON.parse(response);
                            this.setCardTokenFromIframe(data.message);
                        }
                    }
                });

            this.setupCreditCardFormValidation();
        }

        if (this.isStripeEnabled) {
            await this.initializeStripeElements();
        }
        this.userAccountService.userAccountUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userAccount) => {
            this.userLoggedIn = userAccount !== undefined;
            this.featureEnabledAndUserLoggedIn =
                this.siteSettings?.allowClientOnlineAccountAccess === true &&
                this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts) &&
                this.userLoggedIn;
        });

        if (this.stripeSurchargeEnabled) {
            this.stripeSurchargeRate = await this.stripeApiService.getStripeSurchargeRate();
        }
    }

    private async initializeStripeElements() {
        this.isLoading = true;
        try {
            //todo: get currency from site settings and culture code
            const stripeElements = await this.stripeElementsService.createStripeElementsForSetup(this.localService.getCurrencyCode());
            if (stripeElements) {
                const paymentElement = stripeElements.create('payment', {});
                paymentElement.mount('#stripe-payment-element');
                this.creditCardService.setStripePaymentElements(stripeElements);
            }
        } finally {
            this.isLoading = false;
        }
    }

    getServicePrice(service: Service) {
        const employeeServicePrice = this.appointmentRequest.employee[0].employeeServicePriceList.find(
            (ep) => ep && ep.serviceID === service.id
        );
        return employeeServicePrice ? employeeServicePrice.price : 0;
    }

    isDepositRequired() {
        return this.appointmentRequest.service.some((service) => service.depositRequired);
    }

    getServiceDuration(service: Service) {
        const durationException = this.appointmentRequest.employee[0].durationExceptionList?.find((de) => de.serviceID === service.id);
        return durationException ? durationException.duration : service.duration;
    }

    private setupCreditCardFormValidation() {
        if (this.showCCInformation && this.isCardConnectEnabled) {
            this.navigationService.setIsStepFormValid(this.creditCardForm.valid);
            this.creditCardForm.valueChanges.subscribe((_) => {
                this.navigationService.setIsStepFormValid(this.creditCardForm.valid);
                if (this.creditCardForm.valid) {
                    this.setCreditCardInformation();
                }
            });
        }
    }

    private createCreditCardForm() {
        this.creditCardForm = this.fb.group({
            cardToken: new FormControl('', [Validators.required]),
            securityCode: new FormControl('', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(4),
                this.isNumberValidator('invalidCVV'),
            ]),
            expiration: new FormControl('', [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
                this.creditCardExpirationValidator(),
            ]),
            zipCode: new FormControl('', [Validators.required, Validators.minLength(5), this.isNumberValidator('invalidZipCode')]),
        });
    }

    private setCardTokenFromIframe(tokenNumber: any) {
        if (tokenNumber) {
            this.creditCardForm.controls['cardToken'].setValue(tokenNumber);
            this.creditCardForm.controls['cardToken'].setErrors(null);
        } else {
            this.creditCardForm.controls['cardToken'].setErrors({ invalidCreditCardNum: true });
            this.navigationService.setIsStepFormValid(false);
        }

        this.creditCardForm.controls['cardToken'].markAsTouched();
    }

    private createCreditCardUrl() {
        const baseCardConnectUrl = environment.baseCardConnectUrl;
        const tokenizerPath = '/itoke/ajax-tokenizer.html';

        const urlParams = this.generateCSSAsURLParams();
        const invalidInputEvent = 'true';
        const tokenizeWhenInactive = 'true';
        const inactivityTimeout = '3000';
        const formatInput = 'true';

        this.url =
            `${baseCardConnectUrl}${tokenizerPath}` +
            `${urlParams}&invalidinputevent=${invalidInputEvent}&tokenizewheninactive=${tokenizeWhenInactive}` +
            `&inactivityto=${inactivityTimeout}&formatinput=${formatInput}`;
    }

    private getDataFromLocalStorage() {
        this.siteSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const site = this.localStorageService.get(SITE) as Site;
        this.supportsPets = site?.supportsPets;
    }

    private generateCSSAsURLParams() {
        const themeColor = hexToRgb(this.siteSettings.themeColor);

        const sanitizedThemeColor = sanitizeColor(themeColor);

        return (
            `?css=body{margin: 0;}` +
            `input{box-sizing: border-box;outline: none;width: 100%;font-size: 16px;` +
            `height:40px; padding: 8px 10px;border: 1px solid rgb(189, 189, 189);border-radius: 4px;` +
            `color: rgb(34, 34, 34);outline: none;overflow: hidden;}` +
            `input:focus{border: 1px solid ${sanitizedThemeColor};}`
        );
    }

    get getBookingDate() {
        return getBookingDate(this.appointmentRequest.datetime);
    }

    get getBookingTime() {
        return getBookingTime(this.appointmentRequest.datetime);
    }

    private creditCardExpirationValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const expirationDate = control.value;
            if (!expirationDate) {
                return null;
            }
            const [expirationMonth, expirationYear] = expirationDate.match(/.{2}/g) ?? [0, 0];
            const month = parseInt(expirationMonth, 10);
            const year = parseInt(expirationYear, 10);
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear() % 100;
            const currentMonth = currentDate.getMonth() + 1;
            if (!month || !year || month < 1 || month > 12 || year < currentYear || (year === currentYear && month < currentMonth)) {
                return { creditCardExpired: true };
            }
            return null;
        };
    }

    private isNumberValidator(property: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            return !value || isNaN(value) || parseInt(value) < 0 ? { [property]: true } : null;
        };
    }
    public ngOnDestroy() {
        this.creditCardService.resetCreditCardInformation();
        this.creditCardService.resetStripePaymentElements();
        this.ngUnsubscribe.complete();
    }
    /* istanbul ignore next */
    scrollToCCInformationContainer() {
        this.cCInformationContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }

    //TODO: Only display the 'Not You?' button if the user is logged in
    onClickNotYou() {
        this.dialog.open(ConfirmationDialogComponent, {
            width: '240px',
            data: {
                title: "You'll Be Logged Out",
                description: 'Please log in again using the correct account or continue as a guest.',
            },
        });
    }

    private setCreditCardInformation() {
        const [expirationMonth, expirationYear] = this.creditCardForm.get('expiration')!.value.match(/.{2}/g);
        const fullYear = this.getFullYearFromTwoDigitYear(parseInt(expirationYear));
        const creditCard = new CreditCard({
            creditCardToken: this.creditCardForm.get('cardToken')!.value,
            securityCode: this.creditCardForm.get('securityCode')!.value,
            postalCode: this.creditCardForm.get('zipCode')!.value,
            cardHolderName: `${this.appointmentRequest.client.firstName} ${this.appointmentRequest.client.lastName}`,
            expirationYear: fullYear,
            expirationMonth: parseInt(expirationMonth),
            daySmartAccountID: this.customerID,
        });
        this.creditCardService.setCreditCardInformation(creditCard);
    }

    private getFullYearFromTwoDigitYear(year: number) {
        const currentYear = new Date().getFullYear();
        const currentCentury = Math.floor(currentYear / 100) * 100;
        const fullYear = new Date(currentCentury + year, 0).getFullYear();
        return fullYear;
    }

    private calculateTotalDepositAmount() {
        return this.appointmentRequest.service.reduce((sum, service) => {
            let depositAmount = 0;
            if (service.depositRequired) {
                const employeeServicePrice =
                    this.appointmentRequest.employee[0]?.employeeServicePriceList.find((ep) => ep?.serviceID === service.id)?.price ?? 0;
                depositAmount = service.depositPercentage
                    ? (employeeServicePrice * service.depositPercentage) / 100
                    : service.depositFixedAmount ?? 0;
            }
            return sum + depositAmount;
        }, 0);
    }

    saveComment(): void {
        this.localStorageService.setNested(APPOINTMENT_REQUEST, 'comments', this.comments);
    }

    public formatPercentage(value: number): string {
        // Check if the number is a whole number
        if (value % 1 === 0) {
            return `${value}%`; // Return without decimals
        }
        // Convert to string and remove trailing zeros
        return `${Number(value.toFixed(2))}%`;
    }
}
