import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from '@app/components/dialog/general-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ErrorDialogService {
    constructor(private dialog: MatDialog) {}

    openErrorDialog(message: string): void {
        this.dialog.open(GeneralDialogComponent, {
            data: {
                title: 'Something went wrong',
                message,
            },
        });
    }
}
