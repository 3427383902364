import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationService } from './services/navigation.service';

declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public isLoading = false;
    private navigationSubscription!: Subscription;

    constructor(
        private navigationService: NavigationService,
        private changeDetector: ChangeDetectorRef,
        private router: Router
    ) {
        this.setWindowsHeight();
        window.addEventListener('resize', this.setWindowsHeight);
        this.addGaScript();
        this.setUpAnalytics();
    }

    ngOnInit(): void {
        this.navigationSubscription = this.navigationService.getIsLoading().subscribe((isLoading) => {
            if (this.isLoading !== isLoading) {
                this.isLoading = isLoading;
                this.changeDetector.detectChanges();
            }
        });
    }

    private setWindowsHeight() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--windows-height', `${vh}px`);
    }

    private addGaScript() {
        const gtagScript: HTMLScriptElement = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingID;
        document.head.prepend(gtagScript);
        /** Disable automatic page view hit to fix duplicate page view count  **/
        gtag('config', environment.gaTrackingID, { send_page_view: false });
    }

    private setUpAnalytics() {
        /* istanbul ignore next */
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
            gtag('event', 'page_view', {
                page_path: event.urlAfterRedirects,
            });
        });
    }

    ngOnDestroy() {
        this.navigationSubscription.unsubscribe();
    }
}
