import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';

export class CreditCard {
    public cardHolderName?: string;
    public creditCardToken?: string;
    public expirationMonth?: number;
    public expirationYear?: number;
    public securityCode?: string;
    public postalCode?: string;
    public daySmartAccountID?: string;
    public paymentMethod?: string; // stripe payment method id
    public clientSecret?: string; // stripe setup intent client secret
    public currency?: string; // currency code

    public constructor(init?: Partial<CreditCard>) {
        Object.assign(this, init);
    }
    public static deserializeValidateCreditCardResponse(appSyncResponse: any): boolean {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: boolean) => obj, ['validateCreditCard', 'passedAuthorization']);
    }
}

export class ValidateCreditCardResponse {
    passedAuthorization!: boolean;
}

export class ValidateCreditCardRequest {
    public creditCard: CreditCard;

    public constructor(card: CreditCard) {
        this.creditCard = card;
    }
}
