import { DayInWeek } from './dayInWeek.model';

/**
 * Week model that holds the data for 7 days (not necessarily Sunday to Saturday order).
 * Example: The week can be: ['Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon'].
 */
export class Week {
    readonly weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    daysInWeek: DayInWeek[] = [];

    constructor(startDate: Date) {
        for (let i = 0; i <= 6; i++) {
            const date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i);
            this.daysInWeek.push({
                dayInWeek: this.weekDays[date.getDay()],
                dayInMonth: date.getDate(),
                date: date,
                hasAvailabilities: false,
            });
        }
    }
}
