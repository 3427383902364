import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from '@environments/environment';
import { CldrIntlService, setData } from '@progress/kendo-angular-intl';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocaleService extends CldrIntlService {
    public static readonly DEFAULT_LOCALE_ID = 'en-US';

    private loaded: any = {};

    private _numberSettings = {
        decimal: '.',
        group: ',',
        list: ';',
        percentSign: '%',
        plusSign: '+',
        minusSign: '-',
        exponential: 'E',
        superscriptingExponent: '×',
        perMille: '‰',
        infinity: '∞',
        nan: 'NaN',
        timeSeparator: ':',
        currencySymbol: '$',
        currencyCode: 'USD',
    };

    constructor(
        @Inject(LOCALE_ID) public thisLocaleId: string,
        private http: HttpClient
    ) {
        super(thisLocaleId);
        this._setLocale(thisLocaleId);
    }

    public getLocale() {
        return this.localeId;
    }

    public getCurrencyCode() {
        return this._numberSettings.currencyCode;
    }

    public async setLocale(localeId: string): Promise<void> {
        if (this.loaded[localeId]) {
            this._setLocale(localeId);
            return;
        }

        const localeFilesLocation = environment.localesUrl;
        try {
            await this.loadLocaleData(`${localeFilesLocation}${localeId}/all.json`);
            this._setLocale(localeId);
        } catch (error) {
            if (localeId.includes('-')) {
                const areaLocaleId = localeId.split('-')[0];
                try {
                    await this.loadLocaleData(`${localeFilesLocation}${areaLocaleId}/all.json`);
                    this._setLocale(localeId);
                } catch {
                    this._setLocale(LocaleService.DEFAULT_LOCALE_ID);
                }
            } else {
                this._setLocale(LocaleService.DEFAULT_LOCALE_ID);
            }
        }
    }

    private async loadLocaleData(url: string): Promise<void> {
        const res = await lastValueFrom(this.http.get<any>(url));
        if (res) {
            this._populateNumberSettingsCurrencyCode(res['numbers']?.localeCurrency);
            setData(res);
        }
    }

    private _setLocale(determinedLocaleId: string) {
        this.localeId = determinedLocaleId;
        this.loaded[determinedLocaleId] = true;
        this._populateNumberSettings();
    }

    private _populateNumberSettings() {
        const currencyTestString = this.formatNumber(0, 'c0');
        const currencySymbol = currencyTestString.replace(/[\d\s]/g, '');
        this._numberSettings = {
            ...this.numberSymbols(),
            currencySymbol,
            currencyCode: this._numberSettings.currencyCode,
        };
    }

    private _populateNumberSettingsCurrencyCode(currencyCode?: string) {
        if (currencyCode) {
            this._numberSettings.currencyCode = currencyCode;
        }
    }
}
